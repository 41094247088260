import axios from 'axios';
import esriId from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import Portal from "@arcgis/core/portal/Portal";
import PortalItem from "@arcgis/core/portal/PortalItem";
import PortalQueryParams from "@arcgis/core/portal/PortalQueryParams";
import WebMap from "@arcgis/core/WebMap";
import esriConfig from "@arcgis/core/config.js";
import FeatureService from "@arcgis/core/rest/featureService/FeatureService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

var clientId, portalUrl;

export function initEsriAuth(in_portalUrl, in_clientId){
   
    if (portalUrl != in_portalUrl || clientId != in_clientId){
        portalUrl = in_portalUrl;
        clientId = in_clientId;
       
        //Create a new OAuthInfo object.
        const info = new OAuthInfo({
            // Swap this ID out with an app ID registered in your ArcGIS Organization.
            appId: clientId,
            //appId: "aEjfGyV2LzgUVagi",
            //appId:"3AbgO0Bn7DVIMpYA",//sample
            // Add the portalUrl property if using your own portal.
            portalUrl: portalUrl,
            //portalUrl: "https://demo-agx.sspinnovations.com/portal",
            //portalUrl:"https://demo-unserver.sspinnovations.com/unserver",
            // Set the authNamespace property to prevent the user's signed in state
            // from being shared with other apps on the same domain with the same authNamespace value.
            // authNamespace: "portal_oauth_inline",
            // Set popup to true to show the OAuth sign-in page in a separate popup window.
            popup: true,
            //popupCallbackUrl: "oauth-callback.html"
        });

        // Call the checkSignIn function to see if the user is already signed in.
        // Add the OAuthInfo to the IdentityManager.
        esriId.registerOAuthInfos([info]);
    }
    
}

export function getCredential(){
    return esriId
      .getCredential(portalUrl + "/sharing", {
        // Set the following property to false to not show a dialog
        // before the OAuth popup window is open.
        oAuthPopupConfirmation: false,
      });
}

export function destroyCredential(){
  return esriId.destroyCredentials()
}

export function checkSignInStatus(in_portalUrl, in_clientId){
    initEsriAuth(in_portalUrl, in_clientId);

    return esriId
    .checkSignInStatus(portalUrl + "/sharing");
}

export function getPortalItems(){
    const portal = new Portal({
        authMode: "immediate"
      });
      // Check if using a portal other than ArcGIS Online.
      if (portalUrl !== "https://www.arcgis.com") {
        portal.url = portalUrl;
      }
      // Load the portal, display the name and username, then call the query items function.
      return portal.load().then(() => {
        //navigationUser.fullName = portal.user.fullName;
        //navigationUser.username = portal.user.username;
        //navLogo.description =
        //  "Gallery of queried portal items displayed below. To sign out, click on the logged in user button.";
        return queryItems(portal).then(function(query){

          return portal.user.fetchFolders().then((folders) => {
            query.results.forEach((item) => {
              folders.forEach((folder) => {
                //console.log("User folder", folder.title);
                if (folder.id == item.ownerFolder){
                  item.folder = folder.title
                }
              });
            });
            return query.results
          });

        });
      });
  }

  // Function to query for portal items.
  function queryItems(portal) {
    // Create query parameters for the portal search.
    const queryParams = new PortalQueryParams({
      query: "owner:" + portal.user.username + " AND (type:\"Web Map\" OR type:\"Feature Service\" OR type:\"Form\")",
      //sortField: "num-views",
      //sortOrder: "desc",
      num: 300
    });
    // Query the items based on the queryParams created from the portal.
    let portalItems = portal.queryItems(queryParams);

    return portalItems
  }


  export function getFeatureLayers(url){
    //Fetch layers:
    const featureService = new FeatureService({
      url: url
     });

     return featureService.load().then(() => {
      let layers = featureService.layerInfos;
      let tables = featureService.tableInfos;

      return layers.concat(tables);
    });
  }

  export function getFeatureLayerFields(featureServiceUrl, id){
    //populate fields:
    const layer = new FeatureLayer({
        // URL to the service
        url: featureServiceUrl + "/" + id.toString()
      });
  
    return layer.load().then(() => {
        let fields = layer.fields;
        return fields;
      });
  }

  export async function getWebMapMeta(itemId, token){
   
    const response = await fetch(`${portalUrl}/sharing/rest/content/items/${itemId}?f=json&token=${token}`);
    const webMapMeta = await response.json();

    return webMapMeta;
  }

  export async function getWebMap(itemId){
   
    let credential =  await esriId.getCredential(`${portalUrl}/sharing`);
    let token = credential.token

    const response = await fetch(`${portalUrl}/sharing/rest/content/items/${itemId}/data?f=json&token=${token}`);
    const webMap = await response.json();

    return webMap;
  }

  export async function addVisionLinkToWebLayer(webMap, layerTitle, visionLink){
    //populate fields:
    try {
     
      var layer
      webMap.operationalLayers.forEach(function(lyr) {
        if (lyr.layerType == "GroupLayer"){
          let lyrFound = lyr.layers.find(function(l){
            return l.title == layerTitle;
          })
          layer = lyrFound
        } else {
          if (lyr.title === layerTitle){
            layer = lyr
          }
        }
      });

      if (layer){
        //await layer.load()
        const hyperlinkText = `<a href='${visionLink}' target='_blank'>Open in Vision</a>`;
            
        //check to see if existing content already have vision link
        var existingLinkIndex = layer.popupInfo.popupElements.findIndex(function(item){
          return item.type == "text" && item.text.indexOf("Vision") > 0
        })

        if (existingLinkIndex >=0){
          layer.popupInfo.popupElements[existingLinkIndex].text = hyperlinkText
        } else {
          // If a popupTemplate exists, append the hyperlink to it
          const existingContent = layer.popupInfo.popupElements || [];
        
          // Insert the hyperlink at the start of the content array (right after title)
          layer.popupInfo.popupElements = [
            {
              type: "text",
              text: hyperlinkText,
            },
            ...existingContent, // Add existing content after the hyperlink
          ];
        }
        layer.popupInfo.description = hyperlinkText
        
        return webMap
        }
     console.log("Popup hyperlink added below the title successfully.");
   } catch (error) {
     console.error("An error occurred:", error);
   }
   return null
 }

 // Function to update the Web Map with modified JSON
export async function updateWebMap(itemId, webMapData) {

  let credential =  await esriId.getCredential(`${portalUrl}/sharing`);
  let token = credential.token
  
  let webMapMeta = await getWebMapMeta(itemId, token)
  let owner = webMapMeta.owner
  let ownerFolder = webMapMeta.ownerFolder
  //http://www.arcgis.com/sharing/rest/content/users/USER_ID/items/ITEM_ID/update
  //https://demo24-arcgis.sspinnovations.com/portal/sharing/rest/content/users/ssp.vision/e92fb9a6e0864d4db47e70290a788942/items/e708f9d4b52c41d28fc4b05a79ac5f67/update
  var url
  if (ownerFolder != null){
    url = `${portalUrl}/sharing/rest/content/users/${owner}/${ownerFolder}/items/${itemId}/update`
  } else {
     url = `${portalUrl}/sharing/rest/content/users/${owner}/items/${itemId}/update`
  }

  const header = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/x-www-form-urlencoded"}
    };

    var body = {
      token: token,
      f: "json",
      text: JSON.stringify(webMapData)//encodeURIComponent(JSON.stringify(webMapData))
    }

    try{

      var response = await axios.post(url, body, header);
      return response;
      
    }
    catch(ex){
      return ex.message;
    }
}


