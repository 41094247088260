import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getAppConfig } from "../config";

//Get config (job types) for an organization
export const getConfig = async (accessToken, orgId) => {

    const appconfig = getAppConfig();
            
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var url = appconfig.api + '/config';
    if (orgId){
      url += "/" + orgId;
    }
    try{
      var result = await axios.get(url, header);

      //add portals
      if (!result.data.portals){
        result.data.portals = [];
      }
      return result;
    }
    catch(ex){
      return null;
    }
}

export const updateConfig = async (accessToken, orgId, config, overwrite) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/config';
  if (orgId){
    url += "/" + orgId;
  }
  
  try{
    //Before update, first fetch from the server to see if changes has been made
    if (!overwrite){
      var serverConfig = await getConfig(accessToken, orgId)
      if (serverConfig.data.uuid != config.uuid){
        //Let's determine which job type has changed and see if it is something we can auto merge, i.e. two users updating different job type
        var serverjobtypes = serverConfig.data.jobTypes;
        /*
        serverjobtypes.forEach(function(serverJobtype){
          //Find the same job type in current config
          var localJobTypes = config.jobTypes.filter(function(jobType){
            return jobType.jobName == serverJobtype.jobName;
          });
          if (localJobTypes.length > 0){

          }
          //JSON.stringify(obj1) === JSON.stringify(obj2) 
        });
        */
        return "serverchanged"
      }
      else{
        //No server updates, check to see if client has real updates
        if (JSON.stringify(config) == JSON.stringify(serverConfig.data)){
          return "noupdates"
        }
      }
    }
    var response = await axios.put(url, config, header);
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const getEsriFeatureConfig = async (accessToken, params) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/config/esri';
  
  try{
    var response = await axios.post(url, params, header);
    
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const createConfig = async (accessToken, orgId, config) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/config';
  if (orgId){
    url += "/" + orgId;
  }
  
  try{
  
    var response = await axios.post(url, config, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

export const generateRegex = async (accessToken, sampleValues) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  var url = appconfig.api + '/config/regex';
  
  try{
    var response = await axios.post(url, sampleValues, header);
    
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const generateRegexChatGpt = async (accessToken, sampleValues) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  var url = appconfig.api + '/config/regex/chatgpt';
  
  try{
    var response = await axios.post(url, sampleValues, header);
    
    return response;
  }
  catch(ex){
    return ex;
  }
}

//Get models
export const getModels = async (accessToken) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/models';
 
  try{
   
    var result = await axios.get(url, header);
    return result.data;
    
  }
  catch(ex){
    return [];
  }
}

export function constructResultUrl(type, direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam, url){
  if (type.toLocaleLowerCase() == "fieldmaps"){
    return constructFieldMapsUrl(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam);
  } else if (type.toLocaleLowerCase() == "survey123"){
    return constructSurvey123Url(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam);
  } else if (type.toLocaleLowerCase() == "featureservice"){
    return constructFeatureServiceUrl(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam);
  } else if (type.toLocaleLowerCase() == "other"){
    return constructOtherUrl(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam, url);
  }
 }

 function constructFieldMapsUrl(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam){
  var url = "https://fieldmaps.arcgis.app";
  
  if (direction == "startFromExternal"){
    url = url + "?referenceContext=updateFeature";
  } else if (direction == "startFromExternalWithRelatedFeature"){
    url = url + "?referenceContext=addRelatedFeature&foreignKeyField=globalid";
  } else if (direction == "startFromExternalWithDifferenceSummary"){
    url = url + "?referenceContext=updateFeature&summary=difference";
  } else if (direction == "startFromVision"){
    url = url + "?referenceContext=addFeature";
  }

  url = url + "&itemID=" + itemId;

  if (closeJob){
    url = url + "&closeJob=" + closeJob;
  }
  else{
    url = url + "&closeJob=false";
  }
  
  url = url + "&featureSourceURL=" + featureSourceUrl;
  url = url + "&featureID=redirectParam";
  
  //attributes

  if (attributes != null){
    var selectedAttr = attributes.filter(function(a){
      return a.checked == true;
    });
  
    ////featureAttributes={"height":"{Height}","material":"{Material}","number":"{Number}","grid_id":"{Grid ID}"}
    var attrs = {}
    selectedAttr.forEach(function(a){
      if (a.displayName || a.alias){
        attrs[a.name] = "{" + a.name + "}";
      }
      else{
        attrs[a.name.toLowerCase().replaceAll(" ","_")] = "{" + a.name + "}";
      }
    });
  
    if (direction == "startFromExternalWithDifferenceSummary"){
      attrs["qa_details"] = "{qa_details}";
      attrs["qa_description"] = "{qa_description}";
      attrs["qa_status"] = "{qa_status}";
      attrs["qa_count"] = "{qa_count}";
      attrs["qa_notes"] = "{qa_notes}";
      attrs["qa_lat"] = "{qa_lat}";
      attrs["qa_lon"] = "{qa_lon}";
      attrs["qa_accuracy"] = "{qa_accuracy}";
    }

    var attributes = JSON.stringify(attrs);
    if (additionalParam && additionalParam.length > 0){
      attributes = attributes.substr(0,attributes.length-1) + "," + additionalParam + "}"
    }
    url = url + "&featureAttributes=" + attributes;
  } 

  return url;
}
 
function constructOtherUrl(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam, baseUrl){
  var url = baseUrl
  if (direction == "startFromExternal"){
    url = url + "?referenceContext=updateFeature";
  } else if (direction == "startFromExternalWithRelatedFeature"){
    url = url + "?referenceContext=addRelatedFeature";
  } else if (direction == "startFromExternalWithDifferenceSummary"){
    url = url + "?referenceContext=addRelatedFeature&summary=difference";
  } else if (direction == "startFromVision"){
    url = url + "?referenceContext=addFeature";
  }

  if (closeJob){
    url = url + "&closeJob=" + closeJob;
  }
  else{
    url = url + "&closeJob=false";
  }

  if (returnPhoto){
    url = url + "&returnPhoto=" + returnPhoto;
  }
  
  //attributes
  if (attributes != null){
    var selectedAttr = attributes.filter(function(a){
      return a.checked == true;
    });
  
    ////featureAttributes={"height":"{Height}","material":"{Material}","number":"{Number}","grid_id":"{Grid ID}"}
    var attrs = {}
    selectedAttr.forEach(function(a){
      if (a.displayName || a.alias){
        attrs[a.name] = "{" + a.name + "}";
      }
      else{
        attrs[a.name.toLowerCase().replaceAll(" ","_")] = "{" + a.name + "}";
      }
    });
  
    var attributes = JSON.stringify(attrs);
    if (additionalParam && additionalParam.length > 0){
      attributes = attributes.substr(0,attributes.length-1) + "," + additionalParam + "}"
    }
    url = url + "&featureAttributes=" + attributes;
  }

  return url;
}

function constructFeatureServiceUrl(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam){
  var url = "";
  if (featureSourceUrl){
    url = featureSourceUrl;
  }
  /*
  //attributes
  var selectedAttr = attributes.filter(function(a){
    return a.checked == true;
  });

  ////featureAttributes={"height":"{Height}","material":"{Material}","number":"{Number}","grid_id":"{Grid ID}"}
  var attrs = {}
  selectedAttr.forEach(function(a){
    if (a.displayName || a.alias){
      attrs[a.name] = "{" + a.name + "}";
    }
    else{
      attrs[a.name.toLowerCase().replaceAll(" ","_")] = "{" + a.name + "}";
    }
  });

  var attributes = JSON.stringify(attrs);
  if (additionalParam && additionalParam.length > 0){
    attributes = attributes.substr(0,attributes.length-1) + "," + additionalParam + "}"
  }
  url = url + "&featureAttributes=" + attributes;
  */
  return url;
}

function constructSurvey123Url(direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam){
  var url = "https://survey123.arcgis.app";
  const appconfig = getAppConfig();

  url = url + "?itemID=" + itemId;
  
  if (direction == "startFromVision"){
    if (appconfig.api.indexOf("dev")>=0){
      url = url + "&callback=https://sspapp.link/visiondev";
    } else if (appconfig.api.indexOf("test")>=0){
      url = url + "&callback=https://sspapp.link/visiontest";
    } else if (appconfig.api.indexOf("prod")>=0){
      url = url + "&callback=https://sspapp.link/vision";
    }
  }

  if (closeJob){
    url = url + "&closeJob=" + closeJob;
  } else {
    url = url + "&closeJob=false";
  }
  
  if (additionalParam && additionalParam.length > 0){
    url = url + additionalParam;
  }

  url = url + constructFieldsUrl(attributes)

  return url;
}

function constructFieldsUrl(attributes){
  var url = "";
  if (attributes){
    //attributes
    var selectedAttr = attributes.filter(function(a){
      return a.checked == true;
    });

    //?field:height={Height}
    selectedAttr.forEach(function(a){
      if (a.displayName || a.alias){
        url = url + "&field:" + a.name + "={" + a.name + "}";
      } else{
        url = url + "&field:" + a.name.toLowerCase().replaceAll(" ","_") + "={" + a.name + "}";
      }
    });
  }
  
  return url;
}

export function constructVisionLink(type, direction, jobName, featureClass, attributes, returnPhoto, itemId, featureSourceUrl){
  let encodedJobName = encodeURI(jobName);
  if (type.toLocaleLowerCase() == "fieldmaps"){
    return constructFieldMapsVisionLink(direction, encodedJobName, featureClass, attributes, returnPhoto, itemId, featureSourceUrl);
  } else if (type.toLocaleLowerCase() == "other"){
    return constructFieldMapsVisionLink(direction, encodedJobName, featureClass, attributes, returnPhoto);
  } else if (type.toLocaleLowerCase() == "survey123"){
    return constructSurvey123VisionLink(direction, encodedJobName, featureClass, attributes, returnPhoto);
  } 
}

function constructFieldMapsVisionLink(direction, jobName, featureClass, attributes, returnPhoto, itemId, featureSourceUrl){
  var visionLink = ""
  const appconfig = getAppConfig();
  if (direction.indexOf("startFromExternal")>=0){
    //https://sspapp.link/visiondev?action=newJob&jobType=Pole%20Test%20Fieldmaps%20Colorado&redirectParam={globalid}
    if (appconfig.api.indexOf("dev")>=0){
      visionLink = "https://sspapp.link/visiondev";
    } else if (appconfig.api.indexOf("test")>=0){
      visionLink = "https://sspapp.link/visiontest";
    } else if (appconfig.api.indexOf("prod")>=0){
      visionLink = "https://sspapp.link/vision";
    }
    visionLink = visionLink + "?action=newJob&jobType=" + jobName + "&redirectParam={globalid}"
    if (returnPhoto){
      visionLink = visionLink + "&returnPhoto=" + returnPhoto;
    }
    visionLink = visionLink + "&feature=" + featureClass;
    if (direction == "startFromExternalWithDifferenceSummary"){
      visionLink = visionLink + "&summary=difference";
    }

    visionLink = visionLink + "&itemID=" + itemId;
    
    visionLink = visionLink + "&featureSourceURL=" + featureSourceUrl;
    
    visionLink = visionLink + constructFieldsUrl(attributes)
  }
  return visionLink;
}

function constructSurvey123VisionLink(direction, jobName, featureClass, returnPhoto){
  var visionLink = "";
  const appconfig = getAppConfig();
  if (direction == "startFromExternal"){
    //Survey123
    
    //https://sspapp.link/vision?action=newJob&jobType=Utility Demo Survey123
    if (appconfig.api.indexOf("dev")>=0){
      visionLink = "https://sspapp.link/visiondev";
    } else if (appconfig.api.indexOf("test")>=0){
      visionLink = "https://sspapp.link/visiontest";
    } else if (appconfig.api.indexOf("prod")>=0){
      visionLink = "https://sspapp.link/vision";
    }
    visionLink = visionLink + "?action=newJob&jobType=" + jobName;
    visionLink = visionLink + "&feature=" + featureClass;
  }
  return visionLink;
}

