import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Navbar, Nav, NavItem, NavLink, Row, Col, NavDropdown, Card, Spinner, Dropdown, Form} from "react-bootstrap";
import JobList from './views/jobs/jobList';
import jwt_decode from "jwt-decode";
import ConfigEditor from './views/configuration/configEditor';
import SettingEditor from './views/configuration/settingEditor';
import Approval from './views/user/approval.js';
import Technical from './views/configuration/technical.js';
import DocContainer from './views/docs/docContainer';
import { getHashParam, setHash} from './controllers/hashController';
import {fetchOrganizations} from './controllers/jobController';
import OrgList from './views/user/orgList';
import UserList from './views/user/userList';
import Report from './views/report/report';
import { Link } from 'react-router-dom';

function App(props) {
  const [accessToken, setToken] = useState(null);
  const [err, setError] = useState(null);
  const [organization, setOrg] = useState(null);
  const [organizations, setOrgs] = useState([]);
  const [orgId, setOrgId] = useState(props.provider.organization);
  const [superAdminOrgId, setSuperAdminOrgId] = useState(props.superAdminOrg);
  const {user, loginWithRedirect, logout, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [height, setHeight] = useState(window.innerHeight);
  const [clipboard, setClipboard] = useState([]);
  
  //Get module from # hash in url
  if (window.location.href.indexOf(".htm")<0){
    var mod = getHashParam(window.location.hash,0);
    if (!mod){
      mod = "jobs"; //default to jobs 
      setHash({module:mod},"module",0); //update hash in url
    }
  } else {
    let startIndex = window.location.href.lastIndexOf("/")
    let stringToReplace = window.location.href.substring(startIndex + 1,window.location.href.length)
    window.location.href = window.location.href.replace(stringToReplace, "#" + stringToReplace.replace(".htm",""))
  }
  
  const [module, setModule] = useState(mod);

  function setOrgParam(id){
   
    //update hash to the second param
    if (module == "jobs"){
      setHash(null,null,2);
    }
    else{
      setHash(null,null,1);
    }
    
    insertParam("organization", id)
  }

  function insertParam(key, value) {
    if (window.history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString() + window.location.hash;
        window.history.pushState(null, null, newurl);
    }
}

function removeParam(key) {
  const url = window.location.href
  console.log("url", url)
  var r = new URL(url)
  r.searchParams.delete(key)
  const newUrl = r.href
  console.log("r.href", newUrl)
  window.history.pushState(null, null, newUrl)
}

function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.map(param => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
  })
  return params;
}

  const handleOrgSelection = (key, e) => {
    setOrg(key);
    var org = organizations.filter(function(o){
      return o.display_name === key;
    })[0];
    setOrgId(org.id);
    setOrgParam(org.id);
    setToken(null);
  };

  const handleSelectModule = (key, e) => {
    if (module != key){

      //if (key.indexOf("#")>=0){
        setModule(key);
        setHash({module:key},"module",0,true);
      //} else {
       // window.open("/#" + key, "_blank")
      //}
    }
  };

  const handleSelect = (key, e) => {
    switch(key) {
      case 'logout':
        setToken(null);
        setOrg(null);
        setOrgs([]);
        removeParam("organizaton")
        logout();
        break;
      
      default:
        //Select organization
        if (!isSuperAdmin()){
          setToken(null);
          setOrg(key);
          setOrgId(e.currentTarget.id)
        }
        else{
          setSuperAdminOrgId(e.currentTarget.id)
        }
       
        if (!isSuperAdmin()){
          setOrgParam(e.currentTarget.id);
        }
        else{
          //Superadmin, append selected org to orginal org
          var decoded = jwt_decode(accessToken);
          setOrgParam(decoded.org_id + "|" + e.currentTarget.id);
        }
         
    }
  };

  const updateDimensions = () => {
    setHeight(window.innerHeight);
  }

  const getToken = async (ignoreCache) => {
    var accessToken;
    try{
      accessToken = await getAccessTokenSilently({
        ignoreCache: ignoreCache,
        organization: orgId
      });
      return accessToken;
    }
    catch(ex){
     //write error to UI
     if (orgId){
      if (organization){
        setError("Cannot obtain access token for " + organization);
      }
      else{
        setError("Cannot obtain access token for " + orgId);
      }
     }
     else{
      setError(ex.message);
     }
    }
  }

  function isSuperAdmin(){
    var decoded = jwt_decode(accessToken);
    var roles = decoded["https://sspvision.net/roles"];
    var isSuperAdmin = (roles.indexOf("vision-superadmin") >= 0);
    return isSuperAdmin
  }

  useEffect(() => {
    if (isAuthenticated === false && isLoading === false){ 
      loginWithRedirect();
    }
    else{
      if (accessToken == null){
        getToken(true).then(async function(token){
            if (token){
                var decoded = jwt_decode(token);
                var roles = decoded["https://sspvision.net/roles"];
                var isSuperAdmin = (roles.indexOf("vision-superadmin") >= 0);
                var org_id = decoded["org_id"];
                const values = getQueryParams(window.location.search);
                var orgParam = values["organization"];

                //Check to see if super admin
                if (organizations.length == 0 || isSuperAdmin){

                  if (isSuperAdmin){
                    var orgs = await fetchOrganizations(token);
                  }
                  else if (decoded["https://sspvision.net/orgs"])
                  {
                    var orgs = decoded["https://sspvision.net/orgs"];
                  }
                  else{
                    setToken(token);
                    return;
                  }
                     
                    if (orgs.length == 1){
                      setOrg(orgs[0]);
                      setOrgs(orgs);
                      if (orgs[0].id != orgId){
                        setOrgId(orgs[0].id);
                        setOrgParam(orgs[0].id); 
                      }
                      if (decoded["org_id"]){
                        setToken(token); //Skip setting token if org_id is not set, so that it will login second time with org_id
                      }
                    }
                    else if (orgs.length > 1){
                      setOrgs(orgs);

                      if (isSuperAdmin){
                        if (!superAdminOrgId){
                          if (props.org){
                            setSuperAdminOrgId(props.org)
                          }
                          else{
                            setSuperAdminOrgId(org_id)
                          }
                        }
                      }
                      else{
                        //Org doesn't match query string, relogin
                        if (orgParam && (orgParam != org_id)){
                          var selorgs = orgs.filter(function(o){
                            return o.id == orgParam;
                          });
                          if (selorgs.length >0){
                            setToken(null);
                            setOrg(selorgs[0]);
                            setOrgId(orgParam)
                            return;
                          }
                        }
                      }

                      if (decoded["org_id"]){
                        var selorgs = orgs.filter(function(o){
                          return o.id == decoded["org_id"];
                        });
                        if (selorgs.length >0){
                          setToken(token);
                          setOrg(selorgs[0]);
                          setOrgId(org_id)
                          if (!values["organization"]){
                            setOrgParam(selorgs[0].id); 
                          }
                        }
                      }
                      else{
                        if (orgId){
                          //Get org by id
                          var selorgs = orgs.filter(function(o){
                            return o.id == orgId;
                          });
                          if (selorgs.length >0){
                            setToken(token);
                            setOrg(selorgs[0]);
                          }
                        }
                      }
                    }
                    else{
                      setToken(token);
                    }
                }
                else{
                  setToken(token);
                }
            }
            else{
              setToken(token);
            }
         }); 
      }
    }
   
    window.addEventListener("resize", updateDimensions);
    return function(){
      window.removeEventListener("resize", updateDimensions);
    } 
  },[accessToken, organization]);
  
  function getTitle(){
    if (organization != null){
      return getSelectedOrg();
    }
    else{
      return user.name;
    }
  }

  function getError(){
    if (err){
      return (
        <Container fluid>
          <Row style={{ height: height + "px" }} className="justify-content-md-center align-items-center"><Col md="auto">
          <div class="f3 text-center">{err}</div>
          </Col></Row>
        </Container>
      )
    }
    else{
      return null;
    }
  }
  
  function getSelectedOrg(){
    if (superAdminOrgId){
      var selorgs = organizations.filter(function(o){
        return o.id == superAdminOrgId;
      });
      if (selorgs.length >0){
        return selorgs[0].display_name;
      }
      else{
        return organization.display_name;
      }
    }
    else{
      return organization.display_name;
    }
  }

  // forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <NavDropdown.Item>{user.name}</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="logout">Sign Out</NavDropdown.Item>
        <NavDropdown.Divider />
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled" style={{height:"130px", overflow:"auto"}}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().indexOf(value.toLocaleLowerCase())>=0,
          )}
        </ul>
      </div>
    );
  },
);

function getBasicDropdown(){
  return (
    <Nav fill onSelect={handleSelect}  defaultActiveKey={getSelectedOrg()} className="d-flex">
              <NavDropdown title={getTitle()} id="basic-nav-dropdown">
              <NavDropdown.Item>{user.name}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item eventKey="logout">Sign Out</NavDropdown.Item>
                {organizations.length > 1 ? <NavDropdown.Divider />:null}
                {organizations.map((org) => (
                  <NavDropdown.Item id={org.id} eventKey={org.display_name}>{org.display_name}</NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
  )
}

function getFilterDropdown(){
  return (
    <Nav fill onSelect={handleSelect}  defaultActiveKey={getSelectedOrg()} className="d-flex">
              <Dropdown as={NavItem} id="basic-nav-dropdown">
              <Dropdown.Toggle as={NavLink}>{getTitle()}</Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu}>
                  {organizations.map((org) => (
                    <NavDropdown.Item id={org.id} eventKey={org.display_name}>{org.display_name}</NavDropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
  )
}

function getDropdown(){
  if (organizations.length <= 5){
    return getBasicDropdown()
  }
  else{
    return getFilterDropdown()
  }
}
  function getNavLinks(){
    var decoded = jwt_decode(accessToken);
    var perms = decoded["permissions"];
    var showConfig = (perms.indexOf("config:update") >= 0);
    var roles = decoded["https://sspvision.net/roles"];
    var isSuperAdmin = (roles.indexOf("vision-superadmin") >= 0);
    var isAdmin = (roles.indexOf("vision-admin") >= 0);
    var isUser = (roles.indexOf("vision-user") >= 0 || roles.indexOf("vision-singleuser") >= 0 || roles.indexOf("vision-essentials-user") >= 0);
    var isSingleUser = (roles.indexOf("vision-singleuser") >= 0);
    var isAdvanced = (roles.indexOf("vision-advanced-user") >= 0);
    var showUserMgmt = (roles.indexOf("vision-invite-manager") >= 0);
    var userMenu = "User Management";
    if (isSuperAdmin){
      userMenu = "Organizations/Users"
    }
      return (
        <Navbar.Collapse>
            <Nav fill variant="pills" onSelect={handleSelectModule}  defaultActiveKey={module} className="me-auto my-2 my-lg-0">
              <Nav.Link eventKey="jobs">Jobs</Nav.Link>
              {isAdmin &&
                <Nav.Link eventKey="report">Report</Nav.Link>
              }
              <NavDropdown title="Documentation" id="basic-nav-dropdown">
                {(isUser || isAdmin || isSuperAdmin) &&
                  <NavDropdown.Item as={Link} to={'#vision-user'} eventKey="vision-user">Mobile User</NavDropdown.Item>
                }
                {(isUser || isAdmin || isSuperAdmin) &&
                  <NavDropdown.Item as={Link} to={'#vision-web-user'} eventKey="vision-web-user">Web User</NavDropdown.Item>
                }
                {(isAdmin || isSuperAdmin) &&
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={'#vision-configuration'} eventKey="vision-configuration">Configuration</NavDropdown.Item>
                </div>
                }
                {(isUser || isAdmin || isSuperAdmin) &&
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={'#vision-technical'} eventKey="vision-technical">Technical</NavDropdown.Item>
                </div>
                }
                {(isAdmin || isSuperAdmin) &&
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={'#vision-admin'} eventKey="vision-admin">Administrator</NavDropdown.Item>
                </div>
                }
                 {(isAdmin || isSuperAdmin) &&
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={'#vision-integration'} eventKey="vision-integration">Integration</NavDropdown.Item>
                </div>
                }
              </NavDropdown>
              {(showUserMgmt || isAdmin || isSuperAdmin || showConfig) &&
                <NavDropdown eventKey="userMgmt" title="Admin" id="basic-nav-dropdown">
                  {showConfig &&
                    <NavDropdown.Item eventKey="configuration">Configuration</NavDropdown.Item>
                  }
                  {showUserMgmt &&
                    <NavDropdown.Item eventKey="signupApproval">Demo Invites</NavDropdown.Item>
                  }
                  {(isAdmin || isSuperAdmin) &&
                    <NavDropdown.Item eventKey="users">{userMenu}</NavDropdown.Item>
                  }
                  {(isAdmin || isSuperAdmin) &&
                    <NavDropdown.Item eventKey="technical">Technical Admin</NavDropdown.Item>
                  }
                  {(isAdmin || isSuperAdmin) &&
                    <NavDropdown.Item eventKey="settings">Settings</NavDropdown.Item>
                  }
                  {(isSuperAdmin) &&
                    <NavDropdown.Item eventKey="default_settings">Default Settings</NavDropdown.Item>
                  }
                </NavDropdown>
              }
            </Nav>
            {getDropdown()}
          </Navbar.Collapse>
        )
  }

  function getAppPage(){
      return (
        <div className='app-background'>
          <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="#home">&nbsp;&nbsp;&nbsp;
              <img
                alt=""
                src="/images/Icon_transparent.png"
                width="32"
                height="32"
                className="d-inline-block"
              /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              SSP Vision
            </Navbar.Brand>
            <Navbar.Toggle />
            {getNavLinks()}
          </Navbar>
          {getAppContent()}
        </div>
      );
  }

  
  function getAppContent(){
    if (err){
      return getError();
    }
    else{
      var decoded = jwt_decode(accessToken);
      var roles = decoded["https://sspvision.net/roles"];
      var isSuperAdmin = (roles.indexOf("vision-superadmin") >= 0);

      if (module == "jobs"){
        return (<Container fluid><Row><Col><JobList accessToken={accessToken} superAdminOrgId={superAdminOrgId} height={height} org={organization}></JobList></Col></Row></Container>)
      }
      else if (module == "report"){
        return (<Container fluid><Row><Col><Report accessToken={accessToken} superAdminOrgId={superAdminOrgId} height={height} clipboard={clipboard} org={organization}></Report></Col></Row></Container>)
      }
      else if (module == "configuration"){
        return (<Container fluid><Row><Col><ConfigEditor accessToken={accessToken} superAdminOrgId={superAdminOrgId} height={height} clipboard={clipboard} org={organization}></ConfigEditor></Col></Row></Container>)
      }
      else if (module == "signupApproval"){
        return (<Container fluid><Row><Col><Approval accessToken={accessToken} superAdminOrgId={superAdminOrgId} height={height} ></Approval></Col></Row></Container>)
      }
      else if (module == "users"){
          return (<Container fluid><Row><Col>
          {isSuperAdmin &&
            <OrgList accessToken={accessToken} superAdminOrgId={superAdminOrgId} height={height} ></OrgList>
          }

          {!isSuperAdmin &&
            <UserList userOnly={true} height={height} accessToken={accessToken} org={organization}></UserList>
          }
          </Col></Row></Container>)
      }
      else if (module == "technical"){
        return (<Container fluid><Row><Col><Technical accessToken={accessToken} superAdminOrgId={superAdminOrgId} org={organization} height={height} ></Technical></Col></Row></Container>)
      }
      else if (module == "settings"){
        return (<Container fluid><Row><Col><SettingEditor accessToken={accessToken} superAdminOrgId={superAdminOrgId} height={height} clipboard={clipboard} org={organization}></SettingEditor></Col></Row></Container>)
      }
      else if (module == "default_settings"){
        return (<Container fluid><Row><Col><SettingEditor accessToken={accessToken} superAdminOrgId={"default"} height={height} clipboard={clipboard} org={organization}></SettingEditor></Col></Row></Container>)
      } else {
        return (<Container fluid><Row><Col><DocContainer doc={module} onHyperlink={handleSelectModule}></DocContainer></Col></Row></Container>)
      }
    }
  }

  function getLogo(org){
    if (org.branding){
      return (<Card.Img variant="top" src={org.branding.logo_url} height="65px" style={{objectFit: "contain", padding:"5px"}}/>)
    }
    else
    {
      return (<div />);
    }
  }
  function getOrganizations(){
    
    return (
        <Row style={{ height: height + "px" }} className="justify-content-md-center align-items-center">
        <Col md="auto">
         <div class="f3 text-center">Select an organization:</div>
         {organizations.map((org) => (
           <Container fluid="sm">
            <Card className="" style={{ width: '18rem', margin:'10px' }} onClick={handleOrgSelection.bind(this,org.display_name)}>
                    <div>{getLogo(org)}</div>
                    <Card.Body>
                      <Card.Text>
                      <center>{org.display_name}</center>
                      </Card.Text>
                    </Card.Body>
                  </Card>
           </Container>
                  
                ))}
         </Col></Row>
    )
  }

  function getOrganizationsPage(){
    return (<div className="app-background">
         <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="#home">
              &nbsp;&nbsp;&nbsp;
              <img
                alt=""
                src="/images/Icon_transparent.png"
                width="32"
                height="32"
                className="d-inline-block"
              /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              SSP Vision
            </Navbar.Brand>
          </Navbar>
          {getError()}
          {getOrganizations()}
      </div>)
  }
 
  if (err){
    //we have an error, let's figure out what to display...
    if (organizations.length > 1 && isLoading === false && isAuthenticated == false){
      //if we already got through the initial login and have a list of org but isAuthenticated is false, that means it errors out when swicthing org
      return getOrganizationsPage();
    }
  }
  else{
    if (isAuthenticated === true && isLoading === false && organization == null && organizations.length > 1){
      return getOrganizationsPage();
    }
    else if (isAuthenticated === true && isLoading === false && accessToken != null){
      return getAppPage();
    }
    else if (isAuthenticated === false){
      return (
        <Container fluid>
          <Row style={{ height: height + "px" }} className="justify-content-md-center align-items-center"><Col md="auto">
          <div class="text-center"><Spinner animation="border" /></div>
          </Col></Row>
        </Container>
      )
    }
  }
}

export default App;
