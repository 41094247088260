
import React, { useEffect, useState, useContext } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Modal, Button, Form, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTrash, faFloppyDisk, faPlus, faCopy, faDownload, faUpload} from '@fortawesome/free-solid-svg-icons';
import { getOrgs, updateOrg, createOrg, deleteOrg} from '../../controllers/authController';
import { getHashParam, setHash} from '../../controllers/hashController';
import UserList from './userList';
import ConfirmDialog from '../controls/confirmDialog';
import jwt_decode from "jwt-decode";

function OrgList(props) {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgHighlight, setOrgHighlight] = useState(null);
  const [orgIndex, setOrgIndex] = useState(0);
  const [orgs, setOrgs] = useState([]); 

  var decoded = jwt_decode(props.accessToken);
  var roles = decoded["https://sspvision.net/roles"];
  var isSuperAdmin = (roles.indexOf("vision-superadmin") >= 0);

  const handleBackToOrgs = (e) => {
    setOrg(null);
    setHash(null,null,1);
  };

  const handleHighlight = (org, i, e) => {
    setOrgHighlight(org);
    setOrgIndex(i);
    setHash(org, "id", 1)
  };

  const handleSelect = (org, i, e) => {
    setOrgHighlight(org);
    setOrgIndex(i);
    setOrg(org);
    setHash(org, "id", 1)
  };

  const clearChecked = () => {
    //Let's clear checkboxes in all levels
    orgs.forEach(function(o){
      delete o.checked;
    })
  };

  function getUsersMoved(existingUserEmails) {
    var userEmails = "";
    existingUserEmails.forEach(function(email){
      userEmails += email + "\n";
    })
    return userEmails
  }
  
  const handleSaveOrg = async () => {
    clearChecked();
    validateOrg(orgHighlight);
    var result;

    var currentOrg = orgs[orgIndex]; 
    if (currentOrg.id){
      result = await updateOrg(props.accessToken, currentOrg);
    }
    else{
      result = await createOrg(props.accessToken, currentOrg);
    }
    
    if (result.status == 200){
        if (!currentOrg.id){
            currentOrg.id = result.data.orgId;

            //Create will also return list of users being moved from demo env to the new org
            /*{
                  "existingUserEmails": [
                    "feichi.shih@sspinnovations.com",
                    "jeff.johnson@sspinnovations.com",
                    "john.selkirk@sspinnovations.com",
                    "skye.perry@sspinnovations.com",
                    "aaron.leese@sspinnovations.com"
                ],
                "existingUserIds": [
                    "auth0|64d2bae37f18450dc24e9757",
                    "auth0|64dd07bb7207e01f2d964791",
                    "auth0|6408aff2be46449a3725457d",
                    "auth0|6419cb0ba47671797a3e7d1d",
                    "auth0|6414cee019607078dd5a4842"
                ],
                "orgId": "org_8yd8HZ2ZF4I95beD",
                "result": "success"
              }*/

            setMessage("Updates saved, following users are moved from demo to the new organization: \n" + getUsersMoved(result.data.existingUserEmails));
            setTimeout(function(){
              setMessage(null);
            }, 6000)
        }
        else{
          setMessage("Updates saved!");
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
        setOrgHighlight(currentOrg);
        
    }
    else{
      setMessage("Failed to save: " + result.response.data.message);
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  };

  const validateOrg = async (org) => {
   
    if (org.metadata.welcome_text == ""){
        delete org.metadata.welcome_text;
    }
    if (org.metadata.primary_contact_email == ""){
        delete org.metadata.primary_contact_email;
    }
    if (org.metadata.primary_contact_name == ""){
        delete org.metadata.primary_contact_name;
    }
    if (org.metadata.status == ""){
        delete org.metadata.status;
    }

    if (org.metadata.tier == ""){
        delete org.metadata.tier;
    }

    if (org.metadata && org.metadata.jobs_require_review && org.metadata.jobs_require_review == ""){
      org.metadata.jobs_require_review = "false";
    } else if (org.metadata && org.metadata.jobs_require_review == null){
      org.metadata.jobs_require_review = "false";
    }

    if (JSON.stringify(org.metadata) == JSON.stringify({})){
      delete org.metadata;
    }
    

    if (org.branding.logo_url == ""){
        delete org.branding.logo_url;
    }
    
    if (JSON.stringify(org.branding) == JSON.stringify({})){
      delete org.branding;
    }

    if (orgHighlight.metadata.license_limits.admin_users == ""){
      delete orgHighlight.metadata.license_limits.admin_users;
    }

    if (orgHighlight.metadata.license_limits.advanced_users == ""){
      delete orgHighlight.metadata.license_limits.advanced_users;
    }

    if (orgHighlight.metadata.license_limits.essentials_users == ""){
      delete orgHighlight.metadata.license_limits.essentials_users;
    }

    if (orgHighlight.metadata.license_limits.standard_users == ""){
      delete orgHighlight.metadata.license_limits.standard_users;
    }

    if (orgHighlight.metadata.license_limits.devices == ""){
      delete orgHighlight.metadata.license_limits.devices;
    }

    if (typeof orgHighlight.metadata.license_limits != 'string' && !(orgHighlight.metadata.license_limits instanceof String)){
      orgHighlight.metadata.license_limits = JSON.stringify(orgHighlight.metadata.license_limits)
    }
  };

  const handleOrganizationTask = (key, e) => {
    switch(key){
      case "delete":
        handleDelete();
        break;
      
        case "save":
          handleSaveOrg();
          break;

        case "add":
          handleAdd();
          break;

      default:
    }
  };


  const handleDeleteCancel = (e) => {
    setDialogMessage(null);
  }

  const handleAdd = () => {
    //Add empty jobtype
    var org = {
     "name":"",
     "display_name":""
     }
     var newOrgs = [...orgs]; //clone to update
     newOrgs.push(org);
 
     setOrgHighlight(org);
     setOrgIndex(newOrgs.length-1);
     setOrgs(newOrgs);
   }
 
   const handleDelete = () => {
     //delete jobs
     var selectedOrgs = orgs.filter(function(o){
       return o.checked;
     })
    
     if (selectedOrgs.length > 0){
       var deleteMsg;
     
       if (selectedOrgs.length == 1){
         deleteMsg = "Delete " + selectedOrgs[0].display_name + "?";
       }
       else{
         deleteMsg = "Delete " + selectedOrgs.length + " organizations?";
       }
       setDialogMessage(deleteMsg);
     }
   }

   const handleDeleteConfirm = (e) => {
    setDialogMessage(null);
   
    var selectedOrgs = orgs.filter(function(o){
        return o.checked;
      })

    selectedOrgs.forEach(async function(o){
        var result;
        if (o.id){
            result = await deleteOrg(props.accessToken, o);
        }
        
        if (result.status == 200){
            setMessage("Deleted " + o.display_name);
            setTimeout(function(){
              setMessage(null);
            }, 2000)
          }
          else{
            setMessage("Failed to delete: " + o.display_name);
            setTimeout(function(){
              setMessage(null);
            }, 2000)
          }
    })

    

    //delete orgs from UI
    var leftOrgs = orgs.filter(function(o){
      return !o.checked
    })
    setOrgs([...leftOrgs]);
   
    //Check to see if currently selected field is still in the list
    var currentOrgs = leftOrgs.filter(function(o){
      return o.name == orgHighlight.name;
    })
    if (currentOrgs.length == 0){
      if (leftOrgs.length > 0){
        setOrgHighlight(leftOrgs[0]);
        setOrgIndex(0);
      }
      else{
        setOrgHighlight(null);
        setOrgIndex(0);
      }
    }
  }

  function fieldUpdate(e){
    //Find current org in the array to update
    var currentOrg = orgs[orgIndex]; //Need to access by orgIndex so that changing Job Type name won't cause issue

    if (e.currentTarget.name == "name"){
        var nameVal = e.currentTarget.value.toLowerCase();
        nameVal = nameVal.replace(" ", "_");
        currentOrg[e.currentTarget.name] = nameVal;
    }
    else{
        var fields = [e.currentTarget.name];
        if (e.currentTarget.name.indexOf(".")>0){
        fields = e.currentTarget.name.split(".");
        }

        //If it is multi level, need to access it by the property hierarchy
        if (fields.length == 1){
            currentOrg[fields[0]] = e.currentTarget.value;
        }
        else if (fields.length == 2){
            //multi level
            currentOrg[fields[0]][fields[1]] = e.currentTarget.value;
        }
        else if (fields.length == 3){
            currentOrg[fields[0]][fields[1]][fields[2]] = parseInt(e.currentTarget.value);
        }
    }
    
    //Update current org as well
    if (orgHighlight){
      var newOrg = {...currentOrg}; //Clone it so that it will trigger state change
      setOrgHighlight(newOrg);
    }

    if (org){
      setOrg(newOrg);
    }
    
   }
 
   function fieldCheckUpdate(e){
    //Find current setting type in the array to update
    var currentOrg = orgs[orgIndex]; //Need to access by orgIndex so that changing Job Type name won't cause issue

     if (e.currentTarget.value != ""){
        var value = e.currentTarget.checked;
        if (value == true){
          value = "true"
        }
        else{
          value = "false"
        }

        var fields = [e.currentTarget.name];
        if (e.currentTarget.name.indexOf(".")>0){
        fields = e.currentTarget.name.split(".");
        }

        //If it is multi level, need to access it by the property hierarchy
        if (fields.length == 1){
            currentOrg[fields[0]] = value;
        }
        else if (fields.length == 2){
            //multi level
            currentOrg[fields[0]][fields[1]] = value;
        }
        else if (fields.length == 3){
            currentOrg[fields[0]][fields[1]][fields[2]] = value;
        }
     }

    //Update current org as well
    if (orgHighlight){
      var newOrg = {...currentOrg}; //Clone it so that it will trigger state change
      setOrgHighlight(newOrg);
    }

    if (org){
      setOrg(newOrg);
    }
   }

   const handleCheckChanged = (org, e) => {
    var clonedOrgs = [...orgs]//Clone the array before update, so that we get proper state change
    var selectedOrg = clonedOrgs.filter(function(o){
      return o.name == org.name;
    })[0];
    if (selectedOrg){
        selectedOrg.checked = e.currentTarget.checked;
      setOrgs(clonedOrgs);
    }
  };

  useEffect(() => {
    if (props.accessToken){
       getOrgs(props.accessToken).then(function(orgs){
            setOrgs(orgs);

            if (orgs.length > 0){
                //If there is url parameters, select it
                var orgId = getHashParam(window.location.hash, 1);
                if (orgId){
                  //find jobType object
                  var selectedOrgIndex = orgs.findIndex(function(o){
                    return o.id == orgId;
                  });
                  if (selectedOrgIndex >= 0){
                    handleSelect(orgs[selectedOrgIndex]);
                  }
                  else{
                    handleHighlight(orgs[0],0);
                  }
                }
                else{
                  handleHighlight(orgs[0],0);
                }
              }
        }); 
    }
   },[props.accessToken]);

   function getLicenseType(){
    return (
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>License Type*</Form.Label>
        <Col sm={8}><Form.Select name="metadata.license_type" value={orgHighlight.metadata.license_type} onChange={fieldUpdate.bind(this)}>
        <option value="per_user">Per User</option>
        <option value="per_device">Per Device</option>
        <option value="unlimited">Unlimited</option>
        </Form.Select></Col>
      </Form.Group>
    )
   }
   function getLicenseFields(){
    if (!orgHighlight.metadata.license_limits){
      if (orgHighlight.metadata.license_type == "per_user" || orgHighlight.metadata.license_type == "per_device"){
        orgHighlight.metadata.license_limits = {}
      }
    }
    else{
      //convert string into object properties
      if (orgHighlight.metadata.license_type == "per_user" || orgHighlight.metadata.license_type == "per_device"){
        if (typeof orgHighlight.metadata.license_limits === 'string' || orgHighlight.metadata.license_limits instanceof String){
          orgHighlight.metadata.license_limits = JSON.parse(orgHighlight.metadata.license_limits)
        }
      }
    }

    if (orgHighlight.metadata.license_type == null){
      orgHighlight.metadata.license_type = "per_user"
    }
    if (orgHighlight.metadata.license_type == "per_user"){

      if (orgHighlight.metadata.license_limits.devices){
        delete orgHighlight.metadata.license_limits.devices;
      }

        if (!orgHighlight.metadata.license_limits.admin_users){
          orgHighlight.metadata.license_limits.admin_users = "";
        }

        if (!orgHighlight.metadata.license_limits.advanced_users){
          orgHighlight.metadata.license_limits.advanced_users = "";
        }

        if (!orgHighlight.metadata.license_limits.essentials_users){
          orgHighlight.metadata.license_limits.essentials_users = "";
        }

        if (!orgHighlight.metadata.license_limits.standard_users){
          orgHighlight.metadata.license_limits.standard_users = "";
        }
        
    }
    else if (orgHighlight.metadata.license_type == "per_device"){
     
      if (orgHighlight.metadata.license_limits.admin_users){
        delete orgHighlight.metadata.license_limits.admin_users;
      }

      if (orgHighlight.metadata.license_limits.advanced_users){
        delete orgHighlight.metadata.license_limits.advanced_users;
      }

      if (orgHighlight.metadata.license_limits.essentials_users){
        delete orgHighlight.metadata.license_limits.essentials_users;
      }

      if (orgHighlight.metadata.license_limits.standard_users){
        delete orgHighlight.metadata.license_limits.standard_users;
      }

      if (!orgHighlight.metadata.license_limits.devices){
        orgHighlight.metadata.license_limits.devices = "10";
      }
    }
    else{
      if (orgHighlight.metadata.license_limits){
        delete orgHighlight.metadata.license_limits
      }
    }

    if (orgHighlight.metadata.license_type == "per_user"){
      return (
        <div>
             {getLicenseType()}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>Max Admin Users*</Form.Label>
              <Col sm={8}><Form.Control type="input" name="metadata.license_limits.admin_users" value={orgHighlight.metadata.license_limits.admin_users} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>Max Advanced Users*</Form.Label>
              <Col sm={8}><Form.Control type="input" name="metadata.license_limits.advanced_users" value={orgHighlight.metadata.license_limits.advanced_users} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>Max Essentials Users*</Form.Label>
              <Col sm={8}><Form.Control type="input" name="metadata.license_limits.essentials_users" value={orgHighlight.metadata.license_limits.essentials_users} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>Max Standard Users*</Form.Label>
              <Col sm={8}><Form.Control type="input" name="metadata.license_limits.standard_users" value={orgHighlight.metadata.license_limits.standard_users} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        </div>
      )
      }
    else if (orgHighlight.metadata.license_type == "per_device"){
      return (
        <div>
             {getLicenseType()}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>Max Devices*</Form.Label>
              <Col sm={8}><Form.Control type="input" name="metadata.license_limits.devices" value={orgHighlight.metadata.license_limits.devices} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
        </div>
      )
    }
    else{
      return (
        <div>
            {getLicenseType()}
        </div>
      )
    }
   }

   function getJobRequiresReview(value){
    if (value != null){
      if (value.toLowerCase() == "true"){
        return true
      }
      else{
        return false
      }
    }
    else{
      return false;
    }
   }

   function getRightPane(){
    if (orgHighlight){
        if (!orgHighlight.metadata){
            orgHighlight.metadata = {}
        }
        if (!orgHighlight.metadata.welcome_text){
            orgHighlight.metadata.welcome_text = "";
        }
        if (!orgHighlight.metadata.primary_contact_email){
            orgHighlight.metadata.primary_contact_email = "";
        }
        if (!orgHighlight.metadata.primary_contact_name){
            orgHighlight.metadata.primary_contact_name = "";
        }
        if (!orgHighlight.metadata.status){
            orgHighlight.metadata.status = "";
        }

        if (!orgHighlight.metadata.tier){
            orgHighlight.metadata.tier = "";
        }

        if (!orgHighlight.metadata.jobs_require_review){
          orgHighlight.metadata.jobs_require_review = "false";
        }

        if (!orgHighlight.branding){
            orgHighlight.branding = {}
        }

        if (!orgHighlight.branding.logo_url){
            orgHighlight.branding.logo_url = "";
        }

        
      return (
        <Container fluid>
         <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
                {isSuperAdmin &&
                    <Nav onSelect={handleOrganizationTask}>
                    <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                    </Nav>
                }
        </Navbar>
        <Form className='form' style={{height:`${props.height - 130}px`}}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Name*</Form.Label>
            <Col sm={8}><Form.Control type="input" name="name" value={orgHighlight.name} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Display Name*</Form.Label>
            <Col sm={8}><Form.Control type="input" name="display_name" value={orgHighlight.display_name} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Logo Url*</Form.Label>
            <Col sm={8}><Form.Control type="input" name="branding.logo_url" value={orgHighlight.branding.logo_url} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Welcome Text</Form.Label>
            <Col sm={8}><Form.Control type="input" name="metadata.welcome_text" value={orgHighlight.metadata.welcome_text} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Primary Contact Email*</Form.Label>
            <Col sm={8}><Form.Control type="input" name="metadata.primary_contact_email" value={orgHighlight.metadata.primary_contact_email} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Primary Contact Name</Form.Label>
            <Col sm={8}><Form.Control type="input" name="metadata.primary_contact_name" value={orgHighlight.metadata.primary_contact_name} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Status</Form.Label>
            <Col sm={8}>
              <Form.Select name="metadata.status" value={orgHighlight.metadata.status} onChange={fieldUpdate.bind(this)}>
              <option value=""></option>
              <option value="test">Test</option>
              <option value="trial">Trial</option>
              <option value="production">Production</option>
            </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Tier</Form.Label>
            <Col sm={8}>
            <Form.Select name="metadata.tier" value={orgHighlight.metadata.tier} onChange={fieldUpdate.bind(this)}>
              <option value=""></option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
            </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Job Requires Review</Form.Label>
            <Col sm={8}><Form.Check type="switch" name="metadata.jobs_require_review" label="" checked={getJobRequiresReview(orgHighlight.metadata.jobs_require_review)} onChange={fieldCheckUpdate.bind(this)}/></Col>
          </Form.Group>
          {getLicenseFields()}
        </Form>
        </Container>
      )
    }
    else{
      //Only show the save button when no items
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
        </Navbar>
        </Container>
      )
    }
   }

   function getOrgHighlightKey(){
    if (orgHighlight){
      return orgHighlight.name;
    }
    else{
      return null;
    }
   }

   function getLeftPane(){
    var isHorizontal = true;
    var height = props.height - 150;
    if (window.innerHeight > window.innerWidth){
      isHorizontal = false;
      height = (props.height/2) - 200;
    }

    if (org){
      return (<UserList height={props.height} accessToken={props.accessToken} org={org} onBackToOrgs={handleBackToOrgs}></UserList>)
    }
    else{
      return (
        <div>
        <Row>
          <Col>
          <Navbar bg="light" variant="light">
            <Navbar.Brand>
            &nbsp;&nbsp;Organizations
            </Navbar.Brand>
              <Nav className="me-auto">
              </Nav>
              {isSuperAdmin &&
                <Nav onSelect={handleOrganizationTask}>
                    <Nav.Link eventKey="add"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;</Nav.Link>
                    <Nav.Link eventKey="delete"><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;</Nav.Link>
                </Nav>
              }
          </Navbar>
          <ListGroup defaultActiveKey={getOrgHighlightKey()} style={{height:`${height}px`, overflow:'auto'}}>
          {orgs.map((org,i) => (
            <ListGroup.Item key={org.name} eventKey={org.name} active={org.name == getOrgHighlightKey()} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,org,i)}>
              <Container fluid>
                <Row>
                  <Col><Form.Check type="checkbox" id={org.name} label={org.display_name} checked={org.checked} onChange={handleCheckChanged.bind(this, org)}/></Col>
                  <Col xs={1}><FontAwesomeIcon icon={faChevronRight}  size='1x'  onClick={handleSelect.bind(this,org,i)}/></Col>
                </Row>
              </Container>
            </ListGroup.Item>
            ))}
          </ListGroup>
          </Col>
          {isHorizontal && 
            <Col>{getRightPane()}</Col>
          }
      </Row>
      {isHorizontal == false && 
        <Row>
          <Col>{getRightPane()}</Col>
        </Row>}
        </div>
      )
    }
   }

   function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }

  return (
    <div style={{position:"relative"}}>
        <Row>
          <Col>{getLeftPane()}</Col>
        </Row>
        {getMessagePlaceholder()}
        <ConfirmDialog title="Delete" message={dialogMessage} onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
    </div>
  )  
        
}

export default OrgList;
