
import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,  faDrawPolygon, faSquareFull, faArrowPointer, faTrash, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { Button, ButtonGroup } from "react-bootstrap";
import { editMultiple, reset} from '../../controllers/mapEditController';
import eventBus from "../controls/eventBus";
import Diagram from "./diagram";
import ImageEdit from "./imageEdit";

<i class="fa-solid fa-square-dashed"></i>
//UI Placeholder div and ref for the map component, actual action is in mapController
export default function Map(props) {
  const elementRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [selectMode, setSelectMode] = useState(null);
  //const [shiftHeld, setShiftHeld] = useState(false);

/*
  function downHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
  }

  function upHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(false);
    }
  }
*/
  useEffect(() => {
    var callback = (data) => {
      toggleSelect(null);
      setEditMode(false);
    }

    eventBus.on("reset", callback);

   // window.addEventListener('keydown', downHandler);
   // window.addEventListener('keyup', upHandler);
    return () => {
      eventBus.remove("reset", callback);
      //window.removeEventListener('keydown', downHandler);
      //window.removeEventListener('keyup', upHandler);
    };
  }, []);

  useEffect(_ => {
    let cleanup;
    // lazy load the module that loads the JSAPI
    // and initialize it
    import("../../controllers/mapController").then(function(mapController){
      cleanup = mapController.initialize(elementRef.current, props.mode);
    });
      
    return () => cleanup && cleanup();
  }, [props.mode]);
  
  function toggleEdit(){
    
    setEditMode(!editMode);
    
    if (!editMode){
      if (props.mode != "d"){
        toggleSelect("point");
      }
    }
    else{
      reset()
    }
  }

  function toggleSelect(currentSelectMode){
    setSelectMode(currentSelectMode);
    editMultiple(currentSelectMode);
  }

  function deleteAttachment(){
    eventBus.dispatch("deleteAttachment", { message: "deleteAttachment" });
  }

  function zoomIn(){
    eventBus.dispatch("zoomIn", { message: "zoomIn" });
  }

  function zoomOut(){
    eventBus.dispatch("zoomOut", { message: "zoomOut" });
  }

  // assign elementRef to the ref of our component
  return (
      <div>
        {props.mode != "d" &&
          <div className="viewDiv" ref={elementRef} style={{height:`${(props.height)}px`}}></div>
        }
        {(props.mode == "d") &&
          //<Diagram style={{height:`${(props.height)}px`}} job={props.job} accessToken={props.accessToken} height={props.height}></Diagram>
          <div>
            <ImageEdit style={{height:`${(props.height)}px`}} height={props.height} canEdit={editMode}></ImageEdit>
            <div className="edittoggle">
              <Button variant="light" onClick={toggleEdit} active={editMode}>
                <FontAwesomeIcon icon={faEdit}/>
              </Button>
              {editMode &&
              <ButtonGroup>
                <Button variant="light" onClick={deleteAttachment.bind(this)}>
                <FontAwesomeIcon icon={faTrash} />
                </Button>
              </ButtonGroup>
              }
            </div>
            <div className="zoomInOut">
              <div>
                <Button variant="light" onClick={zoomIn.bind(this)} size="sm">
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              </div>
              <div>
              <Button variant="light" onClick={zoomOut.bind(this)} size="sm">
                <FontAwesomeIcon icon={faMinus}/>
              </Button>
              </div>
            </div>
          </div>
        }
        {(props.mode != "d" && props.showFeatures && props.canEdit && props.job.reviewed == false) &&
        <div className="edittoggle">
          <Button variant="light" onClick={toggleEdit} active={editMode}>
            <FontAwesomeIcon icon={faEdit}/>
          </Button>
          {editMode &&
            <ButtonGroup aria-label="Basic example">
              <Button variant="light" onClick={toggleSelect.bind(this,"point")} active={selectMode=="point"}>
                <FontAwesomeIcon icon={faArrowPointer} />
              </Button>
              <Button variant="light" onClick={toggleSelect.bind(this,"rectangle")} active={selectMode=="rectangle"}>
               <FontAwesomeIcon icon={faSquare} />
              </Button>
              <Button variant="light" onClick={toggleSelect.bind(this,"polygon")} active={selectMode=="polygon"}>
                <FontAwesomeIcon icon={faDrawPolygon} />
              </Button>
              <Button variant="light" onClick={toggleSelect.bind(this,"all")} active={selectMode=="all"}>
               <FontAwesomeIcon icon={faSquareFull} />
              </Button>
            </ButtonGroup>
          }
        </div>}
      </div>
  );
}