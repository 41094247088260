
import React, { useEffect, useRef, useState } from "react";
import { addFeatures, view, zoomToFeature, clearHighlight, zoomToLayerbyId, attachMapEvent, removeLayers } from '../../controllers/mapController';
import { setJobFeatures } from '../../controllers/mapEditController';

export default function JobFeatureLayer(props) {
  
  function getRenderer(type, uniqueValueInfos){
    var renderer;
    switch(type){
      case "Point":
        renderer = {
          type: "unique-value",  // autocasts as new UniqueValueRenderer()
          field: "job_name",
          defaultSymbol: { 
            type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
            color: [0, 255, 0],
            outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255],
            width: 2
          }
          },  
          uniqueValueInfos: uniqueValueInfos}
        break;
      case "LineString":
        renderer = {
          type: "unique-value",  // autocasts as new UniqueValueRenderer()
          field: "job_name",
          defaultSymbol: { 
            type: "simple-line",
            color: [0, 255, 0], // Orange
            width: 2
          },  
          uniqueValueInfos: uniqueValueInfos}
        break;
      case "Polygon":
        renderer = {
          type: "unique-value",  // autocasts as new UniqueValueRenderer()
          field: "job_name",
          defaultSymbol: { 
            type: "simple-fill",
            color: [0, 255, 0, 0.1],  // Orange, opacity 80%
            outline: {
                color: [0, 255, 0, 0.2],
                width: 1
            } 
          },  // autocasts as new SimpleFillSymbol()
          uniqueValueInfos: uniqueValueInfos}
        break;
    }
    return renderer;
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  function validateExtent(points){
   
    if (points != null && points[0][0] == points[1][0] &&
        points[0][0] == points[2][0] &&
        points[0][0] == points[3][0]){
         
          //When there is only a single point, all coordinates in the polygon looks the same
          //shift them so that they form a proper polygon, move each one to the for corners
          //top left x-10, y-10
          points[0][0] = points[0][0] - 0.00001;
          points[0][1] = points[0][1] - 0.00001;
          //top right x+10, y-10
          points[1][0] = points[1][0] + 0.00001;
          points[1][1] = points[1][1] - 0.00001;
           //bottom right x+10, y+10
          points[2][0] = points[2][0] + 0.00001;
          points[2][1] = points[2][1] + 0.00001;
          //bottom left x-10, y+10
          points[3][0] = points[3][0] - 0.00001;
          points[3][1] = points[3][1] + 0.00001;

          //back to 1
          points[4][0] = points[4][0] - 0.00001;
          points[4][1] = points[4][1] - 0.00001;

        }
    return points;
  }

  function createJobPolygon(jobPoly){
    var points = jobPoly.geometry.coordinates[0];
    var points = validateExtent(points);
    var f = {geometry: {
            type: "polygon", // autocasts as new Polyline()
            rings: [points]
          }, attributes:jobPoly.properties}
    return f;
  }
  function getJobColorMapping(uniqueJobs, type){
    var colors = [[51, 153, 255],[153, 51, 255],[255, 102, 178],[255, 153, 51],[76, 153, 0],[0, 204, 204],[204, 0, 204],[0, 0, 204],[255, 255, 0],[153, 0, 76],[153, 102, 51],[102, 153, 0],[51, 51, 153]]
    var index = -1

    if (type == "Point"){
      var uniqueValueInfos = uniqueJobs.map(function(j){
        index ++
        if (index > 12){
          index = 0
        }
        return {
          // All features with value of "North" will be blue
          value: j.properties.job_name,
          symbol: {
            type: "simple-marker",  // autocasts as new SimpleFillSymbol()
            color: [colors[index][0], colors[index][1], colors[index][2]],  // Orange, opacity 80%
              outline: {
                  color: [255, 255, 255],
                  width: 2
              } 
          }
        }
      });
      return uniqueValueInfos
    } else if (type == "LineString"){
      var uniqueValueInfos = uniqueJobs.map(function(j){
        index ++
        if (index > 12){
          index = 0
        }
        return {
          // All features with value of "North" will be blue
          value: j.properties.job_name,
          symbol: {
            type: "simple-line",  // autocasts as new SimpleFillSymbol()
            color: [colors[index][0], colors[index][1], colors[index][2]],  // Orange, opacity 80%
            width: 2  
          }
        }
      });
      return uniqueValueInfos
    } else {
      var uniqueValueInfos = uniqueJobs.map(function(j){
        index ++
        if (index > 12){
          index = 0
        }
        return {
          // All features with value of "North" will be blue
          value: j.properties.job_name,
          symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: [colors[index][0], colors[index][1], colors[index][2], 0.1],  // Orange, opacity 80%
              outline: {
                  color: [colors[index][0], colors[index][1], colors[index][2], 0.2],
                  width: 1
              } 
          }
        }
      });
      return uniqueValueInfos
    }
    
    
    
  }
  function addJobPolygon(){
    //-104.99841442967549, 39.75346414165405
    if (props.jobPolygon){
      var f = createJobPolygon(props.jobPolygon)
      
      var features = [f];
      if (props.jobPolygonCompare){
        props.jobPolygonCompare.forEach(function(jc){
          var fc = createJobPolygon(jc)
          features.push(fc);
        })
      }
      var uniqueValueInfos = getJobColorMapping(props.jobPolygonCompare)
      var renderer = getRenderer(props.jobPolygon.geometry.type, uniqueValueInfos);
      var fieldInfos = props.fields.map(function(f){
        return {fieldName: f.name, label: f.alias}
      });

      const pTemplate = {
        // autocasts as new PopupTemplate()
        title: "{name}",
        content: [
          {
            // It is also possible to set the fieldInfos outside of the content
            // directly in the popupTemplate. If no fieldInfos is specifically set
            // in the content, it defaults to whatever may be set within the popupTemplate.
            type: "fields",
            fieldInfos: fieldInfos
          }
        ]
      };

      addFeatures(features, renderer, props.fields, null, "f-jobpoly", "Job Polygon", true);
      zoomToLayerbyId("f-jobpoly");
    } 
  }

  function addJobFeatures(){
    
    //To add job features to the map, the workflow are as follow:
    //1. Split features by feature class (e.g Pole, Electric Cabinet, Manhole etc) since they have different attributes
    //2. Dynamically derrive fields, popup template, renderer for each feature class
    //3. Add each feature class to the map

    //Split features by feature class:
    //first get the unique feature classes:
    var featureClasses = props.jobFeatures.map(item => item.properties.FeatureClass)
    .filter((value, index, self) => self.indexOf(value) === index && value != "Polygon");
    
    featureClasses.forEach(function(fc){
      //Get features for feature class
      var featuresForFC = props.jobFeatures.filter(function(f){
        return f.properties.FeatureClass == fc;
      })

      //Get renderer
      var uniqueValueInfos = getJobColorMapping(props.jobPolygonCompare, featuresForFC[0].geometry.type)
      var renderer = getRenderer(featuresForFC[0].geometry.type, uniqueValueInfos);

      //Get Fields
      var fieldInfos = [];
      for (const key in featuresForFC[0].properties) {
        fieldInfos.push({fieldName: key, label: key})
      }

      var fields = [{
        name: "ObjectID",
        alias: "ObjectID",
        type: "oid"
      },
      {
        name: "id",
        alias: "ID",
        type: "string"
      },
      {
        name: "job_name",
        alias: "Job Name",
        type: "string"
      }
    ];
      for (const key in featuresForFC[0].properties) {
        fields.push({name: key, alias: key, type:"string"})
      }
     /*
      const viewFeatureAction = {
        title: "View Job Feature",
        id: "view-feature"
      };

      const pTemplate = {
        // autocasts as new PopupTemplate()
        title: "{ssp-id}",
        content: [
          {
            // It is also possible to set the fieldInfos outside of the content
            // directly in the popupTemplate. If no fieldInfos is specifically set
            // in the content, it defaults to whatever may be set within the popupTemplate.
            type: "fields",
            fieldInfos: fieldInfos
          }
        ],
        actions: [viewFeatureAction]
      };

       // Execute each time the "View job" is clicked
    function viewFeature() {
      if (props.jobFeatures && props.jobFeatures.length >0){
        const jobFeature = view.popup.selectedFeature;
        var feature = props.jobFeatures.filter(function(f){
          return f.properties["ssp-id"] == jobFeature.attributes["ssp-id"];
        });
        if (feature && feature.length>0){
          props.onViewFeature(feature[0]);
        }
      }
    }

    // Event handler that fires each time an action is clicked.
    view.popup.on("trigger-action", (event) => {
      // Execute the measureThis() function if the measure-this action is clicked
      if (event.action.id === "view-feature") {
        view.popup.close();
        clearHighlight();
        viewFeature();
      } 
    });
*/

      attachMapEvent("click", function(event){
        view.hitTest(event)
          .then(function(response){
            if (response.results.length > 0) {
              
              var g = response.results.filter(function (result) {
                // check if the graphic belongs to the layer of interest
                return result.graphic.layer.id.indexOf("f-") >= 0;
              })[0].graphic;

              //Get ssp-id
              var feature = props.jobFeatures.filter(function(f){
                return f.properties["uuid"] == g.attributes["uuid"];
              });

              if (feature && feature.length>0){
                props.onViewFeature(feature[0]);
              }
              
              console.log(g.attributes);
            } 
          });
      });
   
      var features = featuresForFC.map(function(f){

        if (featuresForFC[0].geometry.type == "Point"){
          return {geometry: {
            type: "point",
            x: f.geometry.coordinates[0],
            y: f.geometry.coordinates[1],
            z: f.geometry.coordinates[2]
          }, attributes:f.properties}
        }
        else if(featuresForFC[0].geometry.type == "LineString"){

          return {geometry: {
            type: "polyline", // autocasts as new Polyline()
            paths: f.geometry.coordinates
          }, attributes:f.properties}
        }
        else if(featuresForFC[0].geometry.type == "Polygon"){

          return {geometry: {
            type: "polygon", // autocasts as new Polyline()
            rings: f.geometry.coordinates
          }, attributes:f.properties}
        }
      });

      //filter out bad geometries
      
      var validFeatures = features.filter(function(f){
        if (f && f.geometry && f.geometry.type == "point"){
         // f.attributes.id = f.attributes["ssp-id"]; //copy for query since query doesn't like dash
          return f.geometry.latitude != 0 && f.geometry.longitude !=0;
        }
        else{
          return true;
        }
      })
      
      if (featuresForFC[0].geometry.type == "Point"){
        var pointFeatures = validFeatures.filter(function(f){
          return f && f.geometry && f.geometry.type == "point";
        })
        addFeatures(pointFeatures, renderer, fields, null, "f-" + fc, fc, true);
      }
      else if(featuresForFC[0].geometry.type == "LineString"){
        var lineFeatures = validFeatures.filter(function(f){
          return f && f.geometry && f.geometry.type == "polyline";
        })
        addFeatures(lineFeatures, renderer, fields, null, "f-" + fc, fc, true);
      }
    });
  }

  //Add jobs to map
  useEffect(_ => {
      if (props.jobFeatures != null && props.jobFeatures.length > 0){
        addJobPolygon();
        addJobFeatures(props.jobFeatures);
        setJobFeatures(props.jobFeatures)
      } else {
        removeLayers()
      }
  }, [props.jobFeatures]);

  //Zoom to job centroid when a job is selected in the list
  useEffect(_ => {
    if (props.jobFeature){
      zoomToFeature("uuid='" + props.jobFeature.properties["uuid"] + "'","f-" + props.jobFeature.properties.FeatureClass, false) 
    }
    else{
      clearHighlight();
    }
  }, [props.jobFeature]);
  

  // assign elementRef to the ref of our component
  return (
    <div/>
   
  );
}