
import React, { useEffect, useRef } from "react";
import { addFeatures, view, zoomToFeature, clearHighlight, zoomToLayerbyId} from '../../controllers/mapController';

export default function GeoFeatureLayer(props) {

  function addGeoFeatures(geofeatures, type){
    var features = [];
    var fields = [];
    geofeatures.forEach(function(feature,i){
      var lat = feature.Latitude, lon = feature.Longitude
      if (type == "ar"){
        lat = feature.ARLatitude
        lon = feature.ARLongitude
      }
      if (lat && lon){
        feature["ObjectID"] = i+1
        features.push({
          geometry: {
            type: "point",
            latitude: lat,
            longitude: lon
          },
          attributes: feature
        });
      }
    });
    
    var color = "blue";
    var outlineColor = [255, 255, 255];
    if (type == "vps"){
      color = "red"
    }
    else if (type == "origin"){
      color = "white"
      outlineColor = "green"
    }
    else if (type == "ar"){
      color = "green"
    }
    else if (type == "final"){
      color = "yellow"
    }

    var renderer = {
      type: "simple",  // autocasts as new SimpleRenderer()
      symbol: {
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      style: "circle",
      color: color,
      size: "12px",  // pixels
      outline: {
      // autocasts as new SimpleLineSymbol()
      color: outlineColor,
      width: 1
      }
      }
    };

    var fields = [
      {name: "ObjectID",
        alias: "ObjectID",
        type: "oid"}
    ]
    
    Object.keys(geofeatures[0]).forEach(key => {
     fields.push({
      name:key,
      alias:key,
      type:"string"
     })
    });
    
    var fieldInfos = fields.map(function(f){
      return {fieldName: f.name, label: f.alias}
    });
    const pTemplate = {
      // autocasts as new PopupTemplate()
      title: "{Name}",
      content: [
        {
          // It is also possible to set the fieldInfos outside of the content
          // directly in the popupTemplate. If no fieldInfos is specifically set
          // in the content, it defaults to whatever may be set within the popupTemplate.
          type: "fields",
          fieldInfos: fieldInfos
        }
      ]
    };
  
    addFeatures(features, renderer, fields, pTemplate, "f-geofeature" + type, "Feature location " + type, false, 2);
  }

  function degressOfLongitude(meters, originLat){
    let latitudeRadians = (originLat * Math.PI) / 180
    let metersPerLongitudeDegree = 40075 * 1000 * Math.cos(latitudeRadians) / 360
    let degrees = meters / metersPerLongitudeDegree

    return degrees
  }
  
  
  function degreesOfLatitude(meters){
    let metersPerLatitudeDegree = 111.32 * 1000
    let degrees = meters / metersPerLatitudeDegree
    return degrees
  }

  function convertARCoordsToLatLon(feature, origin){
      var x = feature["Feature_AR_X-Coordinate"] * 1.0 
      var y = feature["Feature_AR_Z-Coordinate"] * -1.0 

      var originX = origin["Feature_AR_X-Coordinate"] * 1.0
      var originY = origin["Feature_AR_Z-Coordinate"] * -1.0

      //Find the difference in meters between origin and feature
      var diffX = x - originX;
      var diffY = y - originY;

      var diffLat = degreesOfLatitude(diffY)
      var diffLon = degressOfLongitude(diffX, origin["Latitude"])

      feature["ARLatitude"] = origin["Latitude"] * 1.0 + diffLat;
      feature["ARLongitude"] = origin["Longitude"] * 1.0 + diffLon;
  }
  
  //Add features to map
  useEffect(_ => {
    
      if (props.geoFeatures != null && props.geoFeatures.length > 0){
        if (props.geoFeatures[0].Type){
          //split into gps and vps layers
          var gpsGeoFeatures = props.geoFeatures.filter(function(row){
            return row.Type == "gps"
          })
          if (gpsGeoFeatures.length > 0){
            addGeoFeatures(gpsGeoFeatures, "gps");
          }

          var originFeatures = props.geoFeatures.filter(function(row){
            return row.Type.indexOf("origin") >= 0
          })
          if (originFeatures.length > 0){
            addGeoFeatures(originFeatures, "origin");
          }

          var vpsGeoFeatures = props.geoFeatures.filter(function(row){
            return row.Type.indexOf("vps") >= 0
          })
          if (vpsGeoFeatures.length > 0){
            addGeoFeatures(vpsGeoFeatures, "vps");

            //Add features from AR Coordinates
            //First we need to inject AR derrived Lat Lon
            var hasOrigin = false
            vpsGeoFeatures.forEach(function(f){
              //Find the matching origin
              var origin = originFeatures.filter(function(o){
                return f.UUID == o.UUID;
              })
              if (origin.length >0){
                convertARCoordsToLatLon(f, origin[0])
                hasOrigin = true
              }
            })
            if (hasOrigin){
              addGeoFeatures(vpsGeoFeatures, "ar");
            }
          }
          
          var finalFeatures = props.geoFeatures.filter(function(row){
            return row.Type.indexOf("final") >= 0
          })
          if (finalFeatures.length > 0){
            addGeoFeatures(finalFeatures, "final");
          }
        }
        else{
          addGeoFeatures(props.geoFeatures, "gps");
        }
        
      }

  }, [props.geoFeatures]);
  return (
    <div />
  );
}