
import React, { useEffect, useRef } from "react";

//UI Placeholder div and ref for the canvas component, actual action is in canvasController
export default function Canvas(props) {
  const elementRef = useRef();
  var minX = 0, minY = 0, maxX = 0, maxY = 0
  var padX = 0, padY = 0

  function addGeoFeatures(geofeatures){
    var ctx = elementRef.current.getContext("2d");
    
    geofeatures.forEach(function(f,i){
      var x = f["Feature_AR_X-Coordinate"] * 5.0
      var y = f["Feature_AR_Z-Coordinate"] * 5.0
      if (x < minX){
        minX = x
      }
      if (x > maxX){
        maxX = x
      }

      if (y < minY){
        minY = y
      }
      if (y > maxY){
        maxY = y
      }
    })
    if (minX < 0){
      padX = minX * -1
    }

    if (minY < 0){
      padY = minY * -1
    }

    elementRef.current.width = maxX + padX + 30
    elementRef.current.height = maxY + padY + 30
    
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, elementRef.current.width, elementRef.current.height);

    geofeatures.forEach(function(f,i){
      
        var x = f["Feature_AR_X-Coordinate"] * 5.0 + padX + 10
        var y = f["Feature_AR_Z-Coordinate"] * 5.0 + padY + 10
    
        ctx.beginPath();
        if (f.Type.indexOf("origin") >= 0){
          ctx.fillStyle = "#FFFFFF";
         }
         else if (f.Type.indexOf("final") >= 0){
          ctx.fillStyle = "#FFFF00"
         }
         else{
          ctx.fillStyle = "#32CD32";
         }
        ctx.fillRect(x,y,3,3);
        //ctx.fillText(f.Type,x,y);
        //ctx.stroke();

    })
    
    
  }

  useEffect(_ => {
    if (props.geoFeatures && props.geoFeatures.length > 0 && props.geoFeatures[0].Type){
      var vpsGeoFeatures = props.geoFeatures.filter(function(row){
        return row.Type.indexOf("vps") >= 0
      })
      var originGeoFeatures = props.geoFeatures.filter(function(row){
        return row.Type.indexOf("origin") >= 0
      })
      var finalGeoFeatures = props.geoFeatures.filter(function(row){
        return row.Type.indexOf("final") >= 0 
      })
      addGeoFeatures(finalGeoFeatures.concat(vpsGeoFeatures).concat(originGeoFeatures));
  
    }
  }, [props.geoFeatures]);
  
  // assign elementRef to the ref of our component
  return (
      <div className="viewDiv">
        <canvas
        ref={elementRef}
        id="myCanvas"
        width="200"
        height="100"
        style={{ border: "1px solid #d3d3d3" }}
      >
        Your browser does not support the HTML canvas tag.
      </canvas>
      </div>
  );
}