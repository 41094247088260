
import React, { useEffect, useState } from 'react';
import {Modal, Button} from "react-bootstrap";

function ConfirmDialog(props) {

  const [deleteOther, setDeleteOther] = useState(false);

  const handleClose = () => props.onClose();

  const handleCheckChanged = (e) => {
    setDeleteOther(e.currentTarget.checked);
  }

  const handleConfirm = () => {
    props.onConfirm(deleteOther);
  }

return (
    <Modal show={props.message != null}>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.message}</Modal.Body>
    <Modal.Footer>
      {props.deleteOther &&
      <span style={{float:"left", width:"80%"}}>
        <input type="checkbox" id="deleteOther" checked={deleteOther} onChange={handleCheckChanged.bind(this)}/>
        <span>&nbsp;&nbsp;{props.deleteOther}</span>
      </span>
        
      }
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleConfirm}>
        OK
      </Button>
    </Modal.Footer>
  </Modal>
)
  
}

export default ConfirmDialog;
