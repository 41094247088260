import React, { useEffect, useRef, useState } from "react";
import {map, view} from '../../controllers/mapController';
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import IdentifyParameters from "@arcgis/core/rest/support/IdentifyParameters";
import * as identify from "@arcgis/core/rest/identify";

//External map layers directly from ArcGIS map/feature services
export default function ExternalMapLayer(props) {
     //Add jobs to map
    useEffect(_ => {
        if (props.mapLayers != null && props.mapLayers.length > 0){
            addExternalLayers(props.mapLayers);
        }
        return () => {
           removeExternalLayers();
          };
    }, [props.mapLayers]);

    function removeExternalLayers(){
        map.layers.forEach(layer => {
            if (layer.id.indexOf("e-jobType") == 0){
              map.remove(layer);
            }
        });
    }

    function addExternalLayers(layerUrls){
        //First remove existing layers from another job type if any
        removeExternalLayers();

        //Add job type layers
        layerUrls.forEach(function(layerConfig){
            //parse layer url to determine what layer it is
            //https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/NDFD_HourlyTemperature_v1/FeatureServer
            //https://sampleserver6.arcgisonline.com/arcgis/rest/services/SampleWorldCities/MapServer
            if (layerConfig.mapLayer){
                let layerUrl = layerConfig.mapLayer.toLowerCase()

                if (layerUrl.indexOf("featureserver")>0){
                    let iStart = layerUrl.indexOf("/rest/services/");
                    let iEnd = layerUrl.indexOf("/featureserver");
                    let layerId = "e-" + props.level + "-fs-" + layerUrl.substr(iStart+15, iEnd-iStart-15)

                    const layer = new FeatureLayer({
                        // Notice that the url doesn't end with /2
                        url: layerConfig.mapLayer,
                        id:layerId
                    });
                
                    view.popup.defaultPopupTemplateEnabled = true;
                    const popupTemplate = layer.createPopupTemplate();
                    layer.popupTemplate = popupTemplate;
                    
                    map.add(layer);

                }else if(layerUrl.indexOf("mapserver")>0){
                    let iStart = layerUrl.indexOf("/rest/services/");
                    let iEnd = layerUrl.indexOf("/mapserver");
                    let layerId = "e-" + props.level + "-ms-" + layerUrl.substr(iStart+15, iEnd-iStart-15)

                    const layer = new MapImageLayer({
                        url: layerConfig.mapLayer,
                        id: layerId
                    });

                    map.add(layer);  // adds the layer to the map

                    layer.loadAll().then(function(){
                        for (let sublayer of layer.allSublayers) {
                            var popupTemplate = sublayer.createPopupTemplate();
                            var fields = popupTemplate.fieldInfos.map(function(fi){
                                return fi.fieldName
                            })
                            if (fields.indexOf("ASSETGROUP")>=0 && fields.indexOf("ASSETTYPE")>=0){
                                popupTemplate.title = "{ASSETGROUP} - {ASSETTYPE}";
                            }
                            
                            sublayer.popupTemplate = popupTemplate;
                        }

                        addIdentifyPopup(layerConfig);
                    })
                }
            }
        });
    
    }

    function addIdentifyPopup(layerConfig){
        view.when(function () {
            // executeIdentify() is called each time the view is clicked
            view.on("click", executeIdentify);
    
            // Set the parameters for the identify
            var params = new IdentifyParameters();
            params.tolerance = 3;
            params.layerIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            params.layerOption = "top";
            params.width = view.width;
            params.height = view.height;

            function executeIdentify(event) {
                // Set the geometry to the location of the view click
                params.geometry = event.mapPoint;
                params.mapExtent = view.extent;
                //document.getElementById("viewDiv").style.cursor = "wait";
        
                // This function returns a promise that resolves to an array of features
                // A custom popupTemplate is set for each feature based on the layer it
                // originates from
                identify
                  .identify(layerConfig.mapLayer, params)
                  .then(function (response) {
                    const results = response.results;
        
                    return results.map(function (result) {
                      let feature = result.feature;
                      let layerName = result.layerName;
        
                      feature.attributes.layerName = layerName;
                     
                       /* feature.popupTemplate = {
                          // autocasts as new PopupTemplate()
                          title: layerName,
                          content:
                            ""
                        };
                      */
                      return feature;
                    });
                  })
                  .then(showPopup); // Send the array of features to showPopup()
        
                // Shows the results of the identify in a popup once the promise is resolved
                function showPopup(response) {
                  if (response.length > 0 && response.popupTemplate) {
                    //view.openPopup({
                    //  features: response,
                    //  location: event.mapPoint
                    //});
                    view.popup.open({
                        features: response,
                        location: event.mapPoint
                    });
                  }
                  //document.getElementById("viewDiv").style.cursor = "auto";
                }
            }
          });

    }
}