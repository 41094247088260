import React, { useEffect, useState } from 'react';
import {Container} from "react-bootstrap";
import { getHashParam, setHash} from '../../controllers/hashController';

function DocContainer(props) {

    const [doc, setDoc] = useState(null);
    const [hash, setHash] = useState(null);
    /*
     const [height, setHeight] = useState(window.innerHeight - 100);
    const [width, setWidth] = useState(window.innerWidth - 100);

    function getDocUrl (){
        return "/" + props.doc + ".html"
    }
    return (
        <Container>
            <div style={{ paddingTop: "20px", paddingBottom: "50px" }} >
                <iframe src={getDocUrl()} width={width} height={height} title={props.doc}></iframe>
            </div>
        </Container>
    )
    */

    useEffect(() => {
        
        var hash = ""
        var docName = props.doc
        if (docName.indexOf("#")>0){
            docName = props.doc.substr(0,props.doc.indexOf("#"));
            hash = props.doc.substr(props.doc.indexOf("#") + 1, props.doc.length - props.doc.indexOf("#") - 1);
        }
        const docPath = require("../../docs/" + docName + ".htm");
        
        fetch(docPath)
        .then(response => {
            return response.text()
        })
        .then(text => {
            //setDoc("<div>test<div>")
            setDoc(text);
             // Scroll to the element with the specified ID (if it exists)
             setHash(hash);
        })
        
    },[props.doc])
    
    useEffect(() => {
            // Add event listener to intercept link clicks within the rendered HTML
            const container = document.getElementById('doc-content');
    
            if (container) {
                container.addEventListener('click', handleLinkClick);
            }
            
            if (hash){
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                } else {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "instant"
                      });
                }
            } else {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "instant"
                  });
            }

            // Cleanup event listener on component unmount
            return () => {
                if (container) {
                    container.removeEventListener('click', handleLinkClick);
                }
            };
    }, [doc]);
    
        const handleLinkClick = (event) => {
            // Check if the clicked element is a link
            const target = event.target;
            if (target.tagName === 'A') {
                event.preventDefault(); // Prevent the default browser behavior
                const href = target.getAttribute('href');
    
                if (href && href.indexOf('.htm')>=0) {
                    // Extract the doc name from the href and navigate within the React app
                    const docName = href.replace('.htm', '').replace(/^\//, '');
                    props.onHyperlink(docName);
                    
                } else {
                    // For other links (external), let the browser handle them
                    window.location.href = href;
                }
            }
        };
    return (
        <Container>
            <div style={{ paddingTop: "20px", paddingBottom: "50px" }} >
            {doc != null &&
                <div id="doc-content" dangerouslySetInnerHTML={{__html: doc}}/>
            }
            </div>
        </Container>
    )
}

export default DocContainer;