
import Search from "@arcgis/core/widgets/Search";
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import '@arcgis/core/assets/esri/themes/light/main.css';
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import Basemap from "@arcgis/core/Basemap";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Query from "@arcgis/core/rest/support/Query";
import LayerView from "@arcgis/core/views/layers/LayerView";
import Measurement from "@arcgis/core/widgets/Measurement";
import DirectLineMeasurement3D from "@arcgis/core/widgets/DirectLineMeasurement3D";
import esriConfig from "@arcgis/core/config";
import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
import NavigationToggle from "@arcgis/core/widgets/NavigationToggle";
import LayerList from "@arcgis/core/widgets/LayerList";
import Expand from "@arcgis/core/widgets/Expand";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import SceneLayer from "@arcgis/core/layers/SceneLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

//An @arcgis/core implementation:
//https://www.npmjs.com/package/@arcgis/core
//https://www.youtube.com/watch?v=0fJmKSWURyU
//https://github1s.com/odoe/jsapi-esm-react/blob/HEAD/src/data/webmap.js#L23-L24

//This class contains all the core methods for interacting with the ESRI map and ArcGIS JS API
const noop = () => {};
var highlightSelect;
var mode = "2d";
var tilt;
var nextBasemap;
//esriConfig.apiKey= "AAPKe4fab663a3e64e9aba443cb28d57081cDi00xRPeDVp4rKYrYZakM10Vc7zFU_h8xhFFmCKDaGyCx7NejmtvporPGw6XyVLH";

export const map = new ArcGISMap({
    basemap: "topo-vector",
	ground: "world-elevation"
	//basemap: "hybrid"
  });

var initialViewParams = {
    map: map,
    ui: {
		components: ['attribution', 'zoom', 'compass'],
	},
	constraints: {
		// Disable zoom snapping to get the best synchronization
		snapToZoom: false
	  }
  }



// convenience function for creating either a 2D or 3D view dependant on the type parameter
function createView(params, type) {
	var mapview;
	var x = map;
	if (type === "2d") {
		mapview = new MapView(params);
	} else {
		mapview = new SceneView(params);
	}

	if (view && view.extent){
		mapview.extent = view.extent;
	}

	if (view && view.camera && view.camera.tilt)
	{
		tilt = view.camera.tilt;
	}

	if (view && view.scale){
		mapview.center = view.center;
		mapview.scale = view.scale;
		if (tilt){
			
		}
		
	}

	//Add mapbox basemap:
	/*const mapboxSatellite = new WebTileLayer({
		urlTemplate:
		"https://api.mapbox.com/styles/v1/fshih1/clcrv6pt3000715perjzb4ws9/tiles/256/{level}/{col}/{row}@2x?access_token=pk.eyJ1IjoiZnNoaWgxIiwiYSI6ImNsY3J2MDVvejBqaDczb3BoY29kNmxxb2MifQ.kyrYK8CoLtinsmEfxcBQMw",
		copyright: "© OpenStreetMap contributors Design © Mapbox"
	});*/

	//Add mapbox basemap:
	/*
	const mapboxSatellite = new WebTileLayer({
		urlTemplate:
		"https://api.mapbox.com/styles/v1/fshih1/clcrv6pt3000715perjzb4ws9/tiles/256/{level}/{col}/{row}@2x?access_token=pk.eyJ1IjoiZnNoaWgxIiwiYSI6ImNsY3J2MDVvejBqaDczb3BoY29kNmxxb2MifQ.kyrYK8CoLtinsmEfxcBQMw",
		copyright: "© OpenStreetMap contributors Design © Mapbox"
	});

	let mapboxSatelliteBasemap = new Basemap({
		baseLayers: [
			mapboxSatellite
		]
	});
	*/

	//https://api.nearmap.com/tiles/v3/{tileResourceType}/{z}/{x}/{y}.{format}?apikey=NTA2MmVlN2YtZDJmNy00NTUzLWJiNDctMGM2ZjIxZmI0YTUx 
	//https://api.nearmap.com/tiles/v3/Vert/21/1855981/1265938.jpg?
	//https://api.nearmap.com/wms/v1/latest/apikey/APIKEY?service=WMS&request=GetCapabilities.
	const nearMap = new WebTileLayer({
		urlTemplate:
		"https://us0.nearmap.com/maps/z={z}&x={x}&y={y}&version=2&nml=Vert&client=wmts_integration&httpauth=false&apikey=NTA2MmVlN2YtZDJmNy00NTUzLWJiNDctMGM2ZjIxZmI0YTUx",
		copyright: "© Nearmap Vertical"
	});

	let nearMapBasemap = new Basemap({
		baseLayers: [
			nearMap
		]
	});

	const googleMap = new WebTileLayer({
		urlTemplate:
		//Google
		"http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
		copyright: "© Google"
	});

	let googleBasemap = new Basemap({
		baseLayers: [
			googleMap
		]
	});

	var basemapToggle;
	if (map.basemap.title == "Basemap"){
		basemapToggle = new BasemapToggle({
			view: mapview,
			//nextBasemap: "arcgis-imagery"
			nextBasemap:"streets-vector"
		});
	}
	else{
		basemapToggle = new BasemapToggle({
			view: mapview,
			//nextBasemap: "arcgis-imagery"
			//"streets-vector"
			//nextBasemap:mapboxSatelliteBasemap
			nextBasemap:nearMapBasemap
			//nextBasemap:googleBasemap
		});
	}
	mapview.ui.add(basemapToggle,"bottom-right");

	const search = new Search({ view: mapview });
	mapview.ui.add(search, "top-right");

	var layerList = new LayerList({
		container: document.createElement("div"),
		view: mapview,
		listItemCreatedFunction: function(event){
		  const item = event.item;
		  // displays the legend for each layer list item
		  item.panel = {
			content: "legend",
			open: false
		  };
		}
	  });
	const layerListExpand = new Expand({
		expandIconClass: "esri-icon-layer-list",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
		expandTooltip: layerList.label,
		view: mapview,
		content: layerList.domNode,
		expanded: false,
		group: "top-right"
	  });

	  mapview.ui.add(layerListExpand, "top-right");
	
	  if (mode == "3d"){
		var measurement = new DirectLineMeasurement3D({
			container: document.createElement("div"),
			view: mapview,
			//activeTool: "distance"
		  });

		  const measurementExpand = new Expand({
			expandIconClass: "esri-icon-measure",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
			expandTooltip: measurement.label,
			view: mapview,
			content: measurement.domNode,
			expanded: false,
			group: "top-right"
		  });

		  reactiveUtils.when(
			() => measurementExpand.expanded === false,
			() => {
				if (measurement) {
					view.ui.remove(measurement);
					measurement.destroy();
					measurement = null;
				  }
			}
		  );
	
		  reactiveUtils.when(
			() => measurementExpand.expanded === true,
			() => {
				// skip the initial 'new measurement' button
				measurement.viewModel.start()
			}
		  );

		  mapview.ui.add(measurementExpand, "top-right");
	
	  }
	  else{
		var measurement = new Measurement({
			container: document.createElement("div"),
			view: mapview,
			//activeTool: "distance"
		  });
		  const measurementExpand = new Expand({
			expandIconClass: "esri-icon-measure",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
			expandTooltip: measurement.label,
			view: mapview,
			content: measurement.domNode,
			expanded: false,
			group: "top-right"
		  });
	
		  
		  reactiveUtils.when(
			() => measurementExpand.expanded === false,
			() => {
				measurement.clear();
			}
		  );
	
		  reactiveUtils.when(
			() => measurementExpand.expanded === true,
			() => {
				measurement.activeTool = "distance";
			}
		  );
		
		  mapview.ui.add(measurementExpand, "top-right");
	  }


	  var coordsWidget = document.createElement("div");
	  coordsWidget.id = "coordsWidget";
	  coordsWidget.className = "esri-widget esri-component";
	  coordsWidget.style.padding = "7px 15px 5px";

	  mapview.ui.add(coordsWidget, "bottom-right");

	  function showCoordinates(pt) {
		/*
		var coords =
		  "Lat/Lon " +
		  pt.latitude.toFixed(3) +
		  " " +
		  pt.longitude.toFixed(3) +
		  " | Scale 1:" +
		  Math.round(view.scale * 1) / 1 +
		  " | Zoom " +
		  view.zoom;
		*/
		if (pt){
			var coords =
		  	"Lat/Lon " +
		  	pt.latitude.toFixed(8) +
		  	" " +
		  	pt.longitude.toFixed(8)

			coordsWidget.innerHTML = coords;
		}
		
	  }

	  mapview.watch("stationary", function (isStationary) {
		showCoordinates(view.center);
	  });
	  
	  mapview.on("pointer-move", function (evt) {
		showCoordinates(view.toMap({ x: evt.x, y: evt.y }));
	  });

	

	let scaleBar = new ScaleBar({
		view: mapview
	  });
	  // Add widget to the bottom left corner of the view
	  mapview.ui.add(scaleBar, {
		position: "bottom-left"
	  });

	if (mode == "3d"){
		// creates a new instance of the NavigationToggle widget
		let navigationToggle = new NavigationToggle({
		view: mapview
		});
	
		// and adds it to the top right of the view
		mapview.ui.add(navigationToggle, "top-right");

		/*
		let sceneLayer = new SceneLayer({
			url: "https://tiles.arcgis.com/tiles/z2tnIkrLQ2BRzr6P/arcgis/rest/services/San_Diego_Without_CC/SceneServer/layers/0"
		  });

		  map.add(sceneLayer);
		
		  let sceneLayer2 = new SceneLayer({
			url: "https://tiles.arcgis.com/tiles/z2tnIkrLQ2BRzr6P/arcgis/rest/services/San_Diego_Convention_Center/SceneServer/layers/0"
		  });

		  map.add(sceneLayer2);
		*/ 
	}


	return mapview;
  }

export var view = createView(initialViewParams, mode);

var callbacks = [];

export const initialize = (container, type) => {
  mode = type;
  view = createView(initialViewParams, mode);
  view.container = container;
  //add callback
	for (const key in callbacks) {
		view.on(key, callbacks[key]);
	}

  view
    .when()
    .then(_ => {
      console.log("Map and View are ready");
    })
    .catch(noop);
  return () => {
    view.container = null;
	
  };
};



export const attachMapEvent = (name, callback) => {
	callbacks[name] = callback
	view.on(name, callback);
};

export async function toggleLayer(layername, visible){
	var layer = map.findLayerById(layername);
	if (layer){
		layer.visible = visible;
	}
};

export async function removeLayers(){
	//Remove only job features layer with "f-" prefix
	var layers = map.layers.items;
	for (var i = layers.length - 1; i >= 0; --i) {
		var layer = layers[i];
		if (layer.id.indexOf("f-") >= 0){
			map.layers.remove(layer);
		}
	}
	
};

export async function addFeatures(features, renderer, fields, pTemplate, id, title, visible, order){

	if (features && features.length > 0){
		// geometryType and spatialReference of the layer
		// will be inferred from the first feature in the array
		// if it has a geometry.
		let layer = new FeatureLayer({
			id:id,
			title:title,
			source: features,  // autocast as a Collection of new Graphic()
			objectIdField: "ObjectID",
			fields: fields,
			popupTemplate: pTemplate,
			visible:visible,
			maxScale: 0
		});

		layer.outFields = ['*'];
		layer.renderer = renderer;

		//Remove any existing layer..
		var lyrExisting = map.findLayerById(id);
		if (lyrExisting){
			map.remove(lyrExisting);
		}
		map.add(layer, order);
	} else {
		var lyrExisting = map.findLayerById(id);
		if (lyrExisting){
			map.remove(lyrExisting);
		}
	}
	
}

export async function zoomToPoint(lat,lon) {
	view.goTo({
		center: [lon, lat],
		zoom: 20
	  }); 
}

export async function zoomToFeature(where,layerId,toZoom=true) {

	var featureLayer = map.findLayerById(layerId);
	if (featureLayer){
		let query = featureLayer.createQuery();
		query.where = where;
	
		featureLayer.queryFeatures(query).then(function(response){
			// returns a feature set with features
			var feature = response.features[0];
			view.whenLayerView(featureLayer)
			.then(function(layerView) {
			// The layerview for the layer
				if (highlightSelect) {
					highlightSelect.remove();
				}
				
				// set the highlight on the first feature returned by the query
				highlightSelect = layerView.highlight(feature);

				if (toZoom){
					// center the feature
					view
					.goTo(
					{
						target: feature.geometry,
						tilt: 0,
						heading:0,
						zoom:21
					},
					{
						duration: 2000,
						easing: "in-out-expo"
					}
					)
					.catch((error) => {
					if (error.name != "AbortError") {
						console.error(error);
					}
					});
				}
			})
			.catch(function(error) {
			// An error occurred during the layerview creation
			});
		});
	}
}

export async function clearHighlight(){
	if (highlightSelect) {
		highlightSelect.remove();
	}
}

export async function zoomToLayerbyId(layerId, zoomToSingle=false) {
	var layer = map.findLayerById(layerId);
	if (layer){
		return layer.queryExtent().then((response) => {
			if (response.count == 1 && zoomToSingle){
				//Single point
				view.goTo(
					{
						target: response.extent.center,
						tilt: 0,
						heading:0,
						zoom:19
					});
				
			}
			else{
				view.goTo(response.extent.expand(1.1)).catch((error) => {
					console.error(error);
				  });
			}
		  });
	}
	else{
		return null;
	}
}

export async function zoomToLayer(layer) {
	return layer.queryExtent().then((response) => {
	  view.goTo(response.extent).catch((error) => {
		console.error(error);
	  });
	});
}

  
