import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, ToggleButton, ButtonGroup, Form, Toast, Modal, Button } from "react-bootstrap";
import { getMyJobs, getOrganizationJobs, fields, deleteJob, downloadJob, uploadJob, downloadJobCsv } from '../../controllers/jobController';
import { getConfig} from '../../controllers/configController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTrash, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons'
import JobFeatureList from './jobFeatureList';
import Map from './map';
import JobLayer from './jobLayer';
import fileDownload from 'js-file-download';
import ConfirmDialog from '../controls/confirmDialog';
import { getHashParam, setHash} from '../../controllers/hashController';
import jwt_decode from "jwt-decode";
import ExternalMapLayer from './externalLayer';
import eventBus from "../controls/eventBus";

function JobList(props) {

  const [dialogMessage, setDialogMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [notFilteredJobs, setNotFilteredJobs] = useState([]);
  const [job, setJob] = useState(null);
  const [highlightjob, setHighlightJob] = useState(null);
  const [mapMode, setMapMode] = useState("2d");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filter, setFilter] = useState(null);
  const [noJobs, setNoJobs] = useState(true);
  const [config, setConfig] = useState(null);

  const [paging, setPaging] = useState(1);
  const [pagingEnd, setPagingEnd] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [pagingKey, setPagingKey] = useState(null);

  const ref = React.createRef();
  
  //Get which tab to open from url
   //Get job mode from # hash in url
   var mode = getHashParam(window.location.hash,1);
   if (!mode){
     mode = "myjobs"; //default to jobs
     setHash({jobmode:mode},"jobmode",1);
   }

  //Set jobmode from # hash in url
  const [jobmode, setJobMode] = useState(mode);
  
  const openDiagram = (e) => {
    setMapMode("d");
  }

  eventBus.on("openDiagram", openDiagram);

  const handleMapMode = (key, e) => {
    setMapMode(key);
  };

  const handleHighlighted = (job, e) => {
    setHighlightJob(job);
  };

  const handleJobMode = (key, e) => {
    setJobMode(key);
    setPaging(1);
    setPagingKey(null);
    setHash({jobmode:key},"jobmode",1);
  };
  
  const handleJobSelect = (job, e) => {
    //update url to add jobname to hash
    if (job){
      setHash(job,"name",2);
    }
    setJob(job);
  };

  const handleJobView = (job, e) => {
    setHighlightJob(job);
    setJob(job);
    setHash(job,"name",2);
  };

  const handleApproved = (job) => {
    setJob(job);
  }

  const handleBackToJobs = (job) => {
    var clonedJobs = [...jobs] //for jobs update to reflect comment update
    setJobs(clonedJobs);

    setJob(null);
    setHash(null,null,2);
    setMapMode("2d");
  };

  const handleCheckChanged = (job, e) => {
    var clonedJobs = [...jobs]//Clone the array before update, so that we get proper state change
    var selectedJob = clonedJobs.filter(function(j){
      return j.uuid == job.uuid;
    })[0];
    if (selectedJob){
      selectedJob.checked = e.currentTarget.checked;
      setJobs(clonedJobs);
    }
  };

  const handleFileInput = async (e) => {
    var files = e.target.files;
    setSelectedFiles(files);
  };

  const handleFileUpload = async (key, e) => {
    setFileUpload(false);
    setMessage("Uploading, please wait...");
    Object.keys(selectedFiles).forEach(function(key){
      uploadJob(props.accessToken, selectedFiles[key]).then(function(result){
        if (result.status == 200){
          setMessage("Job uploaded successfully, it will take a moment to unpack.");
          setTimeout(function(){
            setMessage(null);
          }, 3000);
        }
        else{
          setMessage("Failed to Upload");
          setTimeout(function(){
            setMessage(null);
          }, 3000);
        }
      })
    })
  };

  const handleFileUploadClose = async (key, e) => {
    setFileUpload(false);
  };

  const handleDeleteConfirm = (e) => {
    setDialogMessage(null);

    var selectedJobs = jobs.filter(function(j){
      return j.checked;
    })

   
    selectedJobs.forEach(async function(job){
      setMessage("Deleting...");
      var result = await deleteJob(props.accessToken, job.uuid);
      
      if (result.result == "success"){
        setMessage("Job deleted sucessfully...");
      }
      else{
        setMessage("Deletion failed: " + result.message);
      }

      setTimeout(function(){
        setMessage(null);
        //delete jobs from UI
        var leftJobs = jobs.filter(function(j){
          return !j.checked;
        })
        setJobs([...leftJobs]);
      }, 3000)
    });
    
    
  }

  const handleDeleteCancel = (e) => {
    setDialogMessage(null);
  }

  const handleJobTask = async (key, e) => {
    var selectedJobs = jobs.filter(function(j){
      return j.checked;
    })
  
    switch(key){
      case "delete":
        //delete jobs from server
        var deleteMsg;
        if (selectedJobs.length == 1){
          deleteMsg = "Delete " + selectedJobs[0].name + "?";
        }
        else{
          deleteMsg = "Delete " + selectedJobs.length + " jobs?";
        }
        setDialogMessage(deleteMsg);
        break;

        case "download":
          //download jobs from server
          if (selectedJobs.length > 0){
            setMessage("Downloading...");
            try{
              selectedJobs.forEach(async function(job){
                var result = await downloadJob(props.accessToken, job.uuid);
                setTimeout(function(){
                  setMessage(null);
                }, 1000)
    
                if (result){
                  fileDownload(result, job.name + ".zip");
                }
              });
            }
            catch(ex){
              setMessage(ex.message);
              setTimeout(function(){
                setMessage(null);
              }, 3000)
            }
          }
          break;

          case "downloadCsv":
            //download jobs from server
            if (selectedJobs.length > 0){
              setMessage("Downloading...");
              try{
                var result = await downloadJobCsv(props.accessToken, selectedJobs.map(function(j){
                  return j.uuid;
                }));
                setTimeout(function(){
                  setMessage(null);
                }, 1000)
    
                if (result){
                  fileDownload(result, "features.csv");
                }
              }
              catch(ex){
                setMessage(ex.message);
                setTimeout(function(){
                  setMessage(null);
                }, 3000)
              }
            }
            break;

         case "upload":
            //show a modal dialog with file input
            setFileUpload(true);
            break;

      default:
    }
  };
  
  function getJobs(orgId, pagingKey){
    
    //Need to determine if we can fetch from cache or need to fetch from the server, i.e. are we paging forward or backward?

    switch(jobmode){
      case "myjobs":
        return getMyJobs(props.accessToken, orgId, pagingKey);

      case "organization":
        return getOrganizationJobs(props.accessToken, orgId, pagingKey);
      
      default:
    }
  }

  function loadJobs(orgId){
    function sort_by_key(array, key)
      {
        //Sort desc
        return array.sort(function(a, b)
        {
          var x = a[key]; var y = b[key];
          return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
      }

      getJobs(orgId, pagingKey).then(function(newFetchedjobs){
        getConfig(props.accessToken, orgId).then(function(config){
          if (config.data != ''){
            setConfig(config.data);
          }
        });
        if (newFetchedjobs.items.length > 0){
          setNoJobs(false);
          setPagingEnd(paging + newFetchedjobs.items.length -1);
          if (newFetchedjobs.count){
            setTotalJobs(newFetchedjobs.count);
          }
          setPagingKey(newFetchedjobs.lastEvaluatedKey);
        } else {
          setPaging(0)
          setPagingEnd(0)
          setTotalJobs(0)
          setPagingKey(null)
        }

        var newjobs = sort_by_key(newFetchedjobs.items,"date")
        setNotFilteredJobs(newjobs);
        if (filter && filter.length >0){
          applyFilterText(newjobs, filter)
        }
        else{
          setJobs(newjobs);
        }
        
          //if url has job set, switch to the job
          var jobname = getHashParam(window.location.hash,2);
          if (jobname){
            //find the job in jobs to switch
            var job = newjobs.filter(function(j){
              return j.name == jobname;
            });
            setTimeout(function(){
              if (job.length > 0){
                //Switch to job
                setHighlightJob(job[0]);
                setJob(job[0]);
              }
              else{
                //not finding jobs in the current tab (jobmode)...
                //switch job mode to organization..
                setJobMode("organization");
                setHash({jobmode:"organization"},"jobmode", 1); //overwrite job hash when we switched organization
              }
            },100);
          }
    }); 
  }
  
  //Initial screen load
  useEffect(() => {

   if (props.superAdminOrgId){
    loadJobs(props.superAdminOrgId)
   }
   else if (props.accessToken){
      loadJobs();
   }
      
  },[jobmode, props.accessToken, props.superAdminOrgId, paging]);

  useEffect(_ => {
    //Scroll highlighted item into view (when selected from the map)
    if (ref && ref.current){
      setTimeout(function(){
        if (ref.current.getBoundingClientRect().bottom > window.innerHeight) {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
      }
  
      if (ref.current.getBoundingClientRect().top < 0) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } 
        
      },200)
      
    }
  }, [highlightjob, job]);

  function getHighlightJob(){
    var jobname = " ";
    if (highlightjob){
      jobname = highlightjob.name;
    }
    return jobname;
  }

  function getActiveStatus(job){
    if (highlightjob && job && job.name == highlightjob.name){
      return true;
    }
    else{
      return false;
    }
  }

  function getActiveRef(job){
    if (highlightjob && job && job.name == highlightjob.name){
      return ref;
    }
    else{
      return null;
    }
  }

  function applyFilter(e){
    var filterText = e.currentTarget.value;//.toLowerCase();
    applyFilterText(notFilteredJobs, filterText);
  }

  function applyFilterText(jobsToFilter, filterText){
    if (filterText == null || filterText == ""){
      setJobs(jobsToFilter);
    }
    else{
      var filteredJobs = jobsToFilter.filter(function(j){
        var attr = JSON.stringify(j).toLowerCase();
        return attr.indexOf(filterText.toLowerCase()) >= 0;
      })
      
      setJobs([...filteredJobs]);
    }
    setFilter(filterText);
  }

  function getBadgeColor(job){
    if (props.org.metadata && props.org.metadata.jobs_require_review && props.org.metadata.jobs_require_review == 'true'){
      if (job.reviewed == false){
        return "warning"
      }
      else{
        return "success"
      }
    }
    else{
      return "primary"
    }
  }

  function canEdit(job){
    if (props.org.metadata && props.org.metadata.jobs_require_review && props.org.metadata.jobs_require_review == 'true'){
      if (job != null && job.reviewed == false){
        return true
      }
      else{
        return false
      }
    }
    else{
      return false
    }
  }

  /*
  function handlePagingPrev(){
    if (paging>1){
      setPaging(paging-50)

    }
  }*/

  function handlePagingNext(){
    //if (paging + 50 < totalJobs){
      if (pagingKey){
        setPaging(pagingEnd+1);
      } else {
        setPaging(1);
      }
      
    //}
  }

  function getPagingControl(){

    return (<span>&nbsp;&nbsp;
      {/*paging>1 &&
      <FontAwesomeIcon className="paging" icon={faChevronLeft} size='sm' onClick={handlePagingPrev.bind(this)}/>
      */}
      &nbsp;&nbsp;{paging}-{pagingEnd} of {totalJobs}&nbsp;&nbsp;
      {(jobs.length<totalJobs) &&
      <FontAwesomeIcon className="paging" icon={faChevronRight} size='sm' onClick={handlePagingNext.bind(this)}/>
      }
      &nbsp;&nbsp;
    </span>
    )
  }

  function getJobPane(height, isHorizontal){
    var decoded = jwt_decode(props.accessToken);
    var roles = decoded["https://sspvision.net/roles"];
   // var showAll = (roles.indexOf("vision-superadmin") >= 0);
    var showDelete = false;
    if (mode == "myjobs"){
      showDelete = true;
    }
    else{
      showDelete = (roles.indexOf("vision-advanced-user") >= 0 || roles.indexOf("vision-superadmin") >= 0 ||roles.indexOf("vision-admin") >= 0);
    }
    var showUploadDownload = (roles.indexOf("vision-superadmin") >= 0);
    var showOrganization = (roles.indexOf("vision-singleuser") < 0);
    
    if (job != null){
      var selectedJobs = jobs.filter(function(j){
        return j.checked;
      })
      return (<JobFeatureList mode={mapMode} accessToken={props.accessToken} height={height} org={props.org} job={job} selectedJobs={selectedJobs} config={config} fields={fields} canEdit={canEdit(job)} onBackToJobs={handleBackToJobs} onHighlighted={handleHighlighted.bind(this,job)} onApproved={handleApproved}></JobFeatureList>)
    }
    else{
      return (
        <div>
          <Navbar bg="light" variant="light">
                <Nav onSelect={handleJobMode} defaultActiveKey={jobmode} className="me-auto">
                  <Nav.Link eventKey="myjobs">My Jobs&nbsp;&nbsp;</Nav.Link>
                  {showOrganization &&
                    <Nav.Link eventKey="organization">Organization&nbsp;&nbsp;</Nav.Link>
                  }
                </Nav>
                {isHorizontal &&
                  <span>
                     {getPagingControl()}
                    <Form.Control style={{height:`25px`, width:`50%`, display:`inline`}} type="input" name="filter" value={filter} onChange={applyFilter.bind(this)}/>
                  </span>
                }
                <Nav onSelect={handleJobTask}>
                {showUploadDownload &&
                  <Nav.Link eventKey="downloadCsv"><FontAwesomeIcon icon={faFileCsv} />&nbsp;&nbsp;</Nav.Link>
                 }
                 {showUploadDownload &&
                  <Nav.Link eventKey="download"><FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;</Nav.Link>
                 }
                 {showUploadDownload &&
                  <Nav.Link eventKey="upload"><FontAwesomeIcon icon={faUpload} />&nbsp;&nbsp;</Nav.Link>
                 }
                 {showDelete &&
                    <Nav.Link eventKey="delete"><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;</Nav.Link>
                 }
                </Nav>
          </Navbar>
          {isHorizontal == false &&
                  <div>
                    <Form.Control style={{height:`25px`, width:`75%`, display:`inline`}} type="input" name="filter" value={filter} onChange={applyFilter.bind(this)}/>

                  </div>
          }
          {
            (jobmode == "myjobs" && noJobs == true) &&
            <div style={{padding:"20px"}}>You can download the SSP Vision app from the App Store to capture data.</div>
          }
          { (jobmode != "myjobs" || noJobs == false) &&
            <ListGroup defaultActiveKey={getHighlightJob()} style={{height:`${height - 150}px`, overflow:'auto'}}>
            {jobs.map((job) => (
              <ListGroup.Item key={job.uuid} eventKey={job.name} action active={getActiveStatus(job)} ref={getActiveRef(job)} className="d-flex justify-content-between align-items-start" onClick={handleHighlighted.bind(this,job)}>
                {isHorizontal &&
                  <Container fluid>
                    <Row>
                      <Col sm={4}>
                        <Form.Check type="checkbox" id={job.uuid} label={job.name} checked={job.checked} onChange={handleCheckChanged.bind(this, job)}/>
                      </Col>
                        <Col>{jobmode != "myjobs" && job.user_name}&nbsp;&nbsp; {job.comments}</Col>
                        <Col sm={1}><Badge bg={getBadgeColor(job)} pill>{job.feature_count}</Badge></Col>
                      <Col sm={1}><FontAwesomeIcon icon={faChevronRight}  size='1x'  onClick={handleJobSelect.bind(this,job)}/></Col>
                    </Row>
                  </Container>
                }
                {isHorizontal == false &&
                 <Container fluid="sm">
                  <Row>
                  <Col className="d-flex justify-content-between align-items-start">
                      <span>
                        <input type="checkbox" id={job.uuid} label={job.name} checked={job.checked} onChange={handleCheckChanged.bind(this, job)}/>
                        <label for={job.uuid}>&nbsp;&nbsp;{job.name}</label>
                      </span>
                      <span>
                      <Badge bg={getBadgeColor(job)} pill>{job.feature_count}</Badge>&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faChevronRight} size='1x' onClick={handleJobSelect.bind(this,job)}/>
                      </span>
                    </Col>
                  </Row>
                  {job.user_name != "" &&
                    <Row>
                    <Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{job.user_name}</Col>
                    </Row>
                  }
                  {job.comments != "" &&
                    <Row>
                    <Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{job.comments}</Col>
                    </Row>
                  }
                  </Container>
                }
              </ListGroup.Item>
              ))}
          </ListGroup>
          }
      </div>
    );
    }
  }

  function getFileUploadDialog(){
      return (
        <Modal show={fileUpload}>
          <Modal.Header closeButton>
            <Modal.Title>Select a job zip file to upload</Modal.Title>
          </Modal.Header>
          <Modal.Body><input type="file" multiple accept='.zip' onChange={handleFileInput}/></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFileUploadClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleFileUpload}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      )
  }

  function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }
 
  function getMapLayers(){
    if (config){
      return config.mapLayers
    }
    else{
      return []
    }
  }

  var isHorizontal = true;
  if (window.innerHeight > window.innerWidth){
    isHorizontal = false;
  }
  if (isHorizontal){
    return (
      <div style={{position:"relative"}}>
        {getFileUploadDialog()}
        <ConfirmDialog title="Delete" message={dialogMessage} onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
        <Row>
        <Col>{getJobPane(props.height, isHorizontal)}</Col>
        <Col style={{position:"relative"}}>
          <div className='maptoggle'>
            <ButtonGroup>
            <ToggleButton variant="secondary" size="sm" key="2d" id={`radio-2d`} type="radio" 
              name="radio" value="2d" checked={mapMode === "2d"} onChange={(e) => handleMapMode(e.currentTarget.value)}>2D
            </ToggleButton>
            <ToggleButton variant="secondary" size="sm" key="3d" id={`radio-3d`} type="radio"
              name="radio" value="3d" checked={mapMode === "3d"} onChange={(e) => handleMapMode(e.currentTarget.value)}>3D
            </ToggleButton>
            {job!=null &&
              <ToggleButton variant="secondary" size="sm" key="d" id={`radio-d`} type="radio"
                name="radio" value="d" checked={mapMode === "d"} onChange={(e) => handleMapMode(e.currentTarget.value)}>Diagram
              </ToggleButton>
            }
            </ButtonGroup>
          </div>
          <Map mode={mapMode} height={(props.height-70)} showFeatures={job!=null} job={job} accessToken={props.accessToken} canEdit={canEdit(job)}></Map>
          <JobLayer jobs={jobs} fields={fields} job={job} highlight={highlightjob} onViewJob={handleJobView} onHighlightJob={handleHighlighted} />
          <ExternalMapLayer mapLayers={getMapLayers()} level="org"></ExternalMapLayer>
        </Col>
      </Row>
      {getMessagePlaceholder()}
      </div>
    )  
  }
  else{
    return (
      <div style={{position:"relative"}}>
        {getFileUploadDialog()}
        <ConfirmDialog title="Delete" message={dialogMessage} onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
        <Row>
        <Col style={{position:"relative"}}>
          <div className='maptoggle'><ButtonGroup>
            <ToggleButton variant="secondary" size="sm" key="2d" id={`radio-2d`} type="radio" 
              name="radio" value="2d" checked={mapMode === "2d"} onChange={(e) => handleMapMode(e.currentTarget.value)}>2D
            </ToggleButton>
            <ToggleButton variant="secondary" size="sm" key="3d" id={`radio-3d`} type="radio"
              name="radio" value="3d" checked={mapMode === "3d"} onChange={(e) => handleMapMode(e.currentTarget.value)}>3D
            </ToggleButton>
            <ToggleButton variant="secondary" size="sm" key="d" id={`radio-d`} type="radio"
              name="radio" value="d" checked={mapMode === "d"} onChange={(e) => handleMapMode(e.currentTarget.value)}>D
            </ToggleButton>
        </ButtonGroup></div>
          <Map mode={mapMode} height={(props.height/3*2)} showFeatures={job!=null} job={job} accessToken={props.accessToken}  canEdit={canEdit(job)}></Map>
          <JobLayer jobs={jobs} fields={fields} job={job} highlight={highlightjob} onViewJob={handleJobView} onHighlightJob={handleHighlighted} />
          <ExternalMapLayer mapLayers={getMapLayers()} level="org"></ExternalMapLayer>
        </Col>
      </Row>
      <Row>
      <Col>{getJobPane(props.height, isHorizontal)}</Col>
      </Row>
      {getMessagePlaceholder()}
      </div>
    )
  }
}

export default JobList;
