import React, { useEffect, useState } from 'react';
import {Container, ListGroup, Row, Col, Form, Button, Navbar, Nav} from "react-bootstrap";
import { getApprovals, approveInvite, exportApprovals, getDemoUsers } from '../../controllers/authController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import fileDownload from 'js-file-download';
import { getHashParam, setHash} from '../../controllers/hashController';

function Approval(props) {
    const [approvals, setApprovals] = useState([]);
    const [comment, setComment] = useState(null);
   
    const [demoUsers, setDemoUsers] = useState([]);

    var mode = getHashParam(window.location.hash,1);
   if (!mode){
     mode = "pending"; //default to jobs
     setHash({displayMode:mode},"displayMode",1);
   }
   
  //Set usermode from # hash in url
  const [displayMode, setDisplayMode] = useState(mode);

    useEffect(() => {

      if (displayMode == "demoUsers"){
        getDemoUsers(props.accessToken).then(function(users){
          var sortedUsers = sort_by_key(users, "last_login")
          setDemoUsers(sortedUsers);
          
        }); 
      }
      else{
        getApprovals(props.accessToken, displayMode).then(function(approvals){
          var sortedApprovals = sort_by_key(approvals, "requestts")
          setApprovals(sortedApprovals)
        }); 
      }
    },[displayMode])

    function sort_by_key(array, key)
      {
        //Sort desc
        return array.sort(function(a, b)
        {
          var x = a[key]; var y = b[key];
          return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
      }

    const handleApprove = async (approval, e) => {
        var result = await approveInvite(props.accessToken, approval.uuid, true, comment);
        if (result.status == "200"){
            approval.approved = true
        }
        else{
            approval.error = result.data.message
        }
        var clonedApprovals = [...approvals] 
        setApprovals(clonedApprovals)
    }

    const handleDeny = async (approval, e) => {
       var result = await approveInvite(props.accessToken, approval.uuid, false, comment);
       if (result.status == "200"){
            approval.denied = true
       }
       else{
        approval.error = result.data.message
       }
       var clonedApprovals = [...approvals] 
       setApprovals(clonedApprovals)
    }

    const handleDisplayMode = (key, e) => {
        setDisplayMode(key)
        setHash({displayMode:key},"displayMode",1);
      };
    //<Col><Form.Check type="checkbox" id={approval.uuid} label={approval.name} checked={approval.checked} onChange={handleCheckChanged.bind(this, approval)}/></Col>

    function getApprovalButtons(approval){
        if (approval.approved){
            return <div><b>Approved</b></div>
        }
        else if(approval.denied || (approval.toreview == false && approval.approved == false)){
            return <div><b>Denied</b></div>
        }
        else if(approval.error){
            return <div>{approval.error}</div>
        }
        else{
            return (
                <div>
                    <Button variant="outline-danger" type="button" onClick={handleDeny.bind(this, approval)}>Deny</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="success" type="button" onClick={handleApprove.bind(this, approval)}>Approve</Button>
                </div>
            )
        }
    }

    function fieldUpdate(e){
        setComment(e.currentTarget.value);
    }
    
    const handleTask = async (key, e) => {
       if (key == "download"){
        if (displayMode != "demoUsers"){
            var exportStream = await exportApprovals(props.accessToken, displayMode)
            if (exportStream){
                fileDownload(exportStream,  "approvals.csv");
              }
         }
         else{
            downloadDemoUsers();
        }
      } 
    }

    function getDemoUsersDownload(){
      var csv = "Created, Name, Email, Company, Verified, Last Login, Jobs Count, Latest Job\n";
      demoUsers.forEach(function(user){
        csv += formatDate(user.created_at) + ",";
        csv += user.name + ",";
        csv += user.email + ",";
        csv += getCompany(user) + ",";
        csv += getEmailVerified(user) + ",";
        csv += formatDate(user.last_login) + ",";
        csv += getJobs(user) + ",";
        csv += getLastJob(user)  + "\n";
      })
      return csv;
    }
    function downloadDemoUsers(){
      const csvContent = `${getDemoUsersDownload()}`;
      fileDownload(csvContent,  "approvals.csv");
    }

    function formatDate(dt){
        //2023-05-05T16:30:36Z
        if (dt){
            var localDate = new Date(dt);
            var formattedDate = localDate.toLocaleString();//.substring(0, 16);
            formattedDate = formattedDate.replace(",", " ");
            return formattedDate
        }
    }
    
    function getComments(approval){
        if (approval.toreview == false){
            return (approval.comments)
        }
        else{
            return (<Form.Control style={{height:`25px`}} type="input" onChange={fieldUpdate.bind(this)}/>)
        }
    }

    function getCompany(user){
      if (user.user_metadata && user.user_metadata.Company){
        return user.user_metadata.Company;
      }
      else{
        return "";
      }
    }

    function getEmailVerified(user){
      if (user.email_verified && user.email_verified == true){
        return "Yes";
      }
      else{
        return "No";
      }
    }

    function getJobs(user){
      if (user.app_metadata && user.app_metadata.job_count){
        return user.app_metadata.job_count.toString();
      }
      else{
        return "";
      }
    }

    function getLastJob(user){
      if (user.app_metadata && user.app_metadata.latest_job){
        return user.app_metadata.latest_job.toString();
      }
      else{
        return "";
      }
    }

    function getDemoUsersList(demoUsers){
      
      if (demoUsers != null && demoUsers.length > 0){
        return (
          <ListGroup style={{height:`${props.height - 150}px`, overflow:'auto'}}>
            <ListGroup.Item key={1} eventKey={1} action className="d-flex justify-content-between align-items-start">
              <Container fluid>
                  <Row>
                    <Col xs={1}>Created</Col>
                    <Col xs={2}>Name</Col>
                    <Col xs={2}>Email</Col>
                    <Col xs={1}>Company</Col>
                    <Col xs={1}>Verified</Col>
                    <Col xs={1}>Last Login</Col>
                    <Col xs={1}>#Jobs</Col>
                    <Col xs={1}>Latest Job</Col>
                  </Row>
                </Container>
               </ListGroup.Item>
            {demoUsers.map((user) => (
              <ListGroup.Item key={user.user_id} eventKey={user.user_id} action className="d-flex justify-content-between align-items-start">
                <Container fluid>
                  <Row>
                    <Col xs={1}>{formatDate(user.created_at)}</Col>
                    <Col xs={2}>{user.name}</Col>
                    <Col xs={2}>{user.email}</Col>
                    <Col xs={1}>{getCompany(user)}</Col>
                    <Col xs={1}>{getEmailVerified(user)}</Col>
                    <Col xs={1}>{formatDate(user.last_login)}</Col>
                    <Col xs={1}>{getJobs(user)}</Col>
                    <Col xs={1}>{getLastJob(user)}</Col>
                  </Row>
                </Container>
                </ListGroup.Item>
              ))}
          </ListGroup>
      )
      }
    }

    function getPendingList(approvals){
        if (approvals.length > 0){
            var fieldWidth = 2
            if (displayMode == "reviewed"){
                fieldWidth = 1
            }
            return (
                <ListGroup style={{height:`${props.height - 150}px`, overflow:'auto'}}>
                  <ListGroup.Item key={1} eventKey={1} action className="d-flex justify-content-between align-items-start">
                    <Container fluid>
                        <Row>
                          <Col xs={1}>Requested</Col>
                          <Col xs={fieldWidth}>Name</Col>
                          <Col xs={3}>Business Email</Col>
                          <Col xs={1}>Company</Col>
                          <Col xs={1}>Comments</Col>
                          <Col xs={1}>Decision Comments</Col>
                          <Col xs={1}>Decision</Col>
                          {displayMode == "reviewed" &&
                            <Col xs={1}>Decided On</Col>
                          }
                          {displayMode == "reviewed" &&
                            <Col xs={1}>Decided By</Col>
                          }
                        </Row>
                      </Container>
                     </ListGroup.Item>
                  {approvals.map((approval) => (
                    <ListGroup.Item key={approval.uuid} eventKey={approval.uuid} action className="d-flex justify-content-between align-items-start">
                      <Container fluid>
                        <Row>
                          <Col xs={1}>{formatDate(approval.requestts)}</Col>
                          <Col xs={fieldWidth}>{approval.name}</Col>
                          <Col xs={3}>{approval.email}</Col>
                          <Col xs={1}>{approval.company}</Col>
                          <Col xs={1}>{approval.usage != 'null' && 
                            approval.usage
                          }</Col>
                          <Col xs={1}>{getComments(approval)}</Col>
                          <Col xs={fieldWidth}>
                          {getApprovalButtons(approval)}
                          </Col>
                          {displayMode == "reviewed" &&
                            <Col xs={1}>{formatDate(approval.decisionts)}</Col>
                          }
                          {displayMode == "reviewed" &&
                            <Col xs={1}>{approval.approver}</Col>
                          }
                        </Row>
                      </Container>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
            )
        }
        else{
            return (
                <Container fluid>
                        <Row>
                          <Col></Col>
                          <Col>No pending request</Col>
                          <Col></Col>
                        </Row>
                      </Container>
            )
        }
    }

    function getDemoUsersCount(){
      return "(" + demoUsers.length + ")"
    }

    return (
        <div>
        <Navbar bg="light" variant="light" expand="lg">
              <Navbar.Collapse>
                <Nav onSelect={handleDisplayMode} defaultActiveKey={displayMode} className="me-auto my-2 my-lg-0">
                  <Nav.Link eventKey="pending">Pending&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="reviewed">Reviewed&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="demoUsers">Demo Users{demoUsers.length > 0 && getDemoUsersCount()}&nbsp;&nbsp;</Nav.Link>
                </Nav>
                <Nav onSelect={handleTask}>
                  <Nav.Link eventKey="download"><FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;</Nav.Link>
                </Nav>
              </Navbar.Collapse>
        </Navbar>
        {displayMode != "demoUsers" &&
        getPendingList(approvals)}
        {displayMode == "demoUsers" &&
        getDemoUsersList(demoUsers)}
        </div>
    )
    
}

export default Approval;