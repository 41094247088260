import axios from 'axios';
import { getAppConfig } from "../config";

//Get approvals
export const getApprovals = async (accessToken, displayMode) => {
    const appconfig = getAppConfig();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      
      var url = appconfig.api + '/inviteRequests';
     
      if (displayMode == "reviewed"){
        url += "?toreview=false"
      }

      try{
        /*
        {
	"uuid": "0ee1099a-65c3-46f8-935d-396af4ed7d57",
	"name": "Jeffrey Johnson",
	"email": "ortelius@gmail.com",
	"company": "SSP",
	"usage": "Testing INVALID Invite Code",
	"toreview": true,
	"approved": false,
	"requestts": "2023-05-05T16:30:36Z",
	"code": "foo34"
}
type AppInvitation struct {
	Uuid       string `dynamodbav:"uuid" json:"uuid"`
	Name       string `dynamodbav:"name" json:"name"`
	Email      string `dynamodbav:"email" json:"email"`
	Company    string `dynamodbav:"company" json:"company"`
	Usage      string `dynamodbav:"usage" json:"usage"`
	ToReview   bool   `dynamodbav:"toreview" json:"toreview"`
	Approved   bool   `dynamodbav:"approved" json:"approved"`
	RequestTS  string `dynamodbav:"requestts" json:"requestts,omitempty"`
	DecisionTS string `dynamodbav:"decisionts" json:"decisionts,omitempty"`
	Approver   string `dynamodbav:"approver" json:"approver,omitempty"`
	Comments   string `dynamodbav:"comments" json:"comments,omitempty"`
	InviteCode string `dynamodbav:"code" json:"code,omitempty"`
}
        */
        var approvals = await axios.get(url, config);
        return approvals.data;
      }
      catch(ex){
        return [];
      }
  }

  export const getDemoUsers = async (accessToken, displayMode) => {
    const appconfig = getAppConfig();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      
      var url = appconfig.api + '/demoUsers';

      try{
        var users = await axios.get(url, config);
        return users.data;
      }
      catch(ex){
        return [];
      }
  }

  export const getDevices = async (accessToken, orgId) => {
    const appconfig = getAppConfig();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      
      var url = appconfig.api + '/device';
      if (orgId){
        url += "/" + orgId;
      }
      try{
        var devices = await axios.get(url, config);
        return devices.data;
      }
      catch(ex){
        return [];
      }
  }

  export const updateDeviceName = async (accessToken, orgId, device) => {
    const appconfig = getAppConfig();
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var url = appconfig.api + '/device';
    if (orgId){
      url += "/" + orgId;
    }
  
    try{
    
      var response = await axios.put(url, device, header);
      
      return response;
      
    }
    catch(ex){
      return ex;
    }
  }

  export const approveInvite = async (accessToken, uuid, approval, comment) => {
    const appconfig = getAppConfig();
    const header = {
        headers: { Authorization: `Bearer ${accessToken}` }
    };
  
    var body = {"approved":`${approval}`, "comments":`${comment}`}
   
    var url = appconfig.api + '/inviteRequestApproval/' + uuid;
   
    try{
    
      var response = await axios.post(url, body, header);
      return response;
      
    }
    catch(ex){
      return ex.response;
    }
  }

  ///inviteRequests/export
//Get approvals
export const exportApprovals = async (accessToken, displayMode) => {
    const appconfig = getAppConfig();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      
      var url = appconfig.api + '/inviteRequests/export';
     
      if (displayMode == "reviewed"){
        url += "?toreview=false"
      }

      try{
        var approvals = await axios.get(url, config);
        return approvals.data
      }
      catch(ex){
        return [];
      }
  }
//https://api.dev.sspvision.net/organizations/create
export const createOrg = async (accessToken, org) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/create';
  //var orgCreate = {"Name":org.name, "Display Name":org.display_name}
  try{
  
    var response = await axios.post(url, org, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

export const updateOrg = async (accessToken, org) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + org.id;
  var org_copy = {...org};
  delete org_copy.id;

  try{
  
    var response = await axios.put(url, org_copy, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

export const deleteOrg = async (accessToken, org) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + org.id;
  
  try{
  
    var response = await axios.delete(url, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

//Get organizations
export const getOrgs = async (accessToken) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var orgurl = appconfig.api + '/organizations/all';
    
    try{
      var orgs = await axios.get(orgurl, config);
      return orgs.data;
    }
    catch(ex){
      return [];
    }
}

//Get Users
//organizations/:orgId/members
export const getUsers = async (accessToken, orgId, pagingKey) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var orgurl = appconfig.api + '/organizations/' + orgId + "/members/details";
    
    var countUrl = orgurl + "/count";

    orgurl += "?limit=50"

    if (pagingKey){
      orgurl += "&lastEvaluatedKey=" + encodeURI(pagingKey);
    }

    try{
      var orgs = await axios.get(orgurl, config);

      var users = orgs.data;
      //re-map the structure, take user up one level
      let newUsersStruc = users.map(function(user){
        var newUserStruc = user.User
        newUserStruc.Roles = user.Roles
        return newUserStruc;
      }).sort(function(a,b){
        if (Date.parse(a.created_at) > Date.parse(b.created_at)){
          return -1;
        } else if (Date.parse(a.created_at) < Date.parse(b.created_at)){
          return 1;
        }
        else{
          return 0;
        }
      });
      //users.items = newUsersStruc;

     // if (!pagingKey){
     //   var count = await axios.get(countUrl, config);
     //   newUsersStruc.count = count.data.count;
     // } 
      return newUsersStruc
    }
    catch(ex){
      return [];
    }
}

//Get Invites
//organizations/:orgId/invitations
export const getInvites = async (accessToken, orgId) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var orgurl = appconfig.api + '/organizations/' + orgId + "/invitations";
    
    try{
      var orgs = await axios.get(orgurl, config);
      return orgs.data;
    }
    catch(ex){
      return [];
    }
}

//organizations/roles
export const getRoles = async (accessToken) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var rolesurl = appconfig.api + '/organizations/roles';
    
    try{
      var roles = await axios.get(rolesurl, config);
      return roles.data;
    }
    catch(ex){
      return [];
    }
}

///organizations/:orgId/:userId/roles
export const getUserRoles = async (accessToken, orgId, userId) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var rolesurl = appconfig.api + '/organizations/' + orgId + "/" + userId + "/roles";
    
    try{
      var roles = await axios.get(rolesurl, config);
      return roles.data;
    }
    catch(ex){
      return [];
    }
}

//organizations/:orgId/:userId/roles
export const updateUserRoles = async (accessToken, orgId, user) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var rolesurl = appconfig.api + '/organizations/' + orgId + "/" + user.user_id + "/roles";
  var roles = {"roles":user.roles}
  try{
  
    var response = await axios.put(rolesurl, roles, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

//https://api.dev.sspvision.net/organizations/create
export const createInvite = async (accessToken, orgId, user) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + orgId + "/invitations";
  var invite = {"email": user.email, "roles":user.roles}
  try{
  
    var response = await axios.post(url, invite, header);
    
    return response;
    
  }
  catch(ex){
    return ex.response;
  }
}

export const deleteUserInvite = async (accessToken, orgId, userId) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + orgId + "/invitations/" + userId;
  
  try{
  
    var response = await axios.delete(url, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

///organizations/members/:userId"
export const deleteUser = async (accessToken, orgId, userId) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + orgId + '/members/' + userId;
  
  try{
  
    var response = await axios.delete(url, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}
