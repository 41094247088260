
import React, { useEffect, useState } from 'react';
import {Modal, Button, Form, Row, Col, ListGroup, Container} from "react-bootstrap";
import { checkSignInStatus, getCredential, destroyCredential, getPortalItems, getFeatureLayers, getFeatureLayerFields } from '../../controllers/esriAuthController';

function EsriServiceDialog(props) {

  const [featureServiceUrl, setFeatureServiceUrl] = useState(null);
  const [layers, setLayers] = useState([]);
  const [layer, setLayer] = useState("all");
  const [folder, setFolder] = useState(null);
  const [fields, setFields] = useState([]);
  const [portalItems, setPortalItems] = useState([]);
  const [externalLinkType, setExternalLinkType] = useState(null);
  const [direction, setDirection] = useState(null);
  const [includeDomains, setIncludeDomains] = useState(null);
  const [itemId, setItemId] = useState("all");

  var hasPortal = (props.portals && props.portals.length > 0);
  const [showLogin, setShowLogin] = useState(hasPortal); //The state to login
  const [showDialog, setShowDialog] = useState(!hasPortal); //The state to display portal info/service modal dialog

  var propsPortalUrl = "";
  var propsClientId = "";
  if (props.portals && props.portals.length>0 && props.portals[0].portalUrl){
    propsPortalUrl = props.portals[0].portalUrl;
  }
  if (props.portals && props.portals.length>0 && props.portals[0].clientId){
    propsClientId = props.portals[0].clientId;
  }
  const [portalUrl, setPortallUrl] = useState(propsPortalUrl);
  const [clientId, setClientId] = useState(propsClientId);
  
  const handleClose = () => {
    setShowDialog(false);
    setShowLogin(false);
    props.onClose()
  };

  const handleConfirm = () => {

    if (showLogin){
      //Fetch token again in case it expired
      getCredential()
      .then((result) => {
        // Once a credential is returned from the promise, check the
        // sign in status to query the portal for items.
        //checkSignIn();
        var selectedFeature = {}
        selectedFeature.token = result.token
        
        if (props.mode == "import"){
          selectedFeature.feature_service_url = featureServiceUrl

          if (layer == "all"){
            selectedFeature.layers = layers
          } else {
            selectedFeature.layer_id = layer
            selectedFeature.fields = fields.filter(function(f){
              return f.checked == true;
            }).map(function(f){
              return f.name;
            })
  
            selectedFeature.externalfields = fields.filter(function(f){
              return f.checked == true;
            }).map(function(f){
              return f;
            })
          }
          selectedFeature.include_domains = includeDomains;
        } 

        selectedFeature.externalLinkType = externalLinkType;
        selectedFeature.direction = direction;
        selectedFeature.itemId = itemId;
        if (selectedFeature.itemId == "all"){
          let items = portalItems.filter((item,i) => {
            return item.type == "Web Map" && item.folder == folder;
          }).map(function(i){
            return i.id
          });
          selectedFeature.itemIds = items
        }
        selectedFeature.mode = props.mode;

        props.onConfirm(selectedFeature);

      });
    }
    else{
      //save portal info in config
      props.onSave();
      //portal info
      setShowLogin(true);
    }
    setShowDialog(false);
  }

  useEffect(() => {
    if (showLogin){
      checkSignInStatus(props.portals[0].portalUrl, props.portals[0].clientId)
      .then(() => {
        // If already signed in, then destroy the credentials to sign out.
        //esriId.destroyCredentials();
        //window.location.reload();
        fetchPortalItems();
        setShowDialog(true);
      })
      .catch(() => {
        // If the user is not signed in, generate a new credential.
        getCredential()
          .then((result) => {
            // Once a credential is returned from the promise, check the
            // sign in status to query the portal for items.
            //checkSignIn();
            //setToken(result.token);
            fetchPortalItems();
            setShowDialog(true);
          }).catch((err) => {
            setShowLogin(false);
            setShowDialog(true);
          });
      });
    }
  }, [showLogin]);

  function fetchPortalItems(){
    getPortalItems().then(function(results){
      setPortalItems(results);
    });
  }

  function featureServiceSelected(e){
    let url = e.target.value;
    
    setFeatureServiceUrl(url);
    //Fetch layers:
    getFeatureLayers(url).then((layers) => {
      setLayers(layers);
    });
  }

  function featureLayerSelected(e){
    let id = parseInt(e.target.value);
    setLayer(id);
    if (id != "all"){
      //populate fields:
      getFeatureLayerFields(featureServiceUrl, id).then(function(fields){
        let validFields = fields.filter(function(f){
          return "fid0,globalid,created_user,created_date,last_edited_user,last_edited_date,SHAPE__Length,objectid,qa_status,qa_description,qa_details,qa_count,qa_notes,qa_lat,qa_lon,qa_accuracy".indexOf(f.name) < 0 && f.type != "guid";
        });
        setFields(validFields);
      })
    } 
  }

  function fieldUpdate(e){

    switch(e.currentTarget.name){
      case "externalLinkType":
        setExternalLinkType(e.target.value);
        break;

      case "direction":
        setDirection(e.target.value);
          break;

      case "includeDomains":
        setIncludeDomains(e.target.checked);
        break;

      case "webmap":
        setItemId(e.target.value);
        break;

      case "form":
        setItemId(e.target.value);
        break;

      case "folder":
        setFolder(e.target.value);
        break;
      
      case "portalUrl":
        updatePortalInfo(e);
        setPortallUrl(e.target.value);
        break;

      case "clientId":
        updatePortalInfo(e);
        setClientId(e.target.value);
        break;
      
      default:

    }
 }

  function updatePortalInfo(e){

    if (props.portals.length == 0){
      var portal = {};
      portal[e.currentTarget.name] = e.target.value
      props.portals.push(portal);
    } else {
      var portal = props.portals[0];
      portal[e.currentTarget.name] = e.target.value
    }
  }

  function getFormPicklist(){
    
    let items = portalItems.filter((item,i) => {
      return item.type == "Form";
    });
    return (items.map((item,i) => (
      <option value={item.id}>{item.title}</option>
      )));
    
  }

  function getFolderPicklist(){
    
    let folders = [];
    portalItems.forEach(function(item){
      if (folders.indexOf(item.folder)<0){
        folders.push(item.folder)
      }
    })

    return (folders.map((folder,i) => (
      <option value={folder}>{folder}</option>
      )));
    
  }

  function getWebMapPicklist(){
    
    let items = portalItems.filter((item,i) => {
      return item.type == "Web Map" && item.folder == folder;
    });
    return (items.map((item,i) => (
      <option value={item.id}>{item.title}</option>
      )));
    
  }

  function getFeatureServicePicklist(){
    let items = portalItems.filter((item,i) => {
      return item.type == "Feature Service";
    });
    
    return (items.map((item,i) => (
      <option value={item.url}>{item.title}</option>
      )));
  }

  function getFeatureLayerPicklist(){
    return (layers.map((item,i) => (
      <option value={item.id}>{item.name}</option>
      )));
  }

  function getExternalLinkTypePicklist(){
    let types = [{name:'None', value:'none'}, {name:'FieldMaps', value:'fieldmaps'}, {name:'Survey123', value:'survey123'}, {name:'FeatureService', value:'featureService'}];
    return (types.map((item,i) => (
      <option value={item.value}>{item.name}</option>
      )));
  }

  function handleAttributeCheckChanged(at, e){
    at.checked = e.currentTarget.checked;
  }

  function handleSelectAll(e){
    let selectedFields = fields.map(function(f){
      f.checked = e.target.checked;
      return f;
    });
    setFields(selectedFields);
  }

  function handleNewPortal(){
     destroyCredential();
    
     setShowLogin(false);
     setShowDialog(true);
     /*
    getCredential()
    .then((result) => {
      // Once a credential is returned from the promise, check the
      // sign in status to query the portal for items.
      //checkSignIn();
      //setToken(result.token);
      fetchPortalItems();
      setShowDialog(true);
    }).catch((err) => {
      setShowLogin(false);
      setShowDialog(true);
    });
    */
  }

  function getFeatureBrowser() {
    
    return (
      <div>
      <Form.Group as={Row}className="mb-2">
          <Form.Label column sm={4}>ArcGIS Portal</Form.Label>
          <Col sm={7}>
            <Form.Label column sm={8}>{props.portals[0].portalUrl}</Form.Label>
          </Col>
          <Col sm={1}><Button variant="primary" type="button" onClick={handleNewPortal.bind(this)}>...</Button></Col>
        </Form.Group>
        {(props.mode == "import") &&
          <Form.Group as={Row}className="mb-2">
              <Form.Label column sm={4}>Feature Service</Form.Label>
              <Col sm={8}>
                <Form.Select name="featureService" onChange={featureServiceSelected.bind(this)}>
                <option value={""}></option>
                  {getFeatureServicePicklist()}
                </Form.Select>
              </Col>
            </Form.Group>
        }
        {(props.mode == "import" && layers.length > 0) &&
          <Form.Group as={Row}className="mb-2">
            <Form.Label column sm={4}>Feature Layer</Form.Label>
            <Col sm={8}>
              <Form.Select name="featureLayer" onChange={featureLayerSelected.bind(this)}>
              <option value={"all"}>All</option>
                {getFeatureLayerPicklist()}
              </Form.Select>
            </Col>
          </Form.Group>
        }
        { (props.mode == "import" && fields.length > 0) &&
          <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Fields</Form.Label>
            <Col sm={8}>
              <ListGroup style={{height:`250px`, overflow:'auto'}}>
                    {fields.map((at,i) => (
                    <ListGroup.Item key={at.name} eventKey={at.alias} action className="d-flex justify-content-between align-items-start">
                        <Container fluid>
                        <Row>
                        <Col className="d-flex justify-content-between align-items-start">
                          <span>
                            <input type="checkbox" id={at.name} label="" checked={at.checked} onChange={handleAttributeCheckChanged.bind(this, at)}/>
                            <span>&nbsp;&nbsp;{at.name}</span>
                          </span>
                        </Col>
                        </Row>
                        </Container>
                    </ListGroup.Item>
                    ))}
              </ListGroup>
              <div>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <input type="checkbox" id="selectAll" label="Select All" onChange={handleSelectAll.bind(this)}/>
                <span>&nbsp;&nbsp;Select All</span>
              </div>
              </Col>
        </Form.Group>
        }
        {(props.mode == "import" && layers.length > 0) &&
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Include Domains</Form.Label>
          <Col sm={8}>
          <input type="checkbox" id="includeDomains" name="includeDomains" label="Include Domains" onChange={fieldUpdate.bind(this)}/>
          </Col>
          </Form.Group>
        }
       
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>External Link Type</Form.Label>
          <Col sm={8}>
            <Form.Select name="externalLinkType" onChange={fieldUpdate.bind(this)}>
            <option value={""}></option>
              {getExternalLinkTypePicklist()}
            </Form.Select>
          </Col>
        </Form.Group>
        
        {(externalLinkType && externalLinkType == "survey123" || externalLinkType == "fieldmaps") &&
        <Form.Group as={Row} className="mb-2">
         <Form.Label column sm={4}>Direction</Form.Label>
         <Col sm={8}>
             <Form.Select name="direction" onChange={fieldUpdate.bind(this)}>
             <option value="startFromExternal">Start from External</option>
             <option value="startFromExternalWithDifferenceSummary">Start from External with Difference Summary</option>
             <option value="startFromExternalWithRelatedFeature">Start from External and Add Related Feature</option>
             <option value="startFromVision">Start from Vision</option>
             </Form.Select>
         </Col>
       </Form.Group>
      }
      {(externalLinkType && externalLinkType == "fieldmaps") &&
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Folders</Form.Label>
          <Col sm={8}>
            <Form.Select name="folder" onChange={fieldUpdate.bind(this)}>
            <option value={""}></option>
              {getFolderPicklist()}
            </Form.Select>
          </Col>
        </Form.Group>
        }
      {(externalLinkType && externalLinkType == "fieldmaps" && folder != "" && folder != null) &&
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>FieldMaps Web Map</Form.Label>
          <Col sm={8}>
            <Form.Select name="webmap" onChange={fieldUpdate.bind(this)}>
            {props.mode == "import" &&
              <option value={""}></option>
            }
            {props.mode == "update" &&
              <option value={"all"}>All</option>
            }
              {getWebMapPicklist()}
            </Form.Select>
          </Col>
        </Form.Group>
        }
        {(externalLinkType && externalLinkType == "survey123") &&
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Survey123 Form</Form.Label>
          <Col sm={8}>
            <Form.Select name="form" onChange={fieldUpdate.bind(this)}>
            <option value={""}></option>
              {getFormPicklist()}
            </Form.Select>
          </Col>
        </Form.Group>
        }
        
      </div>
    )
  }

  function getPortalInfo(){
    
    return (
      <div>
      <Form.Group as={Row}className="mb-2">
        <Form.Label column sm={8}>Specify a portal to connect:</Form.Label>
      </Form.Group>
      <Form.Group as={Row}className="mb-2">
        <Form.Label column sm={2}>Portal Url</Form.Label>
        <Col sm={8}><Form.Control type="input" name="portalUrl" value={portalUrl} placeholder="https://<domain>/portal or https://www.arcgis.com" onChange={fieldUpdate.bind(this)}/></Col>
      </Form.Group>
      <Form.Group as={Row}className="mb-2">
        <Form.Label column sm={2}>Client ID</Form.Label>
        <Col sm={8}><Form.Control type="input" name="clientId" value={clientId} placeholder="App Id of the registered Web Mapping Application in portal or ArcGIS Online" onChange={fieldUpdate.bind(this)}/></Col>
      </Form.Group>
      </div>
    )
  }



  function getDialogContent() {
    if (showLogin){
      return getFeatureBrowser()
    } else {
      return getPortalInfo();
    }
  }
return (
    <Modal show={showDialog}>
    <Modal.Header>
    {props.mode == "import" &&
       <Modal.Title>Import Feature Layer</Modal.Title>
    }
    {props.mode == "update" &&
       <Modal.Title>Update External Link</Modal.Title>
    }
    </Modal.Header>
    <Modal.Body>{getDialogContent()}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleConfirm}>
        OK
      </Button>
    </Modal.Footer>
  </Modal>
)
  
}

export default EsriServiceDialog;