
import React, { useEffect, useRef } from "react";
import { addFeatures, view, zoomToFeature, clearHighlight, zoomToLayerbyId} from '../../controllers/mapController';

export default function UserLocationLayer(props) {
  
  function addUserLocations(userLocations, type){
    var features = [];
    var fields = [];
    userLocations.forEach(function(feature,i){
      if (feature.userLatitude && feature.userLongitude){
        feature["ObjectID"] = i+1
        features.push({
          geometry: {
            type: "point",
            latitude: feature.userLatitude,
            longitude: feature.userLongitude
          },
          attributes: feature
        });
      }
    });
    
    var color = "orange";
    if (type == "vps"){
      color = "red"
    }

    var renderer = {
      type: "simple",  // autocasts as new SimpleRenderer()
      symbol: {
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      style: "triangle",
      color: color,
      size: "10px",  // pixels
      outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 255, 255],
      width: 1
      }
      }
    };

    var fields = [
      {name: "ObjectID",
        alias: "ObjectID",
        type: "oid"}
    ]
    Object.keys(userLocations[0]).forEach(key => {
     fields.push({
      name:key,
      alias:key,
      type:"string"
     })
    });
    
    var fieldInfos = fields.map(function(f){
      return {fieldName: f.name, label: f.alias}
    });
    const pTemplate = {
      // autocasts as new PopupTemplate()
      title: "User Location",
      content: [
        {
          // It is also possible to set the fieldInfos outside of the content
          // directly in the popupTemplate. If no fieldInfos is specifically set
          // in the content, it defaults to whatever may be set within the popupTemplate.
          type: "fields",
          fieldInfos: fieldInfos
        }
      ]
    };
  
    addFeatures(features, renderer, fields, pTemplate, "f-userLocation" + type, "User Location " + type, false, 2);
  }

  
  //Add features to map
  useEffect(_ => {
   
      if (props.userLocations != null && props.userLocations.length > 0){
        if (props.userLocations[0].type){
          var gpsUserLocations = props.userLocations.filter(function(row){
            return row.type == "gps"
          })
          if (gpsUserLocations.length > 0){
            addUserLocations(gpsUserLocations, "gps");
          }
        
          var vpsUserLocations = props.userLocations.filter(function(row){
            return row.type.indexOf("vps") >= 0
          })
          if (vpsUserLocations.length > 0){
            addUserLocations(vpsUserLocations, "vps");
          }
          
        }
        else{
          addUserLocations(props.userLocations, "gps");
        }
        
      }

  }, [props.userLocations]);
  return (
    <div />
  );
}