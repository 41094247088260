import axios from 'axios';
import { getAppConfig } from "../config";

//Get jobs for login user
export const getReportData = async (accessToken, orgId) => {
    const appconfig = getAppConfig();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      
      var reportUrl = appconfig.api + '/organizations/' + orgId + '/stats';
  
      try{
        var reportData = await axios.get(reportUrl, config);
        return reportData.data;
      }
      catch(ex){
        return [];
      }
  }