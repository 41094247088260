
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Navbar, Nav, ListGroup, Tab, Tabs, Row, Col, Toast, Button, Badge, Form} from "react-bootstrap";
import { getJobFeatures, updateJobFeatures, getJobImages, getJobVideos, getUserLocations, getGeoFeatures, getDebugLog, getSysLog, updateComment, getJobObservations} from '../../controllers/jobController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import JobFeatureLayer from './jobFeatureLayer';
import GeoFeatureLayer from './geoFeatureLayer';
import UserLocationLayer from './userLocationLayer';
import FeatureDetail from './featureDetail';
import JobDetail from './jobDetail';
import { getHashParam, setHash} from '../../controllers/hashController';
import { hasChanges, reset } from '../../controllers/mapEditController';
import ConfirmDialog from '../controls/confirmDialog';
import { approveJob} from '../../controllers/jobController';
import jwt_decode from "jwt-decode";
import ExternalMapLayer from './externalLayer';
import eventBus from '../controls/eventBus';
import JobCompare from './jobCompare';

function JobFeatureList(props) {
    const [dialogMessage, setDialogMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [jobFeatures, setJobFeatures] = useState([]);
    const [compareJobFeatures, setCompareJobFeatures] = useState([]);
    const [geoFeatures, setGeoFeatures] = useState([]);
    const [userLocations, setUserLocations] = useState([]);
    const [sysLog, setSyslog] = useState([]);
    const [debugLog, setDebuglog] = useState([]);
    const [jobPhotos, setJobPhotos] = useState([]);
    const [jobObservations, setJobObservations] = useState([]);
    const [jobVideos, setJobVideos] = useState([]);
    const [jobFeature, setJobFeature] = useState(null);
    const [highlightFeature, setHighlightFeature] = useState(null);
    const [jobPolygon, setJobPoly] = useState(null);
    const [jobPolygonCompare, setJobPolyCompare] = useState([]);
    const ref = React.createRef();
    var appv = true
  if (props.org.metadata && props.org.metadata.jobs_require_review && props.org.metadata.jobs_require_review == 'true'){
    if (props.job.reviewed == false){
      appv = false
    }
  }
  const [approved, setApproval] = useState(appv);
  const [approvalError, setApprovalError] = useState(null);

  const viewFeature = (e) => {
    let uuid =  e.detail.message;
    let features = jobFeatures.filter(function(f){
      return f.properties["uuid"] == uuid;
    })
    if (features.length > 0){
      handleViewFeature(features[0]);
    }
  }

  eventBus.on("viewFeature", viewFeature);

  const handleApprove = async() => {
    if (hasChanges == true){
      setMessage("Saving...");
      let response = await updateJobFeatures(props.accessToken, props.job.uuid, jobFeatures)
      if (response.status == 200){
        reset()
        setMessage(response.data.message);
        submitApproveJob();
      }
      else{
        setMessage(response.message);
        setTimeout(function(){
          setMessage(null);
        }, 3000)
      }
    }
    else{
      submitApproveJob()
    }
}

const submitApproveJob = async() => {
  setMessage("Approving...");
  var result = await approveJob(props.accessToken, props.job.uuid);
      if (result.status == "200"){
        setApproval(true)
        props.job.reviewed = true
        var job = {...props.job};
        props.onApproved(job)
        setMessage(result.data.message);
        setTimeout(function(){
          setMessage(null);
        }, 2000)
      }
      else{
          //Display error
          setMessage(result.data.message);
          setTimeout(function(){
            setMessage(null);
          }, 3000)
      }
}

const handleCheckChanged = (feature, e) => {
  
  var clonedFeatures = [...jobFeatures]//Clone the array before update, so that we get proper state change
  var selectedFeature = clonedFeatures.filter(function(f){
    return f.properties.uuid == feature.properties.uuid;
  })[0];
  if (selectedFeature){
    selectedFeature.checked = e.currentTarget.checked;
    setJobFeatures(clonedFeatures);
  }
};

    const handleBackToJobs = (e) => {
      if (hasChanges == false){
        reset()
        props.onBackToJobs(props.job);
      }
      else{
        //Show warning and save or discard
        setDialogMessage("Update not saved, discard changes?");
      }
      
    };

    const handleAbortConfirm = (e) => {
      setDialogMessage(null);
      reset()
      props.onBackToJobs(props.job);
    }

    const handleAbortCancel = (e) => {
      setDialogMessage(null);
    }

    const handleJobTask = async (key, e) => {
      switch(key){
        case "save":
          if (hasChanges || props.mode == 'd'){
            setMessage("Saving...");

            let response = await updateJobFeatures(props.accessToken, props.job.uuid, jobFeatures)
            if (response.status == 200){
              reset()
              setMessage(response.data.message);
              setTimeout(function(){
                setMessage(null);
              }, 2000)
            }
            else{
              setMessage(response.message);
              setTimeout(function(){
                setMessage(null);
              }, 3000)
            }
            
          }
          break;

        case "cancel":
          if (hasChanges || props.mode == 'd'){
            reset()
            //repopulate map from job
            populateJobFeatures();
          }

        case "approve":
          break;

        default:
      }
    };


    const handleBackToFeatures = (e) => {
      setJobFeature(null);
      setHash(null,null,3);
    };
    
    const handleHighlighted = (feature, e) => {
      setHighlightFeature(feature);
      eventBus.dispatch("highlightFeature", { message: feature.properties["uuid"] });
    };

    const handleFeatureSelect = (feature, e) => {
      setHighlightFeature(feature);
      setJobFeature(feature);
      setHash(feature.properties, "ssp_id", 3);
    };

    const handleViewFeature = (feature, e) => {
      setHighlightFeature(feature);
      setJobFeature(feature);
      setHash(feature.properties, "ssp_id", 3);
    };

    const addImagesToFeature = (jobFeatures, s3images) => {
      var jobPhotos = [];
      
      jobFeatures.features.forEach(function(f){ //loop through each feature
        if (f.properties["ssp-id"]){
          f.properties["ssp_id"] = f.properties["ssp-id"]
          delete f.properties["ssp-id"];
        }

        if ((!f.properties["ssp_id"]) && f.properties["ssp_description"]){

          f.properties["ssp_id"] = f.properties["ssp_description"]
          delete f.properties["ssp_description"];
        }
        //var featureImageNames = f.properties.images.split(","); //each feature can have multiple images, find the names
        f.images = []; //initialize a new array to store image details
        s3images.forEach(function(s3image){ //loop through each s3 image to find the images for the feature
          if (!s3image.matched){
            if (f.properties.image_names){
              var featureImageNames = f.properties.image_names.split(","); //one feature can have multiple images...
              featureImageNames.forEach(function(fImgName){
                if (s3image.image_name.indexOf(fImgName.trim())>=0){//first try to match by image name
                  f.images.push(s3image);
                  s3image.matched = true;
                }
              })
            }
          }
        })
      });
      s3images.forEach(function(s3image){
        if (!s3image.matched){
          //if no match add to job...even if it is for debugging purpose to see why they don't match
          jobPhotos.push(s3image);
        }
      })
      setJobPhotos(jobPhotos);
    };

    const addObservationsToFeature = (jobFeatures, s3Observations) => {
      var jobObservations = [];
      if (s3Observations && s3Observations.length > 0){
        jobFeatures.features.forEach(function(f){ //loop through each feature
          f.observations = []; //initialize a new array to store image details
          s3Observations.forEach(function(s3observation){ //loop through each s3 image to find the images for the feature
            if (!s3observation.matched){
              if (f.properties.observation_names){
                var featureObservationNames = f.properties.observation_names.split(","); //one feature can have multiple images...
                featureObservationNames.forEach(function(fObservationName){
                  if (s3observation.image_name.indexOf(fObservationName.trim())>=0){//first try to match by image name
                    f.observations.push(s3observation);
                    s3observation.matched = true;
                  }
                })
              }
            }
          })
        });
        s3Observations.forEach(function(s3Observation){
          if (!s3Observation.matched){
            //if no match add to job...even if it is for debugging purpose to see why they don't match
            jobObservations.push(s3Observation);
          }
        })
        setJobObservations(jobObservations);

      }
    };

    const addVideosToFeature = (s3Videos) => {
      if (s3Videos && s3Videos.length > 0 && s3Videos[0].video_url != null){

        setJobVideos(s3Videos);
      }
    };

    useEffect(() => {
      populateJobFeatures();
    },[props.job.uuid]);

    function populateJobFeatures(){
      var jobs = [props.job]
      if (props.selectedJobs.length > 0){
        jobs = jobs.concat(props.selectedJobs.filter(function(j){
          return j.uuid != props.job.uuid;
        }))
      }
      var allJobFeatures = [];
      jobPolygonCompare.length = 0;
      jobs.forEach(function(j){
        getJobFeatures(props.accessToken, j.uuid).then(function(jobFeatures){
          getJobImages(props.accessToken, j.uuid).then(function(jobImages){
            getJobObservations(props.accessToken, j.uuid).then(function(jobObservations){
              getJobVideos(props.accessToken, j.uuid).then(function(jobVideos){
                if (jobFeatures && jobFeatures.features && jobFeatures.features.length >0){
                  addImagesToFeature(jobFeatures, jobImages);
                  //Associate images to job feature
                  var jobPolygon = jobFeatures.features[jobFeatures.features.length - 1];
                  if (props.job.uuid == j.uuid){
                    //Get job polygon to calculate job extent
                    //var jobPolygon = JSON.parse(props.job.bbox_geometry);
                    setJobPoly(jobPolygon);
                  } else {
                    jobPolygonCompare.push(jobPolygon);
                    jobFeatures.features.forEach(function(f){
                      f.properties["job_name"] = j.name;
                    })
                  }

                  addObservationsToFeature(jobFeatures, jobObservations);
                  addVideosToFeature(jobVideos);

                  function sort_by_key(array, key)
                  {
                    //Sort desc
                    return array.sort(function(a, b)
                    {
                      var jobNameA = a.properties["job_name"] ?? "0"
                      var jobNameB = b.properties["job_name"] ?? "0"
                      var x = jobNameA + a.properties[key]; var y = jobNameB + b.properties[key];
                      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });
                  }
                  //jobFeatures.features = sort_by_key(jobFeatures.features,"ssp_id")
                  
                  allJobFeatures = allJobFeatures.concat(jobFeatures.features);
                  allJobFeatures = sort_by_key(allJobFeatures,"ssp_id")
                  setJobFeatures(allJobFeatures);
                
                  //Goto the job feature if it is in the url
                  setTimeout(function(){
                    //if url has feature set, switch to the feature
                    var featureid = getHashParam(window.location.hash, 3);
                    if (featureid){
                      //find the feature in features to switch
                      var features = allJobFeatures.filter(function(f){
                        return f.properties["ssp_id"] == featureid;
                      });
                      
                      if (features.length > 0){
                        //Switch to feature
                        if (jobFeature){
                          var currentFeature = features.filter(function(f){
                            f.properties.uuid = jobFeature.properties.uuid
                          });
                          if (currentFeature.length > 0){
                            setHighlightFeature(currentFeature[0]);
                            setJobFeature(currentFeature[0]);
                            setHash(currentFeature[0].properties,"ssp_id",3); 
                          } else {
                            setHighlightFeature(features[0]);
                            setJobFeature(features[0]);
                            setHash(features[0].properties,"ssp_id",3); 
                          }
                        } else {
                          setHighlightFeature(features[0]);
                          setJobFeature(features[0]);
                          setHash(features[0].properties,"ssp_id",3); 
                        }
                        
                      }
                    }
                  },500);
                }
                else{
                  setJobFeatures([]);
                }
              });
            });
          }); 
        });
      });
      

      //Do these async so that page loads faster
      setTimeout(function(){
        getGeoFeatures(props.accessToken, props.job.uuid).then(function(geoFeatures){
          setGeoFeatures(geoFeatures);
        });
        getUserLocations(props.accessToken, props.job.uuid).then(function(result){
          setUserLocations(result);
        });
        getSysLog(props.accessToken, props.job.uuid).then(function(sysLog){
          setSyslog(sysLog);
        });
        getDebugLog(props.accessToken, props.job.uuid).then(function(debugLog){
          setDebuglog(debugLog);
        });
      },1000)
    }

    function getID(feature){
      //Find feature config
      var featureConfigs = props.config.jobTypes.filter(function(jt){
        return jt.jobName == props.job.job_type;
      });

      if (featureConfigs.length > 0){
        var featureConfig = featureConfigs[0].featureDefinitions.filter(function(fc){
          return fc.featureClass == feature.properties.FeatureClass || fc.featureClassDisplay == feature.properties.FeatureClass
        })[0];

        if (featureConfig != null){
          var featureClassDisplay = featureConfig.featureClass
          if (featureConfig.featureClassDisplay != null && featureConfig.featureClassDisplay.length >0){
            featureClassDisplay = featureConfig.featureClassDisplay
          }
          if (feature.properties["ssp_description"] != featureClassDisplay){
            return feature.properties["ssp_description"]
          }
          else{
            return feature.properties["ssp_id"].replace(featureConfig.featureClass, featureClassDisplay);
          }
        }
        else{
          return feature.properties["ssp_id"];
        }
      } else {
        return feature.properties["ssp_id"];
      }
    } 

    function getMapLayers(){
      if (props.config){
        var jtConfigs = props.config.jobTypes.filter(function(jt){
          return jt.jobName == props.job.job_type;
        });
  
        if (jtConfigs.length > 0){
          return jtConfigs[0].mapLayers;
        }
        else{
          return []
        }
      }
      else{
        return []
      }
    }

    useEffect(_ => {
      //Scroll highlighted item into view (when selected from the map)
      if (ref && ref.current){
        setTimeout(function(){
          if (ref.current.getBoundingClientRect().bottom > window.innerHeight) {
            ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
        }
    
        if (ref.current.getBoundingClientRect().top < 0) {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } 
          
        },200)
        
      }
    }, [jobFeature, highlightFeature]);

    //Currently selected item ref
    function getActiveRef(feature){
      if (highlightFeature && feature && feature.properties.ssp_id == highlightFeature.properties.ssp_id){
        return ref;
      }
      else{
        return null;
      }
    }

    //Currently selected item
    function getActiveStatus(feature){
      if (highlightFeature && feature && feature.properties.uuid == highlightFeature.properties.uuid){
        return true;
      }
      else{
        return false;
      }
    }

    function getFeaturesPane(){
      if (jobFeature){
        var selectedFeatures = jobFeatures.filter(function(j){
          return j.checked;
        })
        return (<FeatureDetail height={props.height} jobFeature={jobFeature} selectedFeatures={selectedFeatures} job={props.job} config={props.config} canEdit={props.canEdit} onBackToFeatures={handleBackToFeatures}></FeatureDetail>)
      }
      else{
        return(<ListGroup style={{height:`${props.height - 209}px`, overflow:'auto'}}>
          {jobFeatures.map((feature) => (
          feature.properties["ssp_id"] &&
          <ListGroup.Item key={feature.uuid} variant={props.theme} eventKey={getID(feature)} active={getActiveStatus(feature)} ref={getActiveRef(feature)} action onClick={handleHighlighted.bind(this,feature)} className="d-flex justify-content-between align-items-start">
            <Container fluid>
              <Row>
                <Col sm={4}>
                  <Form.Check type="checkbox" id={feature.uuid} label={getID(feature)} checked={feature.checked} onChange={handleCheckChanged.bind(this, feature)}/>
                </Col>
                <Col sm={7}>
                {feature.properties["job_name"] &&
                  feature.properties["job_name"]
                }
                </Col>
                <Col sm={1}><FontAwesomeIcon icon={faChevronRight} onClick={handleFeatureSelect.bind(this,feature)}/></Col>
              </Row>
            </Container>
          </ListGroup.Item>
          
          ))}
      </ListGroup>)
      }
        
    }

    function handleCommentUpdate(comment){
      props.job.attributes["comments"] = comment;
      props.job.comments = comment;
      updateComment(props.accessToken, props.job.uuid, comment);
    }

    function getMessagePlaceholder(){
      return (
        <div style={{position:"absolute", top:2, left:0, zIndex:999}}>
          <Toast show={message != null} >
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </div>
      )
    }

    function getApprovalButton(){
      var decoded = jwt_decode(props.accessToken);
      var roles = decoded["https://sspvision.net/roles"];
      var isAdmin = (roles.indexOf("vision-admin") >= 0);

      if (isAdmin && props.canEdit && !approved) {
        return (
         <Button variant="outline-success" type="button" onClick={handleApprove.bind(this)}>Approve Job</Button>    
        )
      }
      else if (props.org.metadata && props.org.metadata.jobs_require_review && props.org.metadata.jobs_require_review == 'true' && approved){
        return (<Badge pill bg="success">Job Approved</Badge>)
      }
    }
    return (
      <div>
        <Navbar bg="light" variant="light" expand="sm">
              <Nav onSelect={handleBackToJobs} defaultActiveKey="backToJobs" className="me-auto">
                <Nav.Link eventKey="backToJobs"><FontAwesomeIcon icon={faChevronLeft}/>&nbsp;&nbsp;{props.job.name}</Nav.Link>
              </Nav>
              <Nav className="me-auto">
              {getApprovalButton()}
              </Nav>
              {(props.canEdit && !approved) &&
                <Nav onSelect={handleJobTask}>
                  <Nav.Link eventKey="cancel"><FontAwesomeIcon icon={faTimesCircle} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
              }
              {(props.canEdit && !approved) &&
                <Nav onSelect={handleJobTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
              }
        </Navbar>
        <Tabs defaultActiveKey="features" id="tabJobDetail" className="mb-3" fill>
          <Tab eventKey="features" title="Job Features">
             {getFeaturesPane()}
          </Tab>
          <Tab eventKey="detail" title="Job Details">
            <JobDetail height={props.height} accessToken={props.accessToken} org={props.org} job={props.job} fields={props.fields} photos={jobPhotos} observations={jobObservations} videos={jobVideos} sysLog={sysLog} debugLog={debugLog} geoFeatures={geoFeatures} onCommentUpdate={handleCommentUpdate}></JobDetail>
          </Tab>
          {(props.selectedJobs.length > 1)&&
            <Tab eventKey="compare" title="Job Compare">
            <JobCompare height={props.height} accessToken={props.accessToken} org={props.org} job={props.job} selectedJobs={props.selectedJobs}></JobCompare>
          </Tab>
          }
        </Tabs>
        <JobFeatureLayer job={props.job} jobFeatures={jobFeatures} jobPolygon={jobPolygon} jobPolygonCompare={jobPolygonCompare} fields={props.fields} jobFeature={highlightFeature} onViewFeature={handleViewFeature}></JobFeatureLayer>
        <GeoFeatureLayer job={props.job} geoFeatures={geoFeatures} ></GeoFeatureLayer>
        <ExternalMapLayer mapLayers={getMapLayers()} level="jobType"></ExternalMapLayer>
        <UserLocationLayer job={props.job} userLocations={userLocations} ></UserLocationLayer>
        <ConfirmDialog title="Feature changes" message={dialogMessage} onConfirm={handleAbortConfirm} onClose={handleAbortCancel}></ConfirmDialog>
        {getMessagePlaceholder()}
    </div>
    );
}

export default JobFeatureList;
