import React, { useEffect, useState } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Modal, Button, Form, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faFloppyDisk, faPlus, faUndo, faDownload, faUpload} from '@fortawesome/free-solid-svg-icons';
import { createM2MClient, deleteM2MClient, rotateM2MClientSecret, getFTPSettings, getEsriSettings, updateConnectionSettings, deleteConnectionSettings, createConnectionSettings, getOrg, getM2MCredential } from '../../controllers/technicalController';
import { getHashParam, setHash} from '../../controllers/hashController';

function Technical(props) {
    const [dialogMessage, setDialogMessage] = useState(null);
    const [setting, setSetting] = useState("m2mClient");
    const [ftpServer, setFTPServer] = useState({type:"sftp",server:"",port:"22",username:"",password:"",path:""});
    const [esriServer, setEsriServer] = useState({type:"esri",username:"",password:""});
    const [m2mCredential, setM2MCredential] = useState(null);
    const [message, setMessage] = useState(null);
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        if (props.accessToken){
       
            var type = getHashParam(window.location.hash, 1);
            if (type && type.length>0){
                setSetting(type);
            }

            //Get M2MClient credentials
            getOrg(props.accessToken, props.org.id).then(function(org){
                //Get client secret
                if (org.metadata.client_id){
                    getM2MCredential(props.accessToken, org.metadata.client_id).then(function(cred){
                        setM2MCredential(cred);
                    })
                }
            });

            getFTPSettings(props.accessToken, props.superAdminOrgId).then(function(data){
                if (data != null){
                    //Populate page
                    setFTPServer(data);
                    setUpdate(!update)
                }
            }); 

            getEsriSettings(props.accessToken, props.superAdminOrgId).then(function(data){
                  if (data != null){
                      //Populate page
                      setEsriServer(data);
                      setUpdate(!update)
                  }
            }); 
    
        }
           
       },[props.accessToken, props.superAdminOrgId]);

    const handleHighlight = (key, e) => {
        setSetting(key);
        setHash({type:key}, "type", 1)
      };

    function fieldUpdateFTP(e){
        //Find current setting type in the array to update
        ftpServer[e.currentTarget.name] = e.currentTarget.value;
        setFTPServer(ftpServer);
        setUpdate(!update);
    }

    function fieldUpdateEsri(e){
      //Find current setting type in the array to update
      esriServer[e.currentTarget.name] = e.currentTarget.value;
      setEsriServer(esriServer);
      setUpdate(!update);
  }

     const handleSaveFTP = async (key, e) => {
        var result;
        if (ftpServer.uuid != null){
            result = await updateConnectionSettings(props.accessToken, ftpServer);
        }
        else{
            result = await createConnectionSettings(props.accessToken,props.superAdminOrgId, ftpServer);
        }

        if (result.status == 200){
            if (ftpServer.uuid == null && result.data.uuid){
                ftpServer.uuid = result.data.uuid;
                setFTPServer(ftpServer);
            }
            
            setMessage("FTP Server settings saved!");
            setTimeout(function(){
              setMessage(null);
            }, 3000)
          }
          else{
            setMessage("Failed to save: " + result.response.data.message);
            setTimeout(function(){
              setMessage(null);
            }, 3000)
          }
     }

     const handleDeleteFTPServer = async (key, e) => {
        var result;
        if (ftpServer.uuid != null){
            result = await deleteConnectionSettings(props.accessToken, ftpServer);
        }
        else{
            result = {status:200}
        }

        if (result.status == 200){
            setFTPServer({type:"sftp",server:"",port:"22",username:"",password:"",path:""});
            setMessage("FTP Server settings deleted!");
            setTimeout(function(){
              setMessage(null);
            }, 3000)
          }
          else{
            setMessage("Failed to delete: " + result.response.data.message);
            setTimeout(function(){
              setMessage(null);
            }, 3000)
          }
     }

     const handleSaveEsri = async (key, e) => {
      var result;
      if (esriServer.uuid != null){
          result = await updateConnectionSettings(props.accessToken, esriServer);
      }
      else{
          result = await createConnectionSettings(props.accessToken,props.superAdminOrgId, esriServer);
      }

      if (result.status == 200){
          if (esriServer.uuid == null && result.data.uuid){
              esriServer.uuid = result.data.uuid;
              setEsriServer(esriServer);
          }
          
          setMessage("Esri Server settings saved!");
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
        else{
          setMessage("Failed to save: " + result.response.data.message);
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
   }

   const handleDeleteEsriServer = async (key, e) => {
      var result;
      if (esriServer.uuid != null){
          result = await deleteConnectionSettings(props.accessToken, esriServer);
      }
      else{
          result = {status:200}
      }

      if (result.status == 200){
          setEsriServer({type:"esri",username:"",password:""});
          setMessage("Esri Server settings deleted!");
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
        else{
          setMessage("Failed to delete: " + result.response.data.message);
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
   }
     const handleDeleteM2MClient = async (key, e) => {
        var result;
        if (m2mCredential != null){
            result = await deleteM2MClient(props.accessToken, m2mCredential.client_id);
        }

        if (result.status == 200){
            setM2MCredential(null);
            setMessage("M2M Client deleted!");
            setTimeout(function(){
              setMessage(null);
            }, 3000)
          }
          else{
            setMessage("Failed to delete: " + result.response.data.message);
            setTimeout(function(){
              setMessage(null);
            }, 3000)
          }
     }
     
     const handleM2MClientRotateSecret = async (key, e) => {
        rotateM2MClientSecret(props.accessToken, m2mCredential.client_id).then(function(cred){
            setM2MCredential(cred);
        });
     }
     
     const handleM2MClientCreate = async (key, e) => {
       
        createM2MClient(props.accessToken, props.superAdminOrgId, props.org.id).then(function(cred){
            setM2MCredential(cred);
        });
     }

     function getM2MClientForm(){
        if (m2mCredential && m2mCredential.client_id != null && m2mCredential.client_secret != null){
            return (
            <Form className='form'>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>Client ID</Form.Label>
                    <Col sm={8}><Form.Control type="input" name="client_id" value={m2mCredential.client_id}/></Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>Client Secret</Form.Label>
                    <Col sm={8}><Form.Control type="input" name="client_secret" value={m2mCredential.client_secret}/></Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>&nbsp;</Form.Label>
                    <Col sm={8}><Button variant="primary" onClick={handleM2MClientRotateSecret}>
                        <FontAwesomeIcon icon={faUndo} size="lg"/>&nbsp;Rotate Secret
                    </Button></Col>
                </Form.Group>
            </Form>
            )
        }
        else{
            return (
                <Form className='form'>
                    <Button variant="primary" onClick={handleM2MClientCreate}>
                        <FontAwesomeIcon icon={faPlus} size="lg"/>&nbsp;Create
                    </Button>
                </Form>
            )
        }
     }
    
     function getFtpServerForm(){
        return (
        <Form className='form' style={{height:`${props.height - 130}px`}}>
          <Form.Group as={Row}className="mb-3">
            <Form.Label column sm={4}>Type</Form.Label>
            <Col sm={8}>
              <Form.Select name="type" value={ftpServer.type} onChange={fieldUpdateFTP.bind(this)}>
              <option value={"sftp"}>SFTP</option>
              <option value={"ftps"}>FTPS</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Server</Form.Label>
            <Col sm={8}><Form.Control type="input" name="server" value={ftpServer.server} onChange={fieldUpdateFTP.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Port</Form.Label>
            <Col sm={8}><Form.Control type="input" name="port" value={ftpServer.port} onChange={fieldUpdateFTP.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Username</Form.Label>
            <Col sm={8}><Form.Control type="input" name="username" value={ftpServer.username} onChange={fieldUpdateFTP.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Password</Form.Label>
            <Col sm={8}><Form.Control type="input" name="password" value={ftpServer.password} onChange={fieldUpdateFTP.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Target Directory</Form.Label>
            <Col sm={8}><Form.Control type="input" name="path" value={ftpServer.path} placeholder="e.g. /home" onChange={fieldUpdateFTP.bind(this)}/></Col>
          </Form.Group>
        </Form>
        )
     }

     function getEsriServerForm(){
      return (
      <Form className='form' style={{height:`${props.height - 130}px`}}>
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Portal</Form.Label>
            <Col sm={8}><Form.Control type="input" name="server" value={esriServer.server} onChange={fieldUpdateEsri.bind(this)}/></Col>
          </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Username</Form.Label>
          <Col sm={8}><Form.Control type="input" name="username" value={esriServer.username} onChange={fieldUpdateEsri.bind(this)}/></Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Password</Form.Label>
          <Col sm={8}><Form.Control type="password" name="password" value={esriServer.password} onChange={fieldUpdateEsri.bind(this)}/></Col>
        </Form.Group>
      </Form>
      )
   }
     function getRightPane(){
        if (setting == "m2mClient"){
          return (
            <Container fluid>
            <Navbar bg="light" variant="light">
              <Navbar.Brand>
                  </Navbar.Brand>
                    <Nav className="me-auto">
                    <Nav.Link>&nbsp;</Nav.Link>
                    </Nav>
                    <Nav onSelect={handleDeleteM2MClient}>
                      <Nav.Link eventKey="m2mClient"><FontAwesomeIcon icon={faTrash} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                    </Nav>
            </Navbar>
              {getM2MClientForm()}
            </Container>
          )
        }
        else if (setting == "ftpServer"){
          //Only show the save button when no items
          return (
            <Container fluid>
            <Navbar bg="light" variant="light">
              <Navbar.Brand>
                  </Navbar.Brand>
                    <Nav className="me-auto">
                    </Nav>
                    <Nav onSelect={handleDeleteFTPServer}>
                      <Nav.Link eventKey="ftpServer"><FontAwesomeIcon icon={faTrash} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                    </Nav>
                    <Nav onSelect={handleSaveFTP}>
                      <Nav.Link eventKey="ftpServer"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                    </Nav>
            </Navbar>
            {getFtpServerForm()}
            </Container>
          )
        }
        else if (setting == "esriServer"){
          //Only show the save button when no items
          return (
            <Container fluid>
            <Navbar bg="light" variant="light">
              <Navbar.Brand>
                  </Navbar.Brand>
                    <Nav className="me-auto">
                    </Nav>
                    <Nav onSelect={handleDeleteEsriServer}>
                      <Nav.Link eventKey="esriServer"><FontAwesomeIcon icon={faTrash} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                    </Nav>
                    <Nav onSelect={handleSaveEsri}>
                      <Nav.Link eventKey="esriServer"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                    </Nav>
            </Navbar>
            {getEsriServerForm()}
            </Container>
          )
        }
       }

     function getLeftPane(){
        var isHorizontal = true;
        var height = props.height - 150;
        if (window.innerHeight > window.innerWidth){
          isHorizontal = false;
          height = (props.height/2) - 200;
        }
          return (
            <div>
            <Row>
              <Col sm={4}>
                <Navbar bg="light" variant="light">
                <Navbar.Brand>
                &nbsp;&nbsp;Technical Admin Settings
                </Navbar.Brand>
                  <Nav className="me-auto">
                  </Nav>
              </Navbar>
              <ListGroup defaultActiveKey={"m2mClient"} style={{height:`${height}px`, overflow:'auto'}}>
                <ListGroup.Item key={"m2mClient"} eventKey={"m2mClient"} active={setting== "m2mClient"} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,"m2mClient")}>
                  <Container fluid>
                    <Row>
                      <Col><Form.Label>M2M Client Credential</Form.Label></Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
                <ListGroup.Item key={"ftpServer"} eventKey={"ftpServer"} active={setting== "ftpServer"} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,"ftpServer")}>
                  <Container fluid>
                    <Row>
                      <Col><Form.Label>FTP Server Credential</Form.Label></Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
                <ListGroup.Item key={"esriServer"} eventKey={"esriServer"} active={setting== "esriServer"} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,"esriServer")}>
                  <Container fluid>
                    <Row>
                      <Col><Form.Label>Esri Server Credential</Form.Label></Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              </ListGroup>
              </Col>
              {isHorizontal && 
                <Col>{getRightPane()}</Col>
              }
          </Row>
          {isHorizontal == false && 
            <Row>
              <Col>{getRightPane()}</Col>
            </Row>}
            </div>
          )
       }
    
       function getMessagePlaceholder(){
        return (
          <div style={{position:"absolute", top:2, left:0}}>
            <Toast show={message != null} >
              <Toast.Body>{message}</Toast.Body>
            </Toast>
          </div>
        )
      }

       return (
        <div style={{position:"relative"}}>
            <Row>
              <Col>{getLeftPane()}</Col>
            </Row>
            {getMessagePlaceholder()}
        </div>
      )  
    
   
}

export default Technical;