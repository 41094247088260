
import React, { useEffect, useState, useContext } from 'react';
import {Modal, Button, Toast, Row, Col, Container, CloseButton} from "react-bootstrap";

function CopyBubble(props) {

  const handleClose = () => {
    //remove from clipboard
    props.onClose();
  };

  const handlePaste = () => {
    props.onPaste(props.clipboard);
  }

var disabled = true;
if (props.clipboard.length>0 && props.clipboard[0] != null && props.clipboard[0].type == props.type){
  disabled = false;
}
return (
  <div style={{position:"absolute", top:0, left:200, zIndex:99}}>
    <Toast className="clipboard" show={!disabled && props.clipboard.length>0 && props.clipboard[0].message != null} onClose={handleClose}>
      <Toast.Body>
        <Container fluid>
        <Row>
          <Col>{props.clipboard.length>0 && props.clipboard[0] && props.clipboard[0].message}</Col>
          <Col sm={3}><Button variant="outline-primary" size="sm" onClick={handlePaste} disabled={disabled}>Paste</Button></Col>
          <Col xs={1}><CloseButton onClick={handleClose}/></Col>
          </Row>
        </Container>
      </Toast.Body>
    </Toast>
  </div>
)
  
}

export default CopyBubble;
