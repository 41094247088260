import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Nav, NavDropdown, Navbar} from "react-bootstrap";
import { getReportData } from '../../controllers/reportController';

function Report(props) {

    const [reportData, setReportData] = useState(null);
    const [filter, setFilter] = useState('lastDay');
   
    useEffect(() => {
        getReportData(props.accessToken, props.org.id).then(function(data){
            setReportData(data);
        });
        },[])

    function getFeaturesLastDayByClass(){
        if (reportData){
            return(
                <div>
                <div><b>Features Last Day By User</b></div>
                {
                reportData.features_last_day_by_class.map((feature) => (
                    <Row>
                        <Col>
                            {feature.feature_class}
                        </Col>
                        <Col>
                            {feature.number_of_features}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getFeaturesLastWeekByClass(){
        if (reportData){
            return(
                <div>
                <div><b>Features Last Week By User</b></div>
                {
                reportData.features_last_week_by_class.map((feature) => (
                    <Row>
                        <Col>
                            {feature.feature_class}
                        </Col>
                        <Col>
                            {feature.number_of_features}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getFeaturesLastMonthByClass(){
        if (reportData){
            return(
                <div>
                <div><b>Features Last Month By User</b></div>
                {
                reportData.features_last_month_by_class.map((feature) => (
                    <Row>
                        <Col>
                            {feature.feature_class}
                        </Col>
                        <Col>
                            {feature.number_of_features}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getFeaturesLastYearByClass(){
        if (reportData){
            return(
                <div>
                <div><b>Features Last Year By User</b></div>
                {
                reportData.features_last_year_by_class.map((feature) => (
                    <Row>
                        <Col>
                            {feature.feature_class}
                        </Col>
                        <Col>
                            {feature.number_of_features}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastDayByJobType(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Day By JobType</b></div>
                {
                reportData.jobs_last_day_by_job_type.map((feature) => (
                    <Row>
                        <Col>
                            {feature.job_type}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                 ))}
                 <div>&nbsp;</div>
                 </div>
            )
        }
    }

    function getJobsLastWeekByJobType(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Week By JobType</b></div>
                {
                reportData.jobs_last_week_by_job_type.map((feature) => (
                    <Row>
                        <Col>
                            {feature.job_type}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastMonthByJobType(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Month By JobType</b></div>
                {
                reportData.jobs_last_month_by_job_type.map((feature) => (
                    <Row>
                        <Col>
                            {feature.job_type}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastYearByJobType(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Year By JobType</b></div>
                {
                reportData.jobs_last_year_by_job_type.map((feature) => (
                    <Row>
                        <Col>
                            {feature.job_type}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastDayByUser(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Day By User</b></div>
                {
                reportData.jobs_last_day_by_user.map((feature) => (
                    <Row>
                        <Col>
                            {feature.user_name}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastWeekByUser(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Week By User</b></div>
                {reportData.jobs_last_week_by_user.map((feature) => (
                    <Row>
                        <Col>
                            {feature.user_name}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastMonthByUser(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Month By User</b></div>
                {reportData.jobs_last_month_by_user.map((feature) => (
                    <Row>
                        <Col>
                            {feature.user_name}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }

    function getJobsLastYearByUser(){
        if (reportData){
            return(
                <div>
                <div><b>Jobs Last Year By User</b></div>
                {reportData.jobs_last_year_by_user.map((feature) => (
                    <Row>
                        <Col>
                            {feature.user_name}
                        </Col>
                        <Col>
                            {feature.number_of_jobs}
                        </Col>
                    </Row>
                ))}
                <div>&nbsp;</div>
                </div>
            )
        }
    }
       
    const handleSelectFilter = (key, e) => {
        if (filter != key){
          setFilter(key);
        }
      };

      const getFilterDisplay = (key) => {
       switch (key) {
        case "lastDay":
            return "Last Day"
            break;
       
        case "lastWeek":
            return "Last Week"
            break;

        case "lastMonth":
            return "Last Month"
            break;

        case "lastYear":
            return "Last Year"
            break;

        default:
            break;
       }
      };

    return (
        <Container>
            <Nav onSelect={handleSelectFilter}  defaultActiveKey={module}>
                <NavDropdown title={getFilterDisplay(filter)} id="basic-nav-dropdown">
                    <NavDropdown.Item eventKey="lastDay">{getFilterDisplay("lastDay")}</NavDropdown.Item>
                    <NavDropdown.Item eventKey="lastWeek">{getFilterDisplay("lastWeek")}</NavDropdown.Item>
                    <NavDropdown.Item eventKey="lastMonth">{getFilterDisplay("lastMonth")}</NavDropdown.Item>
                    <NavDropdown.Item eventKey="lastYear">{getFilterDisplay("lastYear")}</NavDropdown.Item>
                </NavDropdown>
            </Nav>

            {(filter == "lastDay") &&
                getFeaturesLastDayByClass()
            }
            
            {(filter == "lastWeek") &&
                getFeaturesLastWeekByClass()
            }

            {(filter == "lastMonth") && 
                getFeaturesLastMonthByClass()
            }

            {(filter == "lastYear") && 
                getFeaturesLastYearByClass()
            }

            {(filter == "lastDay") &&
                getJobsLastDayByJobType()
            }

            {(filter == "lastWeek") && 
                getJobsLastWeekByJobType()
            }

            {(filter == "lastMonth") && 
                getJobsLastMonthByJobType()
            }

            {(filter == "lastYear") && 
                getJobsLastYearByJobType()
            }

            {(filter == "lastDay") &&
                getJobsLastDayByUser()
            }

            {(filter == "lastWeek") &&
                getJobsLastWeekByUser()
            }

            {(filter == "lastMonth") &&
                getJobsLastMonthByUser()
            }

            {(filter == "lastYear") &&
                getJobsLastYearByUser()
            }
        </Container>
    )
}

export default Report;