const eventBus = {
    on(event, callback) {
      window.addEventListener(event, callback, true);
    },
    dispatch(event, data) {
        window.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        window.removeEventListener(event, callback, true);
    },
  };
  
  export default eventBus;