
import React, { useEffect, useState } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Modal, Button, Form, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faFloppyDisk, faUndo, faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { getSettings, updateSettings, updateAllSettings, createSettings, compareSettings, compareAllSettings } from '../../controllers/settingController';
import ConfirmDialog from '../controls/confirmDialog';

function SettingEditor(props) {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [overwriteMessage, setOverwriteMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [settings, setSettings] = useState(null);
  const [settingsId, setSettingsId] = useState(null);
  const [settingTypeHighlight, setSettingTypeHighlight] = useState(null);
  const [settingTypeIndex, setSettingTypeIndex] = useState(-1);
  const [settingTypes, setSettingTypes] = useState([]); 
  const [differences, setDifferences] = useState(null);

  var st =  [
    {
      type: "Standard",
      settings: [
        {
          name: "loggingLevel",
          value: "debug",
          editByUser: "true",
        },
        {
          name: "handedness",
          value: "right",
          editByUser: "true",
        },
        {
          name: "measurementUnit",
          value: "feet",
          editByUser: "true",
        },
        {
          name: "videoRecording",
          value: "false",
          editByUser: "true",
        },
        {
          name: "arTutorial",
          value: "false",
          editByUser: "true",
        },
        {
          name: "saveVideosToPhotos",
          value: "false",
          editByUser: "true",
        },
        {
          name: "vpsEnabled",
          value: "true",
          editByUser: "true",
        },
        {
          name: "vpsAlert",
          value: "true",
          editByUser: "true",
        },
        {
          name: "vpsAlertLow",
          value: "2.0",
          editByUser: "true",
        },
        {
          name: "vpsAlertHigh",
          value: "7.0",
          editByUser: "true",
        },
        {
          name: "locationAlgorithm",
          value: "Blended",
          editByUser: "true",
        },
        {
          name: "imageMeasurement",
          value: "true",
          editByUser: "true",
        },
      ],
    },
    {
      type: "Advanced",
      settings: [
        {
          name: "tutorialLink",
          value: "https://player.vimeo.com/external/802156475.m3u8?s=b5a011055b64a2defae066a46eefc9525f792a22",
          editByUser: "true",
        },
        {
          name: "documentLink",
          value: "",
          editByUser: "true",
        },
        {
          name: "showPreviousJob",
          value: "false",
          editByUser: "false",
        },
        {
          name: "showPreviousJobOnMatch",
          value: "false",
          editByUser: "false",
        },
        {
          name: "showPreviousJobOnCloud",
          value: "false",
          editByUser: "false",
        },
        {
          name: "showPreviousJobOnCloudSingle",
          value: "false",
          editByUser: "false",
        },
        {
          name: "showSurfaceDistance",
          value: "false",
          editByUser: "true",
        },
        {
          name: "geoTracking",
          value: "false",
          editByUser: "true",
        },
        {
          name: "showDisplayStateInHeader",
          value: "false",
          editByUser: "true",
        },
        {
          name: "showRecognitionMonitor",
          value: "false",
          editByUser: "true",
        },
        {
          name: "showMesh",
          value: "false",
          editByUser: "true",
        },
        {
          name: "fadeDistanceForCloseFeatures",
          value: "0.3",
          editByUser: "true",
        },
        {
          name: "showVerticalText",
          value: "false",
          editByUser: "true",
        },
        {
          name: "showTextRecognitionRectangles",
          value: "false",
          editByUser: "true",
        },
        {
          name: "canUseFallbackDistance",
          value: "true",
          editByUser: "true",
        },
        {
          name: "defaultMaxDistance",
          value: "4.0",
          editByUser: "true",
        },
        {
          name: "defaultFallbackDistance",
          value: "2.0",
          editByUser: "true",
        },
        {
          name: "originBuffer",
          value: "7.0",
          editByUser: "true",
        },
        {
          name: "locationIntervalInitial",
          value: "1.0",
          editByUser: "true",
        },
        {
          name: "locationIntervalLocked",
          value: "2.0",
          editByUser: "true",
        },
        {
          name: "defaultJobType",
          value: "Utility Demo",
          editByUser: "true",
        },
        {
          name: "ductSize",
          value: "0.02",
          editByUser: "true",
        },
        {
          name: "imageReduction",
          value: "0.4",
          editByUser: "true",
        },
        {
          name: "imageCompression",
          value: "0.8",
          editByUser: "true",
        },
        {
          name: "export3D",
          value: "true",
          editByUser: "true",
        },
        {
          name: "googleCloudApiKey",
          value: "AIzaSyCL6cRXcKfDbw15EiJs1wHs5rLrGUqqNM8",
          editByUser: "false",
        },
        {
          name: "showPoleMeasurement",
          value: "true",
          editByUser: "true",
        },
        {
          name: "differenceSummaryDefaultToMatch",
          value: "false",
          editByUser: "true",
        },
        {
          name: "saveForLater",
          value: "false",
          editByUser: "true",
        }
      ],
    },
    {
      type: "Advanced - Recognition Display",
      settings: [
        {
          name: "recognizedText",
          value: "false",
          editByUser: "true",
        },
        {
          name: "recognizedBarcode",
          value: "false",
          editByUser: "true",
        },
        {
          name: "classifiedImages",
          value: "false",
          editByUser: "true",
        },
        {
          name: "imageConfidence",
          value: "true",
          editByUser: "true",
        },
        {
          name: "recognizedSpeech",
          value: "false",
          editByUser: "true",
        },
        {
          name: "attributeRecognition",
          value: "false",
          editByUser: "true",
        },
      ],
    },
    {
      type: "Advanced - Speech Synthesis",
      settings: [
        {
          name: "speechSynthesizerEnabled",
          value: "false",
          editByUser: "true",
        },
        {
          name: "speechOverlapHandlingMethod",
          value: "abortPrevious",
          editByUser: "true",
        },
      ],
    },
    {
      type: "Advanced - Recognition Configuration",
      settings: [
        {
          name: "minimumImageConfidence",
          value: "0.5",
          editByUser: "true",
        },
        {
          name: "minimumTextConfidence",
          value: "0.4",
          editByUser: "true",
        },
        {
          name: "recognitionDelay",
          value: "0.5",
          editByUser: "true",
        },
        {
          name: "increaseRecognitionQos",
          value: "true",
          editByUser: "true",
        },
        {
          name: "autoFeaturePlacement",
          value: "false",
          editByUser: "true",
        },
        {
          name: "saveRecognitionImages",
          value: "false",
          editByUser: "true",
        },
        {
          name: "saveRecognitionMetadata",
          value: "false",
          editByUser: "true",
        },
        {
          name: "continuousSpeechRecognition",
          value: "false",
          editByUser: "true",
        },
        {
          name: "speechRecognitionClearInputBufferDuration",
          value: "1",
          editByUser: "true",
        },
        {
          name: "trackingState",
          value: "false",
          editByUser: "true",
        },
        {
          name: "verticalSurfaces",
          value: "false",
          editByUser: "true",
        },
        {
          name: "horizontalPlanes",
          value: "false",
          editByUser: "true",
        },
        {
          name: "analyzeSceneStability",
          value: "false",
          editByUser: "true",
        },
        {
          name: "sceneStabilityThreshold",
          value: "120",
          editByUser: "true",
        },
        {
          name: "showModelInitialization",
          value: "false",
          editByUser: "true",
        },
        {
          name: "rotationCheck",
          value: "false",
          editByUser: "true",
        }
      ],
    },
    {
      type: "Advanced - Recognition Toubleshooting",
      settings: [
        {
          name: "disableTextRecognition",
          value: "false",
          editByUser: "true",
        },
        {
          name: "recognitionLanguage",
          value: "en-US",
          editByUser: "true",
        },
      ],
    },
    {
      type: "Advanced - External Recognition",
      settings: [
        {
          name: "gcloudVisionEnabled",
          value: "false",
          editByUser: "true",
        },
        {
          name: "chatGPTEnabled",
          value: "false",
          editByUser: "true",
        },
        {
          name: "prioritizeExternalTextRecognition",
          value: "false",
          editByUser: "true",
        },
        {
          name: "chatGPTPrompt",
          value: "Read this label and find attributes in JSON, use regex in JSON to parse value if possible. Return the data as key/value pairs encoded into json and no other information.",
          editByUser: "true",
        },
      ],
    },
  ]


  const handleOverwriteConfirm = async (e) => {
    setOverwriteMessage(null);
   
    var result = await updateSettings(props.accessToken, props.superAdminOrgId, settings, true);
    if (result.status == 200){
      setSettingsId(result.data.uuid);
      settings.uuid = result.data.uuid;
      setSettings(settings);
      setMessage("Updates saved!");
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
    else{
      setMessage("Failed to save: " + result.message);
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  }

  const validateData = () => {
    
  }

  const handleResetSettings = () => {
    var s = settings
    if (s == null){
      s = {}
    }
   
    if (props.superAdminOrgId == "default"){
        s.settingTypes = st;
        saveSettings(s);
        setSettingTypes(st);
        setSettingTypeHighlight(st[settingTypeIndex])
    }
    else{
        //Get settings from default settings record
        getSettings(props.accessToken, "default").then(function(settings){
          if (settings.data != ''){
              var settingtypes = settings.data.settingTypes
              s.settingTypes = settingtypes;
              saveSettings(s);
              setSettingTypes(settingtypes);
              setSettingTypeHighlight(settingtypes[settingTypeIndex])
          }
        });
    }
  }

  const handleSaveSettings = async () => {
    validateData();

    if (settings){
      settings.settingTypes = settingTypes;
    }
   
    saveSettings(settings);
    
  };

  const saveSettings = async (s) => {
    var result;
    if (settingsId){
      result = await updateSettings(props.accessToken, props.superAdminOrgId, s);
    }
    else{
      result = await createSettings(props.accessToken, props.superAdminOrgId, s);
    }
    
    if (result == "serverchanged"){
      //server has updates, prompt user if they want to overwrite
      setOverwriteMessage("A newer version of the config exists on the server, do you want to overwrite?")
    }
    else if (result == "noupdates"){
      setMessage("No updates!");
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
    else if (result.status == 200){
      setSettingsId(result.data.uuid);
      settings.uuid = result.data.uuid;
      setSettings(settings);
      setMessage("Updates saved!");
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
    else{
      setMessage("Failed to save: " + result.message);
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  }

  const handleCompareSettings = async () => {
    
    if (settings){
      settings.settingTypes = settingTypes;
      var result;

      if (props.superAdminOrgId == "default"){
        result = await compareAllSettings(props.accessToken, settings);
      } else{
        result = await compareSettings(props.accessToken, settings);
      }
      
      if (result.length == 0){
        setMessage("All settings are the same as default!");
        setTimeout(function(){
          setMessage(null);
        }, 3000)
      }
      else{
        //Display modal dialog
        setDifferences(result);
      }
    }
   
  };

  const handleDifferenceClose = async () => {
    var checked = differences.filter(function(diff){
      return diff.checked
    })
    if (checked.length > 0){
      if (props.superAdminOrgId == "default"){
        //update settings in other orgs on server
        let result = await updateAllSettings(props.accessToken, checked);
        if (result.status == 200){
          setMessage("Updates pushed!");
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
        else{
          setMessage("Failed to push: " + result.message);
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
      } else{
        handleSaveSettings();
      }
    }
    setDifferences(null);
  }

  function handleApplyDefault(diff, e){
    if (props.superAdminOrgId == "default"){
      //Default settings page
      //make changes in diff, to be updated on the server later through the controller
      var allDiffs = differences
      allDiffs.forEach(function(d){
        if (d.name == diff.name){
          if (e.currentTarget.checked){
            d.newValue = diff.default;
            diff.checked = true;
          }
          else{
            delete d.newValue;
            diff.checked = false;
          }
        }
      });
      setDifferences(allDiffs);
    }
    else{
      //replace setting with default in diff
      var updatedSTs = settingTypes
      
      //update
      updatedSTs.forEach(function(st){
        if (diff.value == "missing"){
          if (diff.type == st.type){
            //add
            if (e.currentTarget.checked) {
              var newSetting = {};
              newSetting.name = diff.name;
              newSetting.value = diff.default;
              newSetting.editByUser = diff.editByUser;
              diff.checked = e.currentTarget.checked;
              st.settings.push(newSetting)
            }
            else{
              //remove setting
              let index = st.settings.findIndex(function(setting){
                return setting.name == diff.name;
              });
              if(index != -1) {
                st.settings.splice(index, 1); // from index remove 1 element
              }
            }
          }
        } else{
          st.settings.forEach(function(s){
            if (s.name == diff.name){
              if (e.currentTarget.checked){
                s.value = diff.default;
                diff.checked = true;
              }
              else{
                s.value = diff.value;
                diff.checked = false;
              }
            }
          })
        }
      })
      
      setSettingTypes(updatedSTs);
    }
  }

  const handleHighlight = (settingtype, i, e) => {
    setSettingTypeHighlight(settingtype);
    setSettingTypeIndex(i);
  };

  const handleOverwriteCancel = (e) => {
    setOverwriteMessage(null);
  }

  const handleSettingTypeTask = (key, e) => {
    switch(key){
      
        case "save":
          handleSaveSettings();
          break;

        case "reset":
            handleResetSettings();
            break;

        case "compare":
            handleCompareSettings();
            break;

      default:
    }
  };

  useEffect(() => {
    if (props.accessToken){
       getSettings(props.accessToken, props.superAdminOrgId).then(function(settings){
        if (settings.data != ''){
            var settingtypes = settings.data.settingTypes
         
            //Populate page
            setSettings(settings.data);
            setSettingsId(settings.data.uuid);
            setSettingTypes(settingtypes);
            if (settingtypes.length > 0){
              handleHighlight(settingtypes[0],0);
            }
        }
        else{
          setSettings(null);
          setSettingsId(null);
          setSettingTypes([]);
          setSettingTypeIndex(-1);
          setSettingTypeHighlight(null);
        }
     }); 
    }
       
   },[props.accessToken, props.superAdminOrgId]);

   function fieldUpdate(e){
    //Find current setting type in the array to update
    var currentST = settingTypes[settingTypeIndex]; //Need to access by settingTypeIndex so that changing Setting Type name won't cause issue
    
    var setting = currentST.settings.filter(function(s){
      return s.name == e.currentTarget.name
    })[0];

    setting.value = e.currentTarget.value;
     
    //Update current jobtype as well
    if (settingTypeHighlight){
      var newSettingType = {...currentST}; //Clone it so that it will trigger state change
      setSettingTypeHighlight(newSettingType);
    }
    
   }

   function fieldCheckUpdate(e){
    //Find current setting type in the array to update
     var currentST = settingTypes[settingTypeIndex]; //Need to access by settingTypeIndex so that changing Setting Type name won't cause issue
    
     if (e.currentTarget.value != ""){
        var value = e.currentTarget.checked;
        if (value == true){
          value = "true"
        }
        else{
          value = "false"
        }
        var setting = currentST.settings.filter(function(s){
          return s.name == e.currentTarget.name
        })[0];

        setting.value = value;
     }

    //Update current jobtype as well
    if (settingTypeHighlight){
      var newSettingType = {...currentST}; //Clone it so that it will trigger state change
      setSettingTypeHighlight(newSettingType);
    }
   }

   function fieldUserEditUpdate(e){
    //Find current setting type in the array to update
     var currentST = settingTypes[settingTypeIndex]; //Need to access by settingTypeIndex so that changing Setting Type name won't cause issue
    
     if (e.currentTarget.value != ""){
        var value = e.currentTarget.checked;
        if (value == true){
          value = "true"
        }
        else{
          value = "false"
        }
        var setting = currentST.settings.filter(function(s){
          return s.name == e.currentTarget.name
        })[0];
        
        setting.editByUser = value;
     }

    //Update current jobtype as well
    if (settingTypeHighlight){
      var newSettingType = {...currentST}; //Clone it so that it will trigger state change
      setSettingTypeHighlight(newSettingType);
    }
   }

   function getDisplayText(text){
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
   }

   function getSettingsForm(){
    return (
      settingTypeHighlight.settings.map(setting => {
          return (<Form.Group as={Row} className="mb-2">
              <Form.Label column sm={5}>{getDisplayText(setting.name)}</Form.Label>
              <Col sm={5}>{getControl(setting)}</Col>
              <Col sm={2}>{getEyeIcon(setting.editByUser)}&nbsp;&nbsp;&nbsp;{getUserEdit(setting)}</Col>
          </Form.Group>)
      })
    )
   }
   
   function getEyeIcon(editByUser){
    if (editByUser == "true"){
      return <FontAwesomeIcon icon={faEye} size="sm"/>
    }
    else{
      return <FontAwesomeIcon icon={faEyeSlash} size="sm"/>
    }
    
   }

   function getUserEdit(setting){
    return (<input type="checkbox" name={setting.name} label="" checked={JSON.parse(setting.editByUser)} onChange={fieldUserEditUpdate.bind(this)}></input>)
   }

   function getToggleDisplay(value){
    if (JSON.parse(value) == true){
      return "Yes";
    }
    else{
      return "No";
    }
   }

   var rangeSetting = {
    vpsAlertLow:{min:1, max:20, step:0.5, unit:"(m)"},
    vpsAlertHigh:{min:1, max:20, step:0.5, unit:"(m)"},
    fadeDistanceForCloseFeatures:{min:0.1, max:2, step:0.1, unit:"(m)"},
    defaultMaxDistance:{min:1, max:5, step:0.1, unit:"(m)"},
    defaultFallbackDistance:{min:0.5, max:5, step:0.1, unit:"(m)"},
    originBuffer:{min:0, max:20, step:0.1, unit:"(m)"},
    locationIntervalInitial:{min:0.1, max:1, step:0.1, unit:"(s)"},
    locationIntervalLocked:{min:0.5, max:5, step:0.1, unit:"(s)"},
    ductSize:{min:0.003, max:0.05, step:0.001},
    minimumImageConfidence:{min:0, max:1, step:0.01},
    minimumTextConfidence:{min:0, max:1, step:0.01},
    recognitionDelay:{min:0.1, max:2, step:0.01,  unit:"(s)"},
    sceneStabilityThreshold: {min:50, max:500, step:1},
    speechRecognitionClearInputBufferDuration: {min:0, max:10, step:0.5,  unit:"(s)"},
    imageReduction: {min:0.25, max:1, step:0.05},
    imageCompression: {min:0.5, max:1, step:0.05}
   }

   var options = {
    loggingLevel:["debug", "info", "warning", "error"],
    handedness:["right", "left"],
    measurementUnit:["feet", "meters"],
    speechOverlapHandlingMethod:["abortPrevious", "waitForCompletion"],
    locationAlgorithm:["Blended","Gps Priority"]
   }

   function getControl(setting){
    if (setting.value == "true" || setting.value == "false"){
      return (<Form.Check type="switch" name={setting.name} label={getToggleDisplay(setting.value)} checked={JSON.parse(setting.value)} onChange={fieldCheckUpdate.bind(this)} />)
    }
    else if (isNaN(setting.value) == false && setting.value != "")
    {
      var range = rangeSetting[setting.name]
      if (range == null){
        range = {min:1, max:100, step:1}
      }
      var unit = ""
      if (range.unit != null){
        unit = range.unit
      }
      return (<div><div style={{width:"100%"}}><center><b>{setting.value}{unit}</b></center></div><span>{range.min}</span>&nbsp;
      <Form.Range style={{width:"70%"}} name={setting.name} value={parseFloat(setting.value)} onChange={fieldUpdate.bind(this)} min={range.min} max={range.max} step={range.step}/>&nbsp;
      <span>{range.max}</span>
              </div>)
    }
    else if (options[setting.name] != null){
      return(
      <Form.Select name={setting.name} value={setting.value} onChange={fieldUpdate.bind(this)}>
        {options[setting.name].map((o,i) => (
        <option value={o}>{getDisplayText(o)}</option>
      ))}
      </Form.Select>)
    }
    else{
      return (
        <Form.Control type="input" name={setting.name} value={setting.value} onChange={fieldUpdate.bind(this)}/>
      )
    }
   }

   function getRightPane(){
    if (settingTypeHighlight){
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleSettingTypeTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        <Form className='form'>
          {getSettingsForm()}
        </Form>
        </Container>
      )
    }
    else{
      //Only show the save button when no items
      return (
        <Container fluid>
        <Navbar bg="light" variant="light" expand="lg">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto my-2 my-lg-0">
                </Nav>
                <Nav onSelect={handleSettingTypeTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        </Container>
      )
    }
   }

   function getSettingTypeHighlightKey(){
    if (settingTypeHighlight){
      return settingTypeHighlight.name;
    }
    else{
      return null;
    }
   }

   function getLeftPane(){
    var isHorizontal = true;
    var height = props.height - 150;
    if (window.innerHeight > window.innerWidth){
      isHorizontal = false;
      height = (props.height/2) - 200;
    }
      return (
        <div>
        <Row>
          <Col sm={4}>
            <Navbar bg="light" variant="light">
            {props.superAdminOrgId == "default" &&
              <Navbar.Brand>
              &nbsp;&nbsp;Default Settings
              </Navbar.Brand>
            }
            {props.superAdminOrgId != "default" &&
              <Navbar.Brand>
              &nbsp;&nbsp;Settings
              </Navbar.Brand>
            }
              <Nav className="me-auto">
              </Nav>
              <Nav onSelect={handleSettingTypeTask}>
                <Nav.Link eventKey="reset"><FontAwesomeIcon icon={faUndo} />&nbsp;&nbsp;</Nav.Link>
              </Nav>
              <Nav onSelect={handleSettingTypeTask}>
                <Nav.Link eventKey="compare"><FontAwesomeIcon icon={faCodeCompare} />&nbsp;&nbsp;</Nav.Link>
              </Nav>
          </Navbar>
          <ListGroup defaultActiveKey={getSettingTypeHighlightKey()} style={{height:`${height}px`, overflow:'auto'}}>
          {settingTypes.map((settingtype,i) => (
            <ListGroup.Item key={settingtype.type} eventKey={settingtype.type} active={settingtype.type == getSettingTypeHighlightKey()} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,settingtype,i)}>
              <Container fluid>
                <Row>
                  <Col><Form.Label>{getDisplayText(settingtype.type)}</Form.Label></Col>
                  <Col xs={1}><Badge bg="primary" pill>{settingtype.settings.length}</Badge></Col>
                </Row>
              </Container>
            </ListGroup.Item>
            ))}
          </ListGroup>
          </Col>
          {isHorizontal && 
            <Col>{getRightPane()}</Col>
          }
      </Row>
      {isHorizontal == false && 
        <Row>
          <Col>{getRightPane()}</Col>
        </Row>}
        </div>
      )
   }

   function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }

  function showDifferences(){
    return (
      <ListGroup style={{overflow:'auto'}}>
        <ListGroup.Item key={"heading"} eventKey={"heading"} action className="d-flex justify-content-between align-items-start">
          <Container fluid>
            <Row>
              {differences[0].org_name &&
              <Col xs={4} className="header">Organization</Col>
              }
              <Col xs={4} className="header">Setting</Col>
              <Col xs={1} className="header">Value</Col>
              <Col xs={1} className="header">Default</Col>
              <Col xs={2} className="f2">Use Default</Col>
            </Row>
          </Container>
          </ListGroup.Item>
      {differences.map((diff) => (
        <ListGroup.Item key={diff.name} eventKey={diff.name} action className="d-flex justify-content-between align-items-start">
          <Container fluid>
            <Row>
            {diff.org_name &&
              <Col xs={4}>{diff.org_name}</Col>
            }
              <Col xs={4}>{diff.name}</Col>
              <Col xs={1}>{diff.value}</Col>
              <Col xs={1} className="f2">{diff.default}</Col>
              <Col xs={2}><input type="checkbox" id={diff.name} onChange={handleApplyDefault.bind(this, diff)}/></Col>
            </Row>
          </Container>
          </ListGroup.Item>
        ))}
        </ListGroup>
    )
  }

  function getDifferenceDialog(){
    if (differences){
      return(
        <Modal dialogClassName="modal-dialog" show={differences != null}>
          <Modal.Header>
            <Modal.Title>Differences from Default Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>{showDifferences()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDifferenceClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }
    else{
      return null
    }
    
  }
  return (
    <div style={{position:"relative"}}>
        <Row>
          <Col>{getLeftPane()}</Col>
        </Row>
        {getMessagePlaceholder()}
        <ConfirmDialog title="Save" message={overwriteMessage} onConfirm={handleOverwriteConfirm} onClose={handleOverwriteCancel}></ConfirmDialog>
        {getDifferenceDialog()}
    </div>
  )  
        
}

export default SettingEditor;
