
import React, { useEffect, useRef } from "react";
import { view, addFeatures, clearHighlight, zoomToFeature, toggleLayer, removeLayers, zoomToLayerbyId, attachMapEvent} from '../../controllers/mapController';
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

export default function JobLayer(props) {
  
  function addJobs(jobs){
    var features = [];

    jobs.forEach(function(job){
      if (job.centroid_lat && job.centroid_lon){
        features.push({
          geometry: {
            type: "point",
            latitude: job.centroid_lat,
            longitude: job.centroid_lon
          },
          attributes: job.attributes
        });
      }
    });
    
    var renderer = {
      type: "simple",  // autocasts as new SimpleRenderer()
      symbol: {
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      color: [226, 119, 40],
      outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 255, 255],
      width: 2
      }
      }
    };

    var fieldInfos = props.fields.map(function(f){
      return {fieldName: f.name, label: f.alias}
    });

    const viewJobAction = {
      title: "View Job Features",
      id: "view-job"
    };

    const pTemplate = {
      // autocasts as new PopupTemplate()
      title: "{name}",
      content: [
        {
          // It is also possible to set the fieldInfos outside of the content
          // directly in the popupTemplate. If no fieldInfos is specifically set
          // in the content, it defaults to whatever may be set within the popupTemplate.
          type: "fields",
          fieldInfos: fieldInfos
        }
      ],
      actions: [viewJobAction]
    };

    // Execute each time the "View job" is clicked
    function viewJob() {
      if (props.jobs && props.jobs.length >0){
        const jobFeature = view.popup.selectedFeature;
        var job = props.jobs.filter(function(j){
          return j.name == jobFeature.attributes.name;
        });
        if (job && job.length>0){
          props.onViewJob(job[0]);
        }
      }
      
    }

    function highlightJob(jobid) {
      
      
      if (props.jobs && props.jobs.length >0){
        
          var job = props.jobs.filter(function(j){
            return j.name == jobid;
          });
          if (job && job.length>0){
            props.onHighlightJob(job[0]);
          }
        
      }
      
    }

    reactiveUtils.on(
      () => view.popup,
      "trigger-action",
      (event) => {  // Execute the measureThis() function if the measure-this action is clicked
        if (event.action.id === "view-job") {
          view.popup.close();
          clearHighlight();
          viewJob();
        }
    });

    attachMapEvent("click", function(event){
      view.hitTest(event)
        .then(function(response){
            if (response.results.length > 0) {
              // do something with the result graphic
              var g = response.results.filter(function (result) {
                // check if the graphic belongs to the layer of interest
                return result.graphic.layer.id == "job";
              });
                
              if (g.length > 0){
                var attributes = g[0].graphic.attributes;
                var jobid = attributes.name;
                clearHighlight();
                highlightJob(jobid);
              } 
            }
        });
    });
    

    addFeatures(features, renderer, props.fields, pTemplate, "job", "Job", true);
    zoomToLayerbyId("job", true);
  }

  function zoomToJob(job){
    if (job && job.centroid_lat && job.centroid_lat != 0){
      //zoomToPoint(job.centroid_lat, job.centroid_lon);
      zoomToFeature("name='" + job.name + "'","job") 
    }
    else{
      clearHighlight();
    }
  }

  //Add jobs to map
  useEffect(_ => {
    
      if (props.job == null && props.jobs.length > 0){
        addJobs(props.jobs);
      } else {
        //remove job layer
        addFeatures(null, null, null, null, "job", "Job", true);
      }
     
  }, [props.jobs]);

  useEffect(_ => {
    
    if (props.job == null){
      //zoomToLayerbyId("job");
      zoomToJob(props.highlight);
      toggleLayer("job", true);
      removeLayers();
    }
    else{
        toggleLayer("job", false);
    }

}, [props.job]);

  //Zoom to job centroid when a job is selected in the list
  useEffect(_ => {
    setTimeout(function(){
      if (view.popup.visible == false){ //If popup is visible, selection is from the map, don't need to zoom
        zoomToJob(props.highlight);
      }
    },200);
    
  }, [props.highlight]);
  
  // assign elementRef to the ref of our component
  return (
    <div />
  );
}