//Get hash parameter from url
export function getHashParam(hash, position) {
    if (hash && hash.length > 0){
        const params = hash.slice(hash.indexOf('#') + 1).split('/');
        if (params && params.length > position){
            return decodeURI(params[position]);
        }
        else{
            return null;
        }
    }
    else{
        return null;
    }
  }
  
  //Set hash value: object, property, position
  export function setHash(hashVal, prop, position, clear){
   
    var hash = window.location.hash;
    var params;
    if (hash && hash.length >0 && !clear){
        params = hash.slice(hash.indexOf('#') + 1).split('/');
        if (hashVal){
            if (params.length > position){
              params[position] = hashVal[prop];
            }
            else{
              params.push(hashVal[prop]);
            }
            
        }
        else{
            params.splice(position);
        }
        hash = "#" + params.join("/");
    }
    else{
        //no hash yet, just set it
        hash = "#" + hashVal[prop];
    }
   
    //window.location.hash = hash;
    var newurl = window.location.href.replace(window.location.hash, "")
     newurl = newurl + hash;
    if (encodeURI(newurl) != window.location.href){
        //debug: alert("new:" + newurl + " current:" + window.location.href);
        window.history.pushState(hash, null, newurl)
    }
  }

  window.onpopstate= (historyEvent) => {
    if (historyEvent && historyEvent.state){
      window.location.reload(false);
    }
  }