
import React, { useEffect, useState, useContext } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Stack, Button, Form, Toast, NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faTrash, faFloppyDisk, faPlus, faUndo, faDownload, faUpload} from '@fortawesome/free-solid-svg-icons';
import { getHashParam, setHash} from '../../controllers/hashController';
import { getUserRoles, getRoles, getUsers, getInvites, createInvite, deleteUser, deleteUserInvite, updateUserRoles, getDevices, updateDeviceName} from '../../controllers/authController';
import ConfirmDialog from '../controls/confirmDialog';
import fileDownload from 'js-file-download';

function UserList(props) {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [message, setMessage] = useState("Loading");
  const [user, setUser] = useState(null);
  const [userHighlight, setUserHighlight] = useState(null);
  const [deviceHighlight, setDeviceHighlight] = useState(null);
  const [userIndex, setUserIndex] = useState(-1);
  const [users, setUsers] = useState([]);  
  const [allUsers, setAllUsers] = useState([]); 
  const [roles, setRoles] = useState([]); 
  const [roleFilter, setRoleFilter] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [devices, setDevices] = useState([]);  

  const [paging, setPaging] = useState(1);
  const [pagingEnd, setPagingEnd] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [pagingKey, setPagingKey] = useState(null);
  
  //Get which tab to open from url
   //Get user mode from # hash in url
   var mode = getHashParam(window.location.hash,2);
   if (!mode){
     mode = "users"; //default to jobs
     setHash({usermode:mode},"usermode",2);
   }

  //Set usermode from # hash in url
  const [usermode, setUserMode] = useState(mode);

  const handleBackToOrgs = (e) => {
    props.onBackToOrgs();
  };

  const handleUserHighlight = (user, i, e) => {
     //populate roles
     if (!user.roles){
        if (user.user_id){
          setUserHighlight(user);
          setUserIndex(i);
          setHash(user, "user_id", 3)
        }
        else if(user.id){
            setUserHighlight(user);
            setUserIndex(i);
            setHash(user, "id", 3)
        }
        
     }
     else{
        setUserHighlight(user);
        setUserIndex(i);
        setHash(user, "id", 3)
     }
     
  };

  const handleDeviceHighlight = (device, i, e) => {
      setDeviceHighlight(device);
 };

  const clearChecked = () => {
    //Let's clear checkboxes in all levels
    users.forEach(function(o){
      delete o.checked;
    })
  };

  const handleSaveUser = async () => {
    clearChecked();
    var result;
    var currentUser = users[userIndex]; 
    if (currentUser.user_id){
      result = await updateUserRoles(props.accessToken, props.org.id, currentUser);
    }
    else{
      ////accessToken, orgId, email
      result = await createInvite(props.accessToken, props.org.id, currentUser);
    }
    
    if (result.status == 200){
        if (currentUser.user_id){
            //update user roles success
        }
        else if (!currentUser.id){
            //TODO: success need to return invite details
            currentUser = result.data;
            //fetch invite
        }
        setUserHighlight(currentUser);
        setMessage("Updates saved!");

            setTimeout(function(){
                setMessage(null);
            }, 3000)
        
    }
    else{
      if (result.data && result.data.message){
        setMessage("Failed to save: " + result.data.message);
      }
      else{
        setMessage("Failed to save!");
      }
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  };

  const handleSaveDevice = async () => {
    var result;
   
      result = await updateDeviceName(props.accessToken, props.superAdminOrgId, deviceHighlight);
   
    if (result.status == 200){
        setMessage("Updates saved!");
            setTimeout(function(){
                setMessage(null);
            }, 3000)
    }
    else{
      if (result.data && result.data.message){
        setMessage("Failed to save: " + result.data.message);
      }
      else{
        setMessage("Failed to save!");
      }
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  };

  const handleRefresh = (key, e) => {
    setUsers(allUsers);
    setRoleFilter([]);
    setNameFilter("");
  };

  const handleUserMode = (key, e) => {

    setUserMode(key);
    setHash({usermode:key},"usermode",2);
    setHash(null,null,3);

    //Clear right pane
    setUserHighlight(null);
  };
  

  const handleUserTask = (key, e) => {
    switch(key){
      case "delete":
        handleDelete();
        break;
      
        case "save":
          handleSaveUser();
          break;

        case "add":
          handleAdd();
          break;

        case "download":
          handleDownload();
          break;

      default:
    }
  };

  const handleDeviceTask = (key, e) => {
    switch(key){
        case "save":
          handleSaveDevice();
          break;

      default:
    }
  };

  const handleDeleteCancel = (e) => {
    setDialogMessage(null);
  }

  const handleAdd = () => {
   
    //Add empty user
    var user = {
     "name":"",
     "email":"",
     "roles":[]
     }
     var newUsers = [...users]; //clone to update
     newUsers.push(user);
 
     setUserHighlight(user);
     setUserIndex(newUsers.length-1);
     setUsers(newUsers);
   }
 
   const handleDelete = () => {
     //delete users
     var selectedUsers = users.filter(function(o){
       return o.checked;
     })
    
     if (selectedUsers.length > 0){
       var deleteMsg;
     
       if (selectedUsers.length == 1){
         deleteMsg = "Delete " + selectedUsers[0].name + "?";
       }
       else{
         deleteMsg = "Delete " + selectedUsers.length + " " + usermode + "?";
       }
       setDialogMessage(deleteMsg);
     }
   }

   const handleDeleteConfirm = (e) => {
    setDialogMessage(null);
   
    var selectedUsers = users.filter(function(u){
        return u.checked;
      })

      selectedUsers.forEach(async function(u){
        var result;
        if (u.user_id){
            result = await deleteUser(props.accessToken,props.org.id,u.user_id);
        }
        else if (u.id){
            result = await deleteUserInvite(props.accessToken, props.org.id, u.id);
        }
        
        if (result.status == 200){
            setMessage("Deleted " + u.name);
            setTimeout(function(){
              setMessage(null);
            }, 2000)
            fetchUsersAndRoles();
          }
          else{
            setMessage("Failed to delete: " + u.name);
            setTimeout(function(){
              setMessage(null);
            }, 2000)
          }
    })

    //delete orgs from UI
    var leftUsers = users.filter(function(u){
      return !u.checked
    })
    setUsers([...leftUsers]);
   
    //Check to see if currently selected field is still in the list
    var currentUsers = leftUsers.filter(function(u){
      return u.name == userHighlight.name;
    })
    if (currentUsers.length == 0){
      if (leftUsers.length > 0){
        setUserHighlight(leftUsers[0]);
        setUserIndex(0);
      }
      else{
        setUserHighlight(null);
        setUserIndex(0);
      }
    }
  }

  function filterUpdate(e){
    var role = e.currentTarget.value;
    var filtered = allUsers.filter(function(user){
      return user.roles.indexOf(role) >= 0 && user.name.indexOf(nameFilter)>=0;
    });
  
    setUsers(filtered);
    setRoleFilter([role]);
    setUserHighlight(filtered[0]);
    setUserIndex(0);
  }

  function textFilterUpdate(e){
    var name = e.currentTarget.value;
  
    var filtered = allUsers.filter(function(user){
      if (roleFilter.length > 0){
        return user.name.indexOf(name)>=0 && user.roles.indexOf(roleFilter[0]) >= 0;
      }
      else{
        return user.name.indexOf(name)>=0;
      }
      
    });
  
    setNameFilter(name);
    setUsers(filtered);
    setUserHighlight(filtered[0]);
    setUserIndex(0);
  }

  function fieldUpdate(e){
    //Find current job type in the array to update
     var currentUser = users[userIndex]; //Need to access by index
    
     if (e.currentTarget.value != ""){
        if (e.currentTarget.name == "email"){
            if ((usermode == "users" && !currentUser.user_id) || usermode == "invites" && !currentUser.id){
                currentUser[e.currentTarget.name] = e.currentTarget.value.toLowerCase();
                currentUser["name"] = e.currentTarget.value.toLowerCase();
            }
        }
        else if (e.currentTarget.name == "roles"){
            var userRoles = [].slice.call(e.target.selectedOptions).map(item => item.value)
            if (currentUser[e.currentTarget.name] && currentUser[e.currentTarget.name].length >0){
                //If role is in existing array, remove it, else add it
                var existingRoles = currentUser[e.currentTarget.name];
                userRoles.forEach(function(r,i){
                    var currentRole = roles.filter(function(role){
                      return role.id == r
                    })
                    if (existingRoles.indexOf(r) >= 0){
                        //Remove it
                        existingRoles.splice(existingRoles.indexOf(r),1)
                        //Update roles user count
                        currentRole[0].count --;
                    }
                    else{
                        //Add it
                        existingRoles.push(r);
                        //Update roles user count
                        if (currentRole[0].count){
                          currentRole[0].count ++;
                        }
                        else{
                          currentRole[0].count = 1;
                        }
                        
                    }
                });
                
                currentUser[e.currentTarget.name] = existingRoles;
            }
            else{
                currentUser[e.currentTarget.name] = userRoles;
            }
        }
     }
     else{
        if (currentUser[e.currentTarget.name]){
          delete currentUser[e.currentTarget.name]
        }
     }

    //Update current user as well
    if (userHighlight){
      var newUser = {...currentUser}; //Clone it so that it will trigger state change
      setUserHighlight(newUser);
    }
    
   }
 
   function deviceFieldUpdate(e){
      var currentDevice = {...deviceHighlight}
      currentDevice[e.currentTarget.name] = e.currentTarget.value;
      setDeviceHighlight(currentDevice);
   }

   const handleCheckChanged = (user, e) => {
    var clonedUsers = [...users]//Clone the array before update, so that we get proper state change
    var selectedUser = clonedUsers.filter(function(u){
      return u.name == user.name;
    })[0];
    if (selectedUser){
        selectedUser.checked = e.currentTarget.checked;
      setUsers(clonedUsers);
    }
  };

  function formatDate(dt){
    //2023-05-05T16:30:36Z
    if (dt){
        var localDate = new Date(dt);
        var formattedDate = localDate.toLocaleString();//.substring(0, 16);
        formattedDate = formattedDate.replace(",", " ");
        return formattedDate
    }
}
  function fetchUsersAndRoles(){
    setRoleFilter([]);
    getUsers(props.accessToken, props.org.id).then(function(users){
      getRoles(props.accessToken).then(function(roles){
        var userIndex = 0
        if (users.length > 0){
          //Paging related
          setPagingEnd(paging + users.length -1);
          if (users.count){
            setTotalUsers(users.count);
          }
          setPagingKey(users.lastEvaluatedKey);

          users.forEach(function(user){
            userIndex ++;
              //Get user roles
              let userRoles = user.Roles;
                //getUserRoles(props.accessToken, props.org.id, user.user_id).then(function(userRoles){
                  var roleIds = userRoles.map(function(ur){
                      var role = roles.filter(function(r){
                        return r.id == ur.id
                      });
                      if (role[0].count){
                        role[0].count ++;
                      }
                      else{
                        role[0].count = 1;
                      }
                      return ur.id
                  })
                  user.roles = roleIds;  
  
                  //If it is the last one
                  if (userIndex == users.length){
                    //setTimeout(function(){
                      var clonedUsers = [...users]//Clone the array before update, so that we get proper state change
                      setUsers(clonedUsers);
                      setAllUsers(clonedUsers);
                      var clonedRoles = [...roles]//Clone the array before update, so that we get proper state change
                      setRoles(clonedRoles);
                     
                      if (users.length > 0){
                        //If there is url parameters, select it
                        var usermode = getHashParam(window.location.hash, 2);
                        var user_id = getHashParam(window.location.hash, 3);
                        if (usermode == "users" && user_id){
                            //find jobType object
                            var selectedUserIndex = users.findIndex(function(u){
                                return u.user_id == user_id;
                            });
                            if (selectedUserIndex >= 0){
                                handleUserHighlight(users[selectedUserIndex]);
                            }
                        }
                        else{
                            handleUserHighlight(users[0],0);
                        }
                    }
                    setMessage(null);
                    //},1000)
                  }
              //});
            })
        }
        else{
          setMessage(null);
          var clonedRoles = [...roles]//Clone the array before update, so that we get proper state change
          setRoles(clonedRoles);
        }
      });
  }); 
  }

  useEffect(() => {
    if (props.accessToken){
      setUsers([]);
       if (usermode == "users"){
        fetchUsersAndRoles();
       }
       else if (usermode == "invites"){
        getInvites(props.accessToken, props.org.id).then(function(invites){
            var users = invites.map(function(i){
                var expired = Date.parse(i.expires_at);
             
                if (expired < Date.now()){
                    i.status = "Expired";
                }
                else
                {
                    i.status = "Pending";
                }
                i.name = i.invitee.email
                i.email = i.invitee.email
                
                return i
            })
            setUsers(users);
            setMessage(null);
            if (users.length > 0){
                
                //If there is url parameters, select it
                var usermode = getHashParam(window.location.hash, 2);
                var id = getHashParam(window.location.hash, 3);
                if (usermode == "invites" && id){
                    //find jobType object
                    var selectedUserIndex = users.findIndex(function(i){
                        return i.id == id;
                    });
                    if (selectedUserIndex >= 0){
                        handleUserHighlight(users[selectedUserIndex]);
                    }
                }
                else{
                    handleUserHighlight(users[0],0);
                }
              }
        }); 
       }
       else if(usermode == "devices"){
        //fetch devices
        getDevices(props.accessToken, props.org.id).then(function(devices){
          setDevices(devices)
          setMessage(null);
          if (devices.length > 0){
            setDeviceHighlight(devices[0]);
          }
        })
      }
    }
   },[props.accessToken, usermode]);

   function getUserCount(role, showCount){
    if (showCount){
     //props.org.metadata.admin_users -> vision-admin
     //props.org.metadata.advanced_users -> vision-advanced-user
     //props.org.metadata.essentials_users -> vision-essentials-user
     //props.org.metadata.standard_users -> vision-standard-user

      var count = 0
      if (role.count){
        count = role.count
      }
      if (props.org.metadata.license_limits && (typeof props.org.metadata.license_limits === 'string' || props.org.metadata.license_limits instanceof String)){
        props.org.metadata.license_limits = JSON.parse(props.org.metadata.license_limits)
      }
      if (props.org.metadata && props.org.metadata.license_limits && props.org.metadata.license_type == "per_user" && role.name == "vision-admin" && props.org.metadata && props.org.metadata.license_limits.admin_users != null){
        return " (" + count + "/" + props.org.metadata.license_limits.admin_users + ")";
      } else if (props.org.metadata && props.org.metadata.license_limits && props.org.metadata.license_type == "per_user" && role.name == "vision-advanced-user" && props.org.metadata && props.org.metadata.license_limits.advanced_users != null){
        return " (" + count + "/" + props.org.metadata.license_limits.advanced_users + ")";
      } else if (props.org.metadata && props.org.metadata.license_limits && props.org.metadata.license_type == "per_user" && role.name == "vision-essentials-user" && props.org.metadata && props.org.metadata.license_limits.essentials_users != null){
        return " (" + count + "/" + props.org.metadata.license_limits.essentials_users + ")";
      } else if (props.org.metadata && props.org.metadata.license_limits && props.org.metadata.license_type == "per_user" && role.name == "vision-user" && props.org.metadata && props.org.metadata.license_limits.standard_users != null){
        return " (" + count + "/" + props.org.metadata.license_limits.standard_users + ")";
      }
      else{
        return " (" + count + ")";
      }
    }
    else{
      return "";
    }
    
   }

   function getRolesPicklist(showCount = false){
    //If vision-admin, show only: vision-user vision-advanceduser or vision-admin
    if (roles.map){
        if (props.userOnly==true){
            var filteredRoles = roles.filter(function(r){
                return r.name != "vision-superadmin" && r.name != "vision-invite-manager" && r.name != "vision-singleuser";
            });

            return (filteredRoles.map((r,i) => (
                <option value={r.id}>{r.name}{getUserCount(r, showCount)}</option>
                )));
        }
        else if (props.org.display_name != "SSP Innovations"){
          var filteredRoles = roles.filter(function(r){
            return r.name != "vision-superadmin" && r.name != "vision-invite-manager";
          });

        return (filteredRoles.map((r,i) => (
            <option value={r.id}>{r.name}{getUserCount(r, showCount)}</option>
            )));
        }
        else{
            return (roles.map((r,i) => (
                <option value={r.id}>{r.name}{getUserCount(r, showCount)}</option>
                )));
        }
    }
   }

   function getCountDisplay(){
     return (
      <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                  <NavLink></NavLink>
                </Nav>
        </Navbar>
        <Stack gap={3}>
        <div className="p-2">First item</div>
        <div className="p-2">Second item</div>
        <div className="p-2">Third item</div>
      </Stack>
        </Container>
      
    );
   }

   function getRightPane(){
    if (usermode == "devices"){
      return getDeviceRightPane()
    }
    else{
      return getUserRightPane()
    }
   }

   function getDeviceRightPane(){

    if (deviceHighlight){
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleDeviceTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        <Form className='form' style={{height:`${props.height - 130}px`}}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Device Name</Form.Label>
            <Col sm={8}><Form.Control type="input" name="device_name" value={deviceHighlight.device_name} onChange={deviceFieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Device Id</Form.Label>
            <Col sm={8}><Form.Control type="input" name="device_id" value={deviceHighlight.device_id}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Model Name</Form.Label>
            <Col sm={8}><Form.Control type="input" name="model_name" value={deviceHighlight.model_name}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>System Version</Form.Label>
            <Col sm={8}><Form.Control type="input" name="system_version" value={deviceHighlight.system_version}/></Col>
          </Form.Group>
        </Form>
        </Container>
      )
    }
    else{
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
        </Navbar>
        </Container>
      )
      //}
    }
   }


   function getUserRightPane(){

    if (userHighlight){
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleUserTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        <Form className='form' style={{height:`${props.height - 130}px`}}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Email</Form.Label>
            <Col sm={8}><Form.Control type="input" name="email" value={userHighlight.email} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
        {
            userHighlight.user_id &&
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>User ID</Form.Label>
                <Col sm={8}><Form.Control type="input" name="user_id" value={userHighlight.user_id}/></Col>
            </Form.Group>
        }
       
            <Form.Group as={Row}className="mb-3">
                <Form.Label column sm={4}>Roles</Form.Label>
                <Col sm={8}>
                <Form.Select name="roles" style={{height:"150px"}} multiple value={userHighlight.roles} onChange={fieldUpdate.bind(this)}>
                {getRolesPicklist()}
                </Form.Select>
                </Col>
          </Form.Group>

          { userHighlight.status &&
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>Status</Form.Label>
                <Col sm={8}><Form.Control type="input" name="status" value={userHighlight.status}/></Col>
            </Form.Group>
          }
          {
            userHighlight.created_at &&
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>Created At</Form.Label>
                <Col sm={8}><Form.Control type="input" name="created_at" value={userHighlight.created_at}/></Col>
            </Form.Group>
          }
          {
            userHighlight.expires_at &&
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>Expires At</Form.Label>
                <Col sm={8}><Form.Control type="input" name="expires_at" value={userHighlight.expires_at}/></Col>
            </Form.Group>
          }
          {
            userHighlight.invitation_url &&
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>Invitation Url</Form.Label>
                <Col sm={8}><Form.Control type="input" name="invitation_url" value={userHighlight.invitation_url}/></Col>
            </Form.Group>
          }
        </Form>
        </Container>
      )
    }
    else{
      //if (usermode == "users") {
      //  return getCountDisplay();
      //}
      //else{
        //Only show the save button when no items
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
        </Navbar>
        </Container>
      )
      //}
    }
   }

   function getUserHighlightKey(){
    if (userHighlight){
      return userHighlight.name;
    }
    else{
      return null;
    }
   }

   function handlePagingNext(){
    //if (paging + 50 < totalUsers){
      if (pagingKey){
        setPaging(pagingEnd+1);
      } else {
        setPaging(1);
      }
      
    //}
  }

  function getPagingControl(){

    return (<span>&nbsp;&nbsp;
      {/*paging>1 &&
      <FontAwesomeIcon className="paging" icon={faChevronLeft} size='sm' onClick={handlePagingPrev.bind(this)}/>
      */}
      {pagingEnd>1 &&
        <span>&nbsp;&nbsp;{paging}-{pagingEnd}</span>
      }
      {(users.length<totalUsers) &&
      <FontAwesomeIcon className="paging" icon={faChevronRight} size='sm' onClick={handlePagingNext.bind(this)}/>
      }
      &nbsp;&nbsp;
    </span>
    )
  }
   function getRolesDisplay(){
    if (usermode == "users"){
      return (
       
              <div>
              <Navbar bg="white" variant="light">
                <Nav className="me-auto">
                  <Nav.Link eventKey="">Filter by roles</Nav.Link>
                </Nav>
                <span>
                  {getPagingControl()}
                  <Form.Control style={{height:`28px`, width:`60%`, display:`inline`}} placeholder="Filter by name" type="input" name="filter" value={nameFilter} onChange={textFilterUpdate.bind(this)}/>
                </span>
                <Nav onSelect={handleRefresh} defaultActiveKey={usermode}>
                <Nav.Link eventKey="refresh"><FontAwesomeIcon icon={faUndo} />&nbsp;&nbsp;</Nav.Link>
                </Nav>
              </Navbar>
              <Form.Select name="roles" style={{height:"150px"}} multiple value={roleFilter} onChange={filterUpdate.bind(this)}>
              {getRolesPicklist(true)}
              </Form.Select>
              </div>
      )
    }
   }

   function getUsersList(height){
    if (usermode == "users" || usermode == "invites"){
      return(
        <ListGroup defaultActiveKey={getUserHighlightKey()} style={{height:`${height}px`, overflow:'auto'}}>
              {users.map((user,i) => (
                <ListGroup.Item key={user.name} eventKey={user.name} active={user.name == getUserHighlightKey()} action className="d-flex justify-content-between align-items-start" onClick={handleUserHighlight.bind(this,user,i)}>
                  <Container fluid>
                    <Row>
                      <Col><Form.Check type="checkbox" id={user.name} label={user.name} checked={user.checked} onChange={handleCheckChanged.bind(this, user)}/></Col>
                      { user.status &&
                            <Col sm={2}>{user.status}</Col>
                        }
                      { user.created_at &&
                            <Col sm={2}>{user.created_at.substr(0,10)}</Col>
                        }
                    </Row>
                  </Container>
                </ListGroup.Item>
                ))}
              </ListGroup>
      )
    }
   }
   
   function getDeviceList(height){
    if (usermode == "devices"){
      return(
        <ListGroup style={{height:`${height}px`, overflow:'auto'}}>
              {devices.map((device,i) => (
                <ListGroup.Item key={device.device_id} eventKey={device.device_id} action className="d-flex justify-content-between align-items-start" onClick={handleDeviceHighlight.bind(this,device,i)}>
                  <Container fluid>
                    <Row>
                      <Col>{device.device_id}</Col>
                      <Col>{device.device_name}</Col>
                      <Col>{formatDate(device.last_login)}</Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
                ))}
              </ListGroup>
      )
    }
   }

   const handleDownload = (key, e) => {
    var exportStream = getUsersDownload()
    if (exportStream){
        fileDownload(exportStream,  "users.csv");
      }
  }

  function lookupRoles(user){
    var lookupRoles = user.roles.map(function(ur){
      var role = roles.filter(function(r){
        return r.id == ur
      })[0];
      return role.name;
    })

    return lookupRoles
  }

   function getUsersDownload(){
    var csv = "Name, Email, Created, Roles\n";
    users.forEach(function(user){
      csv += user.name + ",";
      csv += user.email + ",";
      csv += user.created_at + ",";
      csv += lookupRoles(user) + "\n";
    })
    return csv;
  }

   function getLeftPane(){
    var isHorizontal = true;
    var height = props.height - 150;
    if (window.innerHeight > window.innerWidth){
      isHorizontal = false;
      height = (props.height/2) - 200;
    }

    if (user){
      //return (<UserList height={props.height} org={org} onBackToOrgs={handleBackToOrgs}></UserList>)
    }
    else{
      return (
        <div>
        <Row>
          <Col>
            <Navbar bg="light" variant="light">
            <Nav onSelect={handleBackToOrgs} defaultActiveKey="backToOrgs" className="me-auto">
               {!props.userOnly &&
                <Nav.Link eventKey="backToOrgs"><FontAwesomeIcon icon={faChevronLeft}/></Nav.Link>
               }
                <Nav.Link eventKey="backToOrgs">{props.org.display_name}</Nav.Link>
            </Nav>
            <Nav onSelect={handleUserMode} defaultActiveKey={usermode} className="me-auto">
                <Nav.Link eventKey="users">Users&nbsp;&nbsp;</Nav.Link>
                <Nav.Link eventKey="invites">Invites&nbsp;&nbsp;</Nav.Link>
                <Nav.Link eventKey="devices">Devices&nbsp;&nbsp;</Nav.Link>
            </Nav>
            <Nav onSelect={handleUserTask}>
                { usermode == "invites" &&
                    <Nav.Link eventKey="add"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;</Nav.Link>
                }
                { usermode != "devices" &&
                  <Nav.Link eventKey="delete"><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;</Nav.Link>
                }
                { usermode == "users" &&
                  <Nav.Link eventKey="download"><FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;</Nav.Link>
                }
              </Nav>
          </Navbar>
          {getRolesDisplay()}
          {getUsersList(height)}
          {getDeviceList(height)}
          </Col>
          {(isHorizontal) && 
            <Col>{getRightPane()}</Col>
          }
      </Row>
      {(isHorizontal == false) && 
        <Row>
          <Col>{getRightPane()}</Col>
        </Row>}
        </div>
      )
    }
   }

   function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }

  return (
    <div style={{position:"relative"}}>
        <Row>
          <Col>{getLeftPane()}</Col>
        </Row>
        {getMessagePlaceholder()}
        <ConfirmDialog title="Delete" message={dialogMessage} onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
    </div>
  )  
        
}

export default UserList;
