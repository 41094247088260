import React, { useEffect, useState, useContext } from 'react';
import {Form, Button, Toast, Row, Col, Container, CloseButton, Offcanvas} from "react-bootstrap";
import {generateRegexChatGpt, generateRegex} from '../../controllers/configController';

function RegexDialog(props) {

  const [sampleText, setSampleText] = useState(null);
  const [regexResult, setRegexResult] = useState([]);
  const [regexChatGptResult, setRegexChatGptResult] = useState(null);
  //const [regexChatGptMatch, setRegexChatGptMatch] = useState(null);

  const handleClose = () => {
    //remove from clipboard
    props.onClose();
  };

  function fieldUpdate(e){
    if (e.currentTarget.name == "sampleText"){
        setSampleText(e.currentTarget.value);
    }
   }

   function getRegex(e){
    // /config/regex or /config/regex/chatgpt 
    setRegexChatGptResult(null)
    //setRegexChatGptMatch(null)
    setRegexResult([])
    
    generateRegexChatGpt(props.accessToken, sampleText).then(function(results){
        if (results.status == 200){
            let data = results.data
            setRegexChatGptResult(addWrapper(data.regex));
            //setRegexChatGptMatch(Math.floor(data.matchPercentage).toString()+"%")
        }
    });
    
    generateRegex(props.accessToken, sampleText).then(function(results){
        if (results.status == 200){
            let data = results.data
           
            setRegexResult(data);
        }
    });
   }

   function isValidRegex(regexStr, samples){
        let strings = samples.split(',');
        var validCount = strings.length;
        var invalidStrings = [];
        try {
            const regex = new RegExp(regexStr);

            strings.forEach(function(s){
                if (regex.test(s) == false){
                    invalidStrings.push(s);
                    validCount --;
                }
            });
            
        } catch(e) {
            validCount = 0;
        }
        if (strings.length == 0){
            return {match:"0%", invalidStrings:[]};
        } else {
            return {match:Math.floor(validCount/strings.length*100).toString() + "%", invalidStrings:invalidStrings};
        }
    }

    function addWrapper(regex){
            if (regex.indexOf("\\b")<0){
                regex = "\\b(" + regex + ")\\b";
            }
        return regex
    }

   function getChatGpt(){
    if (regexChatGptResult){
        var match = isValidRegex(regexChatGptResult, sampleText)
        return (
            <Form.Group as={Row} className="mb-2">
            <Row><Form.Label column sm={6}>Chat GPT</Form.Label></Row>
            <Row>
                <Col sm={10}><Form.Control type="input" name="regexChatGpt" className={match.match=="100%"?"":"pattern"} value={regexChatGptResult}/></Col>
                <Col sm={2}><Form.Label>{match.match}</Form.Label></Col>
            </Row>
            {match.invalidStrings.length > 0 &&
                <Row>
                    <Col sm={3}>Invalid:</Col>
                    <Col sm={6}><Form.Label className="pattern">{match.invalidStrings.join(',')}</Form.Label></Col>
                </Row>
            }
            
            </Form.Group>
        )
    }
   }

   function getCalculations(){
    if (regexResult.length > 0){
        return (
            <Form.Group as={Row} className="mb-2">
                <Row><Form.Label column sm={6}>Calculation</Form.Label></Row>
                {getCalculationResults()}
            </Form.Group>
        )
    }
   }

   function getCalculationResults(){
    return (regexResult.map(function(result){
        var regex = addWrapper(result.Regex);
        //var match = Math.floor(result.MatchPercentage).toString()+"%"
        var match = isValidRegex(regex, sampleText)
        return (
            <Form.Group as={Row} className="mb-2">
            <Row>
                <Col sm={10}><Form.Control type="input" name="regexChatGpt" className={match.match=="100%"?"":"pattern"} value={regex}/></Col>
                <Col sm={2}><Form.Label>{match.match}</Form.Label></Col>
            </Row>
            {match.invalidStrings.length > 0 &&
                <Row>
                    <Col sm={3}>Invalid:</Col>
                    <Col sm={6}><Form.Label className="pattern">{match.invalidStrings.join(',')}</Form.Label></Col>
                </Row>
            }
            </Form.Group>
        )
    }));
   }

return (
  <div style={{position:"absolute", top:0, left:490, zIndex:99}}>
    <Offcanvas className="clipboard" show={props.showRegex} onHide={handleClose}>
      <Offcanvas.Header closeButton>
          <Offcanvas.Title>Regex Suggestion</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container fluid>
        <Form>
            <Form.Group as={Row} className="mb-2">
                <Row><Form.Label column sm={6}>Sample Values</Form.Label></Row>
                <Row><Form.Control as="textarea" rows={3} name="sampleText" placeholder="sample1, sample2, sample3" value={sampleText} onChange={fieldUpdate.bind(this)}/></Row>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Row><Button variant="primary" onClick={getRegex} className="me-2">Generate Regex</Button></Row>
            </Form.Group>
            {getChatGpt()}
            {getCalculations()}
        </Form>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  </div>
)
  
}

export default RegexDialog;