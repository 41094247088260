import React, { useEffect, useState, useRef } from "react";
import { getHeightLabels } from '../../controllers/jobController';
import eventBus from "../controls/eventBus";
import ImageOverlay from "./imageOverlay";

export default function ImageEdit(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [jobFeature, setJobFeature] = useState(null);
    const [config, setConfig] = useState(null);
    const [type, setType] = useState(null);
    const Viewer = useRef(null);
    
   
    useEffect(_ => {
        
      }, []);

    
    const viewImage = (e) => {
        let image =  e.detail.message;
        setSelectedImage(image)
        setJobFeature(e.detail.jobFeature);
        setType(image.type)
        setConfig(e.detail.config)
        //setHighlightFeature(uuid);
      }
    
    eventBus.on("viewImage", viewImage);

    // assign elementRef to the ref of our component
    return (
      
        <center>
          {selectedImage &&
            <ImageOverlay image={selectedImage} type={type} zoom={true} height={props.height} canEdit={props.canEdit} jobFeature={jobFeature} config={config}/>
          }
        </center>
      
    );
}