
import React, { useEffect, useState, useRef } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Toast, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTrash, faPlus, faCopy, faFloppyDisk, faCircleMinus, faCirclePlus, faRegistered } from '@fortawesome/free-solid-svg-icons'
import ConfirmDialog from '../controls/confirmDialog';
import CopyBubble from '../controls/copyBubble';
import RegexDialog from '../controls/regexDialog';
import { getHashParam, setHash} from '../../controllers/hashController';
import DefaultValueEditor from './defaultValueEditor';

function ConfigFieldList(props) {
  
  const [dialogMessage, setDialogMessage] = useState(null);
  const [field, setField] = useState(null);
  const [fieldIndex, setFieldIndex] = useState(-1);
  const [fields, setFields] = useState([]); 
  const [clipboard, setClipboard] = useState(props.clipboard);
  const [showRegex, setShowRegex] = useState(null);
  const [showDefaultPanel, setShowDefaultPanel] = useState(false);
  const [message, setMessage] = useState(null);
  
  const clearChecked = () => {
    fields.forEach(function(f){
      delete f.checked;
    })
  };

  const handleSaveConfig = () => {
    clearChecked();
    validateData();
    props.featureClass.attributes = fields;
    props.onSave();
  };

  const validateData = () => {
    fields.forEach(function(f){
      if (f.vslist){
        //Convert array back to object properties
        f.voiceSubstitutions = {};
        f.vslist.forEach(function(vs){
          f.voiceSubstitutions[vs.name] = vs.value;
        })
        delete f.vslist;
      }
      if (!f.voiceSubstitutions || Object.keys(f.voiceSubstitutions).length === 0 ){
        delete f.voiceSubstitutions;
      }
      //Delete empty fields
      if (f.patterns && f.patterns.length > 0){
        f.patterns.forEach(function(pattern){
          if ((pattern.positiveValidationStrings && pattern.positiveValidationStrings.length == 0) || pattern.positiveValidationStrings == null){
            delete pattern.positiveValidationStrings
          }
          if (pattern.negativeValidationStrings && pattern.negativeValidationStrings.length == 0 || pattern.positiveValidationStrings == null){
            delete pattern.negativeValidationStrings
          }
        });
      }
      
      if (f.subtypePatterns && f.subtypePatterns.length > 0){
        f.subtypePatterns.forEach(function(pattern){
          if (pattern.positiveValidationStrings && pattern.positiveValidationStrings.length == 0 || pattern.positiveValidationStrings == null){
            delete pattern.positiveValidationStrings
          }
          if (pattern.negativeValidationStrings && pattern.negativeValidationStrings.length == 0 || pattern.positiveValidationStrings == null){
            delete pattern.negativeValidationStrings
          }
        });
      }

      if (f.default == ""){
        delete f.default;
      }
      if (f.function == ""){
        delete f.function;
      }
      if (f.pickList == ""){
        delete f.pickList;
      }

      if (f.displayName == ""){
        delete f.displayName;
      }

      if (f.keyPattern == ""){
        delete f.keyPattern;
      }

      if (f.valueLocation == ""){
        delete f.valueLocation;
      }
     
    })
  };

  const handleBackToFeatures = (e) => {
    validateData();
    clearChecked();
    props.featureClass.attributes = fields; //Parent shall know the changes
    props.onBackToFeatures();
  };

  const handleCheckChanged = (field, e) => {
    var clonedFields = [...fields]//Clone the array before update, so that we get proper state change
    var selectedField = clonedFields.filter(function(f){
      return f.name == field.name;
    })[0];
    if (selectedField){
        selectedField.checked = e.currentTarget.checked;
        setFields(clonedFields);
    }
    
  };

  const handleSelect = (f,i,e) => {
    convertVoiceSubstitutionToList(f);
    setField(f);
    setFieldIndex(i);
    //setHash(f,"name",3);
  };

  const convertVoiceSubstitutionToList = (f) => {
   //Convert voice substitution to array, will convert back during save
   if (!f.vslist){
    var voiceSubstitutions = [];
    if (f.voiceSubstitutions){
      for (const key in f.voiceSubstitutions) {
        voiceSubstitutions.push({name: key, value: f.voiceSubstitutions[key]})
      }
    }
    
    f.vslist = voiceSubstitutions;
   }
  };

  const handleDeleteConfirm = (deleteOther) => {
    setDialogMessage(null);
  
    //delete jobs from UI
    var deleteFields = fields.filter(function(f){
      return f.checked
    }).map(function(f){
      return f.name;
    })

    var leftFields = fields.filter(function(f){
      return !f.checked
    })
    setFields([...leftFields]);
    
    //Check to see if currently selected field is still in the list
    var currentFields = leftFields.filter(function(f){
      return f.name == field.name;
    })
    if (currentFields.length == 0){
      if (leftFields.length > 0){
        setField(leftFields[0]);
        setFieldIndex(0);
      }
      else{
        setField(null);
        setFieldIndex(0);
      }
    }

    if (deleteOther){
      //delete fields in other feature classes in the current job type with the same name
      var messages = [];
      props.featureClasses.forEach(function(fc){
          for(var i=fc.attributes.length-1; i>=0; i--){
            if (deleteFields.indexOf(fc.attributes[i].name) >= 0){
              messages.push(fc.attributes[i].name+ " in " + fc.featureClass);
              const x = fc.attributes.splice(i, 1);
            }
          }
      });
      setMessage("Deleting " + messages.join(", "));
      setTimeout(function(){
        setMessage(null);
      }, 5000)
    }
  }

  const handleDeleteCancel = (e) => {
    setDialogMessage(null);
  }

  const handleAdd = () => {
    //add empty field
     var newfield ={
      "name":"",
      "type":"String",
      "default":"",
      "function":"",
      "pickList":"",
      "patterns":[],
      "voiceSubstitutions":{},
      "vslist":[]
      }
      var newFields = [...fields]; //clone to update
      newFields.push(newfield);
      props.featureClass.attributes.push(newfield);
 
      setFields(newFields);
      setField(newfield);
      setFieldIndex(newFields.length-1);
   }

   const handleDelete = () => {
    //delete jobs
    var selectedFields = fields.filter(function(f){
      return f.checked;
    })
    if (selectedFields.length > 0){
      var deleteMsg;
    
      if (selectedFields.length == 1){
        deleteMsg = "Delete " + selectedFields[0].name + "?";
      }
      else{
        deleteMsg = "Delete " + selectedFields.length + " attributes?";
      }
      setDialogMessage(deleteMsg);
    }
   }

   const handleCopy = () => {
    var selectedFields = fields.filter(function(j){
      return j.checked;
    })
   
    if (selectedFields.length > 0){
      //Now copy the selected items
      var copyMsg;
      if (selectedFields.length == 1){
        copyMsg = props.featureClass.featureClass + ":" + selectedFields[0].name + " copied!";
      }
      else{
        copyMsg = selectedFields.length + " fields copied!";
      }

      props.clipboard[0] = {message:copyMsg, data:selectedFields, type:"field"}; //This is the global clipboard
      setClipboard([{message:copyMsg, data:selectedFields, type:"field"}]);
    }
  }

  const handlePaste = (clipboard) => {
    //Paste copied data
    var copiedItems = JSON.parse(JSON.stringify(clipboard[0].data)); //deep copy
    copiedItems.forEach(function(item){
      //check counts of item 
      var existing = fields.filter(function(j){
        return j.name.indexOf(item.name) >= 0;
      })

      if (existing.length > 0){
        item.name = item.name + " " + (existing.length.toString());
      }
      item.checked = false;
    });

    var newFields = fields.concat(copiedItems); 
    
    setFields(newFields);
    props.clipboard[0] = null;
    setClipboard([]);

    //Select paste item if it is the first one
    if (newFields.length == 1){
      handleSelect(newFields[0],0);
    }
  }

  const handleRegex = () => {
   setShowRegex(true)
  }

  const handleCopyCancel = (clipboard) => {
    props.clipboard[0] = null;
   setClipboard([]);
  }

  const handleRegexCancel = (clipboard) => {
    setShowRegex(false);
  }

  const handleFieldTask = (key, e) => {
    switch(key){
      case "delete":
        handleDelete();
        break;

      case "save":
        handleSaveConfig();
        break;

      case "add":
        handleAdd();
        break;
      
      case "copy":
        handleCopy();
        break;

      case "regex":
        handleRegex();
        break;

      default:
    }
  };

  const handleDeletePattern = (i, e) => {
   
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    currentField.patterns.splice(i,1);
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
  };

  const handleAddPattern = (e) => {
   
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    if (currentField.patterns == null){
      currentField.patterns = [];
    }
    currentField.patterns.push({pattern:"", positiveValidationStrings:[], negativeValidationStrings:[]});
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
  };

  const handleDeleteSubtypePattern = (i, e) => {
   
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    currentField.subtypePatterns.splice(i,1);
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
  };

  const handleAddSubtypePattern = (e) => {
   
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    if (currentField.subtypePatterns == null){
      currentField.subtypePatterns = [];
    }
    currentField.subtypePatterns.push({pattern:"", positiveValidationStrings:[], negativeValidationStrings:[]});
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
  };

  const handleAddVoiceSubstitution = (e) => {
   
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    if (currentField.vslist == null){
      currentField.vslist = [];
    }
    currentField.vslist.push({name:"", value:""});
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
  };

  const handleDeleteVoiceSubstitution = (i, e) => {
   
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    currentField.vslist.splice(i,1);
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
  };
  
  function fieldUpdate(e){
    
    //Find current job type in the array to update
    var currentField = fields[fieldIndex]; //Need to access by field index so that changing field name won't cause issue

    currentField[e.currentTarget.name] = e.currentTarget.value;
   
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }

   }

   function fieldCheckUpdate(e){
    //Find current featureclass in the array to update
    var currentField = fields[fieldIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issue
    
     if (e.currentTarget.value != ""){
        var value = e.currentTarget.checked;
        if (value == true){
          value = true
        }
        else{
          value = false
        }
        currentField[e.currentTarget.name] = value;
     }

      //Update current feature class as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }
   }

   function patternUpdate(i, e){
    
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    //Find current pattern to update
    currentField.patterns[i].pattern = e.currentTarget.value;
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }

   }

   function subtypePatternUpdate(i, e){
    
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    //Find current pattern to update
    currentField.subtypePatterns[i][e.currentTarget.name] = e.currentTarget.value;
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }

   }

   function voiceSubstitutionUpdate(i, e){
    
    //Find current job type in the array to update
    var currentField = fields.filter(function(f){
      return f.name == field.name;
    })[0];

    //Find the current voice substitution to update, first check to see if it is name or value
    if (e.currentTarget.name.indexOf("name_")>=0){
      currentField.vslist[i].name = e.currentTarget.value
    }
    else{
      //value
      currentField.vslist[i].value = [e.currentTarget.value];
    }
    
    //Update current field as well
    if (field){
      var newField = {...currentField}; //Clone it so that it will trigger state change
      setField(newField);
    }

   }


  useEffect(() => {
    if (props.featureClass){
     function sort_by_key(array, key)
       {
         //Sort desc
         return array.sort(function(a, b)
         {
           var x = a[key]; var y = b[key];
           return ((x < y) ? -1 : ((x > y) ? 1 : 0));
         });
       }
 
        var fields = sort_by_key(props.featureClass.attributes,"name")
         
        setFields(fields);
        
        if (fields.length > 0){
          //If there is url parameters, select it
          var fieldname = getHashParam(window.location.hash, 3);
          if (fieldname){
            //find jobType object
            var selectedFieldIndex = fields.fieldIndex(function(f){
              return f.name == fieldname;
            });
            if (selectedFieldIndex.length >= 0){
              handleSelect(fields[selectedFieldIndex],selectedFieldIndex);
            }
            else{
              handleSelect(fields[0],0);
            }
          }
          else{
            handleSelect(fields[0],0);
          }
        }
        
    }
   },[props.featureClass]);

   function isValidRegex(regex){
    var isValid = true;
    try {
        new RegExp(regex);
    } catch(e) {
        isValid = false;
    }
    return isValid;
  }
  
   function getPatternItems(){
    if (field.patterns){
      return (field.patterns.map((p,i) => (
        <ListGroup.Item key={i.toString()} className="d-flex justify-content-between align-items-start">
            <Container fluid>
            <Row>
                <Col><Form.Control className={isValidRegex(p.pattern)?"":"pattern"} type="input" value={p.pattern} onChange={patternUpdate.bind(this,i)}/></Col>
                <Col xs={1}><FontAwesomeIcon icon={faCircleMinus} size='md' onClick={handleDeletePattern.bind(this, i)}/></Col>
            </Row>
            </Container>
        </ListGroup.Item>
        )))
    }
   }

   function getPatterns(){
    //Regex validation control:
    //https://github.com/pxpeterxu/react-regexr
    //https://regexr.com/
    if (field.patterns && field.patterns.length == 0){
      return(
      <ListGroup style={{height:`50px`, overflow:'auto'}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddPattern.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>)
    } else if (field.patterns && field.patterns.length <= 2){
      return (
        <ListGroup style={{height:`165px`, overflow:'auto'}}>
            {getPatternItems()}
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddPattern.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
      )
    }
    else{
      return (
        <div>
        <ListGroup style={{height:`165px`, overflow:'auto'}}>
            {getPatternItems()}
            </ListGroup>
            <ListGroup style={{height:`50px`}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddPattern.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
        </div>
      )
    }
    
   }

   function getPatternDisplay(f){
    var pattern = "";
    if (f.patterns && f.patterns.length > 0){
      var ps = f.patterns.map(function(p){
        return p.pattern;
      });
      if (f.patterns.length > 1){
        pattern = ps.join(" , ");
      }
      else{
        pattern = ps[0];
      }
      
    }
    return pattern;
   }

   function getSubtypePatternItems(){
    if (field.subtypePatterns){
      return (field.subtypePatterns.map((p,i) => (
        <ListGroup.Item key={i.toString()} className="d-flex justify-content-between align-items-start">
            <Container fluid>
            <Row>
            <Col><Form.Control type="input" name="subtype" value={p.subtype} placeholder="Object" onChange={subtypePatternUpdate.bind(this, i)}/></Col>
              <Col><Form.Control type="input" name="pattern" value={p.pattern} placeholder="Pattern" onChange={subtypePatternUpdate.bind(this, i)}/></Col>
                <Col xs={1}><FontAwesomeIcon icon={faCircleMinus} size='md' onClick={handleDeleteSubtypePattern.bind(this, i)}/></Col>
            </Row>
            </Container>
        </ListGroup.Item>
        )))
    }
   }

   function getSubtypePatterns(){
    //Regex validation control:
    //https://github.com/pxpeterxu/react-regexr
    //https://regexr.com/
    if (!field.subtypePatterns){
      field.subtypePatterns = []
    }
    if (field.subtypePatterns && field.subtypePatterns.length == 0){
      return (
        <ListGroup style={{height:`50px`, overflow:'auto'}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddSubtypePattern.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
      )
    } else if (field.subtypePatterns && field.subtypePatterns.length <= 2){
      return (
        <ListGroup style={{height:`165px`, overflow:'auto'}}>
            {getSubtypePatternItems()}
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddSubtypePattern.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
      )
    }
    else{
      return (
        <div>
        <ListGroup style={{height:`165px`, overflow:'auto'}}>
            {getSubtypePatternItems()}
            </ListGroup>
            <ListGroup style={{height:`50px`}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddSubtypePattern.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
        </div>
      )
    }
   }

   function getVoiceSubstitutionItems(){

    return (field.vslist.map((vs,i) => (
      <ListGroup.Item key={i.toString()} className="d-flex justify-content-between align-items-start">
          <Container fluid>
          <Row>
              <Col><Form.Control type="input" name="name_" value={vs.name} onChange={voiceSubstitutionUpdate.bind(this, i)}/></Col>
              <Col><Form.Control type="input" name={vs.name} value={vs.value} onChange={voiceSubstitutionUpdate.bind(this, i)}/></Col>
              <Col xs={1}><FontAwesomeIcon icon={faCircleMinus} size='md' onClick={handleDeleteVoiceSubstitution.bind(this, i)}/></Col>
          </Row>
          </Container>
      </ListGroup.Item>
      )));
   }

   function getVoiceSubstitutions(){

    if (field.vslist){
      if (field.vslist.length == 0){
        return (
          <ListGroup style={{height:`50px`, overflow:'auto'}}>
              {getVoiceSubstitutionItems()}
              <ListGroup.Item className="d-flex justify-content-between">
                <Container fluid>
                  <Row>
                      <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddVoiceSubstitution.bind(this)}/></Col>
                  </Row>
                </Container>
            </ListGroup.Item>
        </ListGroup>
        );
      } else if (field.vslist.length <= 2){
        return (
          <ListGroup style={{height:`165px`, overflow:'auto'}}>
              {getVoiceSubstitutionItems()}
              <ListGroup.Item className="d-flex justify-content-between">
                <Container fluid>
                  <Row>
                      <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddVoiceSubstitution.bind(this)}/></Col>
                  </Row>
                </Container>
            </ListGroup.Item>
        </ListGroup>
        );
      }
      else{
        return (
          <div>
          <ListGroup style={{height:`165px`, overflow:'auto'}}>
              {getVoiceSubstitutionItems()}
          </ListGroup>
          <ListGroup style={{height:`50px`}}>
              <ListGroup.Item className="d-flex justify-content-between">
                <Container fluid>
                  <Row>
                      <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddVoiceSubstitution.bind(this)}/></Col>
                  </Row>
                </Container>
            </ListGroup.Item>
        </ListGroup>
        </div>
        );
      }
    }
    
   }
   
   function getName(f){
    if (f.displayName != null && f.displayName.length>0){
      return f.displayName
    }
    else{
      return f.name
    }
   }

   function getFeatureClassName(fc){
    if (fc.featureClassDisplay != null && fc.featureClassDisplay.length>0){
      return fc.featureClassDisplay
    }
    else{
      return fc.featureClass
    }
   }

   function handleDefaultValue(){
    setShowDefaultPanel(true);
   }

   function handleDefaultValueConfirm(value){
      //Find current job type in the array to update
      var currentField = fields[fieldIndex]; //Need to access by field index so that changing field name won't cause issue
      currentField["default"] = value;
     
      //Update current field as well
      if (field){
        var newField = {...currentField}; //Clone it so that it will trigger state change
        setField(newField);
      }
    setShowDefaultPanel(false);
   }

   function handleDefaultValueClose(){
    setShowDefaultPanel(false);
   }

   function getLeftPane(){
    var isHorizontal = true;
    var height = props.height - 150;
    if (window.innerHeight > window.innerWidth){
      isHorizontal = false;
      height = (props.height/2) - 200;
    }
        return (
            <div>
            <Navbar bg="light" variant="light">
                        <Nav onSelect={handleBackToFeatures} defaultActiveKey="backToFeatures" className="me-auto">
                        <Nav.Link eventKey="backToFeatures"><FontAwesomeIcon icon={faChevronLeft}/></Nav.Link>
                        <Nav.Link eventKey="backToFeatures">{getFeatureClassName(props.featureClass)} &nbsp;| &nbsp;Attributes</Nav.Link>
                </Nav>
                    <Nav onSelect={handleFieldTask}>
                        <Nav.Link eventKey="copy"><FontAwesomeIcon icon={faCopy} />&nbsp;&nbsp;</Nav.Link>
                        <Nav.Link eventKey="add"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;</Nav.Link>
                        <Nav.Link eventKey="delete"><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;</Nav.Link>
                    </Nav>
                    </Navbar>
                    <ListGroup style={{height:`${height}px`, overflow:'auto'}}>
                    {fields.map((f,i) => (
                    <ListGroup.Item key={f.name} eventKey={f.name} active={field && (f.name == field.name)} action className="d-flex justify-content-between align-items-start" onClick={handleSelect.bind(this,f,i)}>
                        <Container fluid>
                        <Row>
                        <Col className="d-flex justify-content-between align-items-start">
                          <span>
                            <input type="checkbox" id={f.name} checked={f.checked} onChange={handleCheckChanged.bind(this, f)}/>
                            &nbsp;&nbsp;<Form.Label>{getName(f)}</Form.Label>
                          </span>
                        </Col>
                        <Col><Form.Label>{getPatternDisplay(f)}</Form.Label></Col>
                        </Row>
                        </Container>
                    </ListGroup.Item>
                    ))}
                    </ListGroup>
            </div>
          ) 
         
   }

   function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0, zIndex:999}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }

   function getRightPane(){

    if (field){
      if (!field.function){
        field.function = "";
      }

      if (!field.pickList){
        field.pickList = "";
      }

      if (!field.default){
        field.default = "";
      }

      if (!field.displayName){
        field.displayName = ""
      }
      
      if (!field.keyPattern){
        field.keyPattern = ""
      }

      if (!field.valueLocation){
        field.valueLocation = ""
      }
      if (!field.source){
        field.source = "any"
      }

      if (!field.mandatory){
        field.mandatory = false
      }

      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleFieldTask}>
                  <Nav.Link eventKey="regex"><FontAwesomeIcon icon={faRegistered} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
                <Nav onSelect={handleFieldTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        <Form className='form' style={{height:`${props.height - 130}px`, overflow:'auto'}}>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Name</Form.Label>
          <Col sm={8}><Form.Control type="input" name="name" value={field.name} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Display Name</Form.Label>
          <Col sm={8}><Form.Control type="input" name="displayName" value={field.displayName} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Type</Form.Label>
          <Col sm={5}>
            <Form.Select name="type" value={field.type} onChange={fieldUpdate.bind(this)}>
                <option value="String">String</option>
              </Form.Select>
          </Col>
          <Col sm={3}>
              <Form.Check type="switch" name="mandatory" label="Mandatory" checked={JSON.parse(field.mandatory)} onChange={fieldCheckUpdate.bind(this)} />
        </Col>

        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Default</Form.Label>
          <Col sm={7}><Form.Control type="input" name="default" placeholder="String,{jobname},{jobid},{userid},{datetime}" value={field.default} onChange={fieldUpdate.bind(this)}/></Col>
          <Col sm={1}><Button variant="primary" type="button" onClick={handleDefaultValue.bind(this)}>...</Button></Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Function</Form.Label>
          <Col sm={8}>
            <Form.Select name="function" value={field.function} onChange={fieldUpdate.bind(this)}>
                <option value=""></option>
                <option value="stackedText">Stacked Text</option>
                <option value="verticalText">Vertical Text</option>
                <option value="positionalText">Positional Text</option>
                <option value="manufacturer">Gas:Manufacturer</option>
                <option value="productionDate">Gas:Production Date</option>
                <option value="material">Gas:Material</option>
                <option value="lotNumber">Gas:Lot Number</option>
                <option value="componentType">Gas:Component Type</option>
                <option value="componentSize">Gas:Component Size</option>
              </Form.Select>
          </Col>
        </Form.Group>
        {(field.function == "positionalText") &&
          <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Key Pattern</Form.Label>
            <Col sm={8}><Form.Control type="input" name="keyPattern" placeholder="" value={field.keyPattern} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
        }
        {(field.function == "positionalText") &&
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Value Location</Form.Label>
            <Col sm={8}><Form.Select name="valueLocation" value={field.valueLocation} onChange={fieldUpdate.bind(this)}>
                  <option value=""></option>
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                  <option value="above">Above</option>
                  <option value="below">Below</option>
              </Form.Select></Col>
          </Form.Group>
        }
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Pick List</Form.Label>
          <Col sm={8}><Form.Control type="input" name="pickList" value={field.pickList} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Patterns</Form.Label>
          <Col sm={8}>
                {getPatterns()}
          </Col>
        </Form.Group>
        
        {false &&
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Subtype Patterns</Form.Label>
            <Col sm={8}>
                  {getSubtypePatterns()}
            </Col>
          </Form.Group>
        }
       
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Source</Form.Label>
          <Col sm={8}><Form.Select name="source" value={field.source} onChange={fieldUpdate.bind(this)}>
                <option value="any">Any</option>
                <option value="text">Text</option>
                <option value="barcode">Barcode</option>
                <option value="model">Model</option>
            </Form.Select></Col>
        </Form.Group>
        
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Voice Substitutions</Form.Label>
          <Col sm={8}>
            {getVoiceSubstitutions()}
          </Col>
        </Form.Group>
        </Form>
        {field &&
            <DefaultValueEditor defaultValue={field.default} show={showDefaultPanel} onConfirm={handleDefaultValueConfirm} onClose={handleDefaultValueClose}></DefaultValueEditor>
        }
        </Container>
      )
    }
    else{
      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleFieldTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        </Container>
      )
    }
   }


  var isHorizontal = true;
  if (window.innerHeight > window.innerWidth){
    isHorizontal = false;
  }

  return (
    <div style={{position:"relative"}}>
    <Row>
      <Col>{getLeftPane()}</Col>
      {isHorizontal &&
        <Col>{getRightPane()}</Col>
      }
    </Row>
    <ConfirmDialog title="Delete" message={dialogMessage} deleteOther="Delete other fields" onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
    <CopyBubble onPaste={handlePaste} onClose={handleCopyCancel} clipboard={clipboard} type="field"></CopyBubble>
    <RegexDialog accessToken={props.accessToken} onClose={handleRegexCancel} showRegex={showRegex}></RegexDialog>
    {getMessagePlaceholder()}
    {isHorizontal == false &&
      <Row>
        <Col>{getRightPane()}</Col>
      </Row>
      }
    </div>
  )  
        
        
}

export default ConfigFieldList;
